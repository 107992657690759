<template>
    <div style="">
        <div style="text-align: left">它的关注({{foucusList.length}})</div>
        <el-divider></el-divider>
        <div v-for="(item,index) in foucusList">

            <user-basic v-bind:userId="item.to_id"></user-basic>
            <el-divider></el-divider>
        </div>
        <div v-if="foucusList.length==0">
            o(╥﹏╥)o还没有关注的人
        </div>
    </div>
</template>

<script>
    import {request} from "../../util/api";
    import UserBasic from "@/components/UserInfo/UserBasic";
    import Utils from "@/util/utils";

    export default {
        name: "PeopleFocus",
      components: {UserBasic},
      data(){
            return{
                foucusList:null,
            }
        },
        methods:{
            showPeopleInfo(item){
                window.sessionStorage.setItem("peopleUserId",item.id);
                Utils.$emit("init_People")
            },
            getToFocus(){
                request("post","/focus/getToFocus",{id:this.peopleUserId}).then(res=>{
                    if (res.code ==200){
                        this.foucusList = res.object;
                    }
                })
            },

        },
        created() {
            if (window.localStorage.getItem('tokenStr')){
                this.peopleUserId =  window.sessionStorage.getItem("peopleUserId");
                this.getToFocus();
            }

        }
    }
</script>

<style scoped>

</style>
