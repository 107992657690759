<template>
  <div style="height: 820px;overflow-x: hidden; overflow-y: hidden; background-color: white; margin-top: 1px;">
    <div style="height: 820px;width: 100vw; overflow-x: hidden;overflow-y: hidden; max-width: 1000px;margin: 0px auto;">
      <div class="row"
           style="height: 820px;  width: 100vw; max-width: 1000px; position: relative; top: 0vw;">

        <!--    左侧    -->
        <div class="col"
             style=" width: 60px; height: 820px;  padding: 0px; background-color: #2e2e2e;">

          <div style="height: 35px;text-align: center;"></div>
          <div class="row" style="text-align: center;justify-content: center;">
            <user-avatar-v1 style="justify-content: center;" v-bind:size="35" v-bind:user-id="userId"></user-avatar-v1>
          </div>

          <el-button @click="mode = 0" style="margin-top: 10px; " type="text">
            <el-badge :hidden="totalUnread() == 0" :value="totalUnread()">
              <img v-if="mode===0" style="width: 20px;height: 20px;" src="chatGreen.png">
              <img v-else style="width: 20px;height: 20px;" src="chat0.png">
            </el-badge>
          </el-button>

          <el-button @click="mode = 1" style="margin-left: 0px;" type="text">

            <img v-if="mode !== 1" style="width: 25px;height: 25px;" src="contact0.png">
            <img v-else style="width: 25px;height: 25px;" src="contact1.png">
          </el-button>

          <el-button @click="openMoment" style="margin-left: 0px;" type="text">
            <img style="width: 25px;height: 25px;" src="moment.png">
          </el-button>

        </div>

        <div v-show="mode === 0" class="col"
             style="  width: 300px; height: 820px;  border-bottom: 1px solid #ccc;border-left: 1px solid #ccc;">

          <div style="height: 60px;line-height:60px;   background-color: #f7f7f7; border-bottom: 1px solid #ccc; padding: 2px;">
            <el-input style="width: 80%; margin: 0 10%; cursor: not-allowed;" @keyup.enter.native="toSearchUserName"
                      placeholder="搜索"
                      suffix-icon="el-icon-search"
                      v-model="userNamekeyWord"></el-input>
            <el-button @click="toSearchUserName" style="border-radius: 100px;" type="primary" size="medium" plain>
              搜索
            </el-button>
          </div>

          <div class="topnav_box"
               style="flex:1; max-height: 760px; width: 100%; background-color: #eae8e7; overflow: auto; overflow-x: hidden; overflow-y: visible; ">
            <div
                style="height: fit-content; text-align: center; width: 100%;background-color: #cc5353; color: white; padding: 4px; font-size: 14px;"
                v-if="socketDisable">{{ tips }}
            </div>
            <div v-for="(box) in chatUserBoxs" style="width: 100%; ">
              <div v-if="chatUserId != box.userId" @click="changeChatUserId(box.userId)" class="row box"
                   style="width: 95%;">
                <div>
                  <el-badge :hidden="box.unreadNums == 0" :value="box.unreadNums ? box.unreadNums : 0">

                    <UserAvatarV2 v-bind:user-id="box.userId"></UserAvatarV2>
                  </el-badge>
                </div>
                <div class="col" style="margin-left: 10px;flex: 1; height: 100%; overflow: hidden">

                  <user-name-v2 v-bind:user-id="box.userId"
                                style="height: 50%; overflow: hidden; font-size: 15px;"></user-name-v2>
                  <div
                      style="height: 50%; font-size: 14px;color: #999999;line-height: 30px;">{{
                      box.type == 0 ? box.text : box.type == '1' ? '[问题]' : box.type == 2 ? '回答' : box.type == 3 ? '[图片]' : ''
                    }}
                  </div>
                </div>
                <div
                    style=" justify-content: flex-end;  margin-right: 5px; overflow: hidden; height: 16px; width: max-content; font-size: 10px;color: #999999; ">
                  {{ lastChatTimeFunc(box.gmtCreate) }}
                </div>
              </div>

              <div v-else @click="changeChatUserId(box.userId)" class="row box1" style="width: 95%;">
                <div>
                  <el-badge :hidden="box.unreadNums == 0" :value="box.unreadNums ? box.unreadNums : 0">
                    <UserAvatarV2 v-bind:user-id="box.userId"></UserAvatarV2>
                  </el-badge>
                </div>
                <div class="col" style="margin-left: 10px; height: 100%;flex: 1; overflow: hidden">

                  <user-name-v2 v-bind:user-id="box.userId"
                                style="height: 50%; overflow: hidden; font-size: 15px;"></user-name-v2>
                  <div
                      style="height: 50%; font-size: 14px;color: #999999;line-height: 30px;">{{
                      box.type == 0 ? box.text : box.type == '1' ? '[问题]' : box.type == 2 ? '回答' : box.type == 3 ? '[图片]' : ''
                    }}
                  </div>
                </div>
                <div
                    style=" justify-content: flex-end;  margin-right: 5px; overflow: hidden; height: 16px; width: max-content; font-size: 10px;color: #999999; ">
                  {{ lastChatTimeFunc(box.gmtCreate) }}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-show="mode === 0" class="col"
             style="background-color: #f5f5f5; border-left: 1px solid #ccc;height: 820px;  width: 700px;">
          <div class="row" style="height: 60px;border-bottom: 1px solid #ccc; padding: 2px;">
            <div
                style="width:fit-content;  margin-left: 20px;  justify-content: flex-start;">
              <user-name style="font-weight: 500; font-size: 20px; width: 300px; margin-top: 15px; "
                         v-bind:userId="chatUserId"></user-name>

            </div>
          </div>
          <keep-alive>

            <im v-if="chatUserId !== null" style="height: auto;" v-bind:to-user-id="chatUserId" :key="chatUserId"></im>
          </keep-alive>
        </div>


        <div v-show="mode === 1" class="col"
             style="width: 300px;  height: 820px;  border-bottom: 1px solid #ccc;border-left: 1px solid #ccc;">

          <div class="col"
               style="height: 60px; background-color: #f7f7f7; border-bottom: 1px solid #ccc; padding: 2px;">
            <div style="margin: auto 0; text-align: center;">
              通讯录
            </div>
          </div>

          <div class="topnav_box"
               style="flex: 1; text-align: left; max-height: 760px; width: 100%; background-color: #eae8e7; overflow: auto; overflow-x: hidden; overflow-y: visible; ">
            <div
                style="height: fit-content; text-align: center; width: 100%;background-color: #cc5353; color: white; padding: 4px; font-size: 14px;"
                v-if="socketDisable">{{ tips }}
            </div>
            <div v-for="(friendId) in friends" style="width: 100%; height: 60px; ">

              <div v-if="choiceFriendId != friendId" @click="changeFriendUserId(friendId)" class="row friend_box"
                   style="width: 95%;height: 40px;">
                <div>
                  <UserAvatarV2 v-bind:user-id="friendId"></UserAvatarV2>
                </div>
                <div class="col" style="margin: auto 10px; flex: 1; height: 100%;line-height: 100%; ">

                  <user-name-v2 v-bind:user-id="friendId"
                                style="height: 100%; line-height: 40px;  overflow: hidden; font-size: 14px;"></user-name-v2>
                </div>
              </div>
              <div v-else @click="changeFriendUserId(friendId)" class="row friend_box1"
                   style="width: 95%;height: 40px;">
                <div>
                  <UserAvatarV2 v-bind:user-id="friendId"></UserAvatarV2>
                </div>
                <div class="col" style="margin: auto 10px; flex: 1; height: 100%;line-height: 100%; overflow: hidden">

                  <user-name-v2 v-bind:user-id="friendId"
                                style="text-align: left; height: 100%; line-height: 40px; overflow: hidden; font-size: 14px;"></user-name-v2>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-show="mode === 1" class="col"
             style="background-color: #f5f5f5; border-left: 1px solid #ccc; max-height: 820px; height: 100vh;  width: 700px;">
          <div style="width: 400px; height: 280px; margin: 100px auto">
            <div class="col" style="margin-left: 10px; margin-right: 10px;">
              <div class="row" style="height: 60px;">
                <div>
                  <UserAvatarV1 v-bind:user-id="choiceFriendId" v-bind:size="70"></UserAvatarV1>
                </div>
                <div class="col" style="margin-left: 10px;">

                  <div style="height: 50%;">
                    <UserNameV2 v-bind:user-id="choiceFriendId"></UserNameV2>
                  </div>
                  <div class="col" style="height: 50%; width: 10px;">
                    <UserSex style="margin: auto 0;" v-bind:user-id="choiceFriendId"></UserSex>
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="col" style="">
                <div class="row" style="line-height: 30px; height: 30px;margin-top: 15px;">
                  <div style="width: auto;">
                    个性签名：

                  </div>
                  <UserSign style="flex: 1; word-break: break-word; width: fit-content;"
                            v-bind:user-id="choiceFriendId"></UserSign>
                </div>
                <div style="height: 10vh; max-height: 20px;"></div>
                <el-divider></el-divider>
                <div class="row" style="  line-height: 30px; height: 30px;">
                  认证信息：
                  <UserAuth style="flex: 1; word-break: break-word; width: fit-content;"
                            v-bind:user-id="choiceFriendId"></UserAuth>
                </div>
              </div>
              <el-divider></el-divider>
              <div style="margin: 0 auto">
                <el-button @click="toSendMessage" type="info" style="color:#ffffff;" plain>发送消息</el-button>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Im from "@/components/im/Im";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";
import Utils from "@/util/utils";
import {request} from "@/util/api";
import UserName from "@/components/UserInfo/UserName";
import UserNameV2 from "@/components/UserInfo/userNameV2";
import UserSex from "@/components/UserInfo/UserSex";
import UserSign from "@/components/UserInfo/UserSign";
import UserAuth from "@/components/UserInfo/UserAuth";
import UserAvatarV2 from "@/components/UserInfo/UserAvatarV2";

export default {
  name: "WX-Web",
  components: {UserAvatarV2, UserAuth, UserSign, UserSex, UserNameV2, UserName, UserAvatarV1, Im},
  computed: {},
  data() {
    return {
      unReadMessageNumber: 0,
      chatUserId: null,
      chatLastMessage: [],
      choiceId: 0,
      userId: 0,
      user: {},
      chatUserBoxs: [],
      socketDisable: false,
      tips: '网络错误',
      friends: [],
      choiceFriendId: 0,
      mode: 0,
      userNamekeyWord: "",
    }
  },
  created() {
    if (/mobile/i.test(navigator.userAgent)) {
      this.$router.push("phoneChat")
      return;
    }

    let _this = this
    this.userId = window.localStorage.getItem("userId")
    if (!this.userId) {
      this.$router.push("/index")
      this.$message.error("请先登录")
      return;
    }
    this.user = window.sessionStorage.getItem("user_basic:" + this.userId)

    if (typeof this.user === typeof undefined) {
      return
    }
    this.choiceFriendId = this.userId
    Utils.$on('On_Chats_AddMessage', function (number) {
      _this.unReadedMessageNumber += number
    })
    Utils.$on("App_Websocket_Close", function () {
      _this.socketDisable = true
      _this.tips = "请检查网络"
    })
    Utils.$on("App_Websocket_Error", function () {
      _this.socketDisable = true
      _this.tips = "请刷新重试"
    })
    Utils.$on("App_Websocket_Open", function () {
      _this.socketDisable = false
      _this.tips = ""
      _this.$forceUpdate()
    })
    Utils.$on("AppVue_ChangeUnreadNums", function (toId, type, nums) {
      for (let i = 0; i < _this.chatUserBoxs.length; i++) {
        if (_this.chatUserBoxs[i].userId == toId) {
          if (type == 0) {
            _this.chatUserBoxs[i].unreadNums = nums;
          } else {
            _this.chatUserBoxs[i].unreadNums += nums;
          }
          break;
        }
      }
      _this.$forceUpdate()
    })


    Utils.$on('On_Chats_AddMessage_ChangeLastMessage', function (data) {
      let i;
      let t = null;
      for (i = 0; i < _this.chatUserBoxs.length; i++) {
        if (_this.chatUserBoxs[i].fromId == data.fromId && _this.chatUserBoxs[i].toId == data.toId ||
            _this.chatUserBoxs[i].fromId == data.toId && _this.chatUserBoxs[i].toId == data.fromId
        ) {
          let nums = 0;
          if (_this.chatUserBoxs[i].unreadNums) {
            nums = _this.chatUserBoxs[i].unreadNums
          }

          _this.chatUserBoxs[i] = {
            fromId: data.fromId,
            toId: data.toId,
            text: data.text,
            unreadNums: nums,
            userId: data.userId,
            gmtCreate: data.gmtCreate,
            type: data.type
          }
          window.localStorage.setItem(_this.genLastChatKey(data.fromId, data.toId), JSON.stringify(data))
          t = _this.chatUserBoxs[i]
          break;
        }
      }
      if (t === null) {
        t = {
          fromId: data.fromId,
          toId: data.toId,
          text: data.text,
          unreadNums: 1,
          userId: data.userId,
          gmtCreate: data.gmtCreate,
          type: data.type
        }
        _this.chatUserBoxs = [t, ..._this.chatUserBoxs]
      } else {

        for (let j = i; j >= 1; j--) {
          _this.chatUserBoxs[j] = _this.chatUserBoxs[j - 1]
        }

        _this.chatUserBoxs[0] = t
      }

      _this.$forceUpdate()
      return;
    })
  },
  async mounted() {
    if (window.localStorage.getItem("userId") && window.localStorage.getItem("tokenStr")) {
      this.getChatBoxs()
      this.getFriends()
    }

  },
  methods: {
    toSearchUserName() {

    },

    getChatBoxs() {
      request("get", "/chat/getChatUserBoxs").then(res => {
        if (res && res.code == 200) {
          this.chatUserBoxs = res.object;
          this.totalUnread()
        }
      })
    },
    toSendMessage() {
      this.chatUserId = this.choiceFriendId;
      this.mode = 0;
    },
    getFriends() {
      request("get", "/focus/getFriends").then(res => {
        if (res && res.code == 200) {
          this.friends = res.object;
        }
      })
    },
    changeFriendUserId(friendId) {
      this.choiceFriendId = friendId;
    },
    openMoment() {
      Utils.$emit("AppVue_ShowMoment")
    },

    arrowMove(e) {
      // 元素大小
      let elW = e.currentTarget.offsetWidth
      let elH = e.currentTarget.offsetHeight
      // 元素位置
      let elL = e.currentTarget.offsetLeft
      let elT = e.currentTarget.offsetTop
      // 鼠标位置
      let x = e.clientX
      let y = e.clientY
      // 窗口大小
      let w = window.innerWidth
      let h = window.innerHeight
      // 鼠标到元素左边距离
      let moveX = x - elL
      let moveY = y - elT
      let el = e.currentTarget
      document.onmousemove = function (e) {
        el.style.right = w - (e.clientX - moveX) - elW + 'px'
        el.style.bottom = h - (e.clientY - moveY) - elH + 'px'
      }
      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    totalUnread() {
      let res = 0;
      for (let i = 0; i < this.chatUserBoxs.length; i++) {
        res += this.chatUserBoxs[i].unreadNums;
      }
      this.$parent.$parent.unReadMessageNumber = res
      window.localStorage.setItem(this.userId + ":unreadMessageNumber", res)
      return res;
    },

    lastChatTimeFunc(timeStr) {
      if (!timeStr) {
        return ''
      }
      let p = Date.parse(timeStr)
      let pd = new Date(p)
      let nd = new Date()
      let n = nd.valueOf()

      if (nd.getFullYear() !== pd.getFullYear()) {

        return pd.getFullYear() % 100 + '/' + (pd.getMonth() + 1) + '/' + pd.getDate();
      }

      if ((n - p) / 1000 < 60) {
        return '刚刚'
      }

      if ((n - p) / 1000 / 60 < 60) {
        return Math.ceil((n - p) / 1000 / 60) + '分钟前'
      }


      let pYear = pd.getFullYear()
      let nYear = nd.getFullYear()
      let ptowMonthDay = ((pYear % 4 === 0) && (pYear % 100 !== 0) || pYear % 400 === 0) ? 29 : 30
      let ntowMonthDay = ((nYear % 4 === 0) && (nYear % 100 !== 0) || nYear % 400 === 0) ? 29 : 30
      let pMonthDays = [0, 31, ptowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      let nMonthDays = [0, 31, ntowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      for (let i = 1; i < 12; i++) {
        pMonthDays[i] += pMonthDays[i - 1]
        nMonthDays[i] += nMonthDays[i - 1]
      }

      let pMonth = pd.getMonth() + 1;
      let nMonth = nd.getMonth() + 1;
      // let startTime = '2023-02-16 14:36:57'
      let pDays = pd.getDate() + pMonthDays[pMonth]
      let nDays = nd.getDate() + nMonthDays[nMonth]

      if (pDays + 1 === nDays) {
        return "昨天 "
      }
      if (pDays + 2 === nDays) {
        return "前天 "
      }

      if ((n - p) / 1000 / 60 / 60 < 24) {
        return Math.ceil((n - p) / 1000 / 60 / 60) + '小时前'
      }

      return pd.getFullYear() % 100 + '/' + (pd.getMonth() + 1) + '/' + pd.getDate();
    },

    genLastChatKey(left, right) {
      let min = Math.min(left, right)
      let max = Math.max(left, right)
      return "last_chat:" + min + ":" + max
    },
    changeChatUserId(userId) {
      let nums = 0;
      for (let i = 0; i < this.chatUserBoxs.length; i++) {
        if (this.chatUserBoxs[i].userId == userId) {
          this.$parent.unReadMessageNumber -= this.chatUserBoxs[i].unreadNums;

          nums = this.chatUserBoxs[i].unreadNums;
          this.chatUserBoxs[i].unreadNums = 0;
          break
        }
      }
      this.chatUserId = userId;

      if (nums !== 0) {

        Utils.$emit("AppVue_updateLastReadTime", userId)
        this.$forceUpdate()
      }

    },
    async getChatList() {
      return new Promise(function (resolve) {
        request("get", "/chat/getChatUserIds").then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          }
        })
      })

    },

  }
}
</script>


<style scoped>

.el-button--info.is-plain:focus, .el-button--info.is-plain:hover {
  background: #38cd7f;
  border-color: #38cd7f;
  color: #FFF;
}

.el-button--info.is-plain {
  background-color: #07c160;
  border-style: none;
}

.box {
  height: 40px;
  background-color: #e6e5e4;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  text-align: left;
}

.box:hover {
  background-color: #cececf;
}

.moment-error:hover {
  background-color: #fb7373;
}

.moment-flush:hover {
  background-color: #d5d5d5;
}

.box1 {
  height: 40px;
  background-color: #c7c5c4;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  text-align: left;
}

.friend_box {
  height: 40px;
  background-color: #e6e5e4;
  padding: 12px;
  text-align: left;
}

.friend_box:hover {
  background-color: #cececf;
}

.friend_box1 {
  height: 40px;
  background-color: #c7c5c4;
  padding: 12px;
  text-align: left;
}

.topnav_box::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  /*:overflow-y:;*/
  background-color: rgb(245, 245, 245);

}

.topnav_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  border-radius: 10px;
  background-color: rgb(245, 245, 245);

}


.topnav_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  background-color: #d2d2d2;
}

#89d961
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>