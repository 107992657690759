<template>
  <div>
    <div style="height: 30px;">
      <el-button style="height: 30px;"
                 size="small" type="text">
        <div style="display: flex;flex-direction: row;"
             @click="addZan">
          <div style="" v-if="zan.isFromUser">
            <el-image class="good"
                      style="width: 20px;height: 20px;bottom: 8px"
                      src="good2.png">
            </el-image>
          </div>
          <div v-else>
            <el-image class="good"
                      style="width: 20px;height: 20px;bottom: 8px"
                      src="good1.png">
            </el-image>
          </div>
          <div v-if="zan.length>0">
            <div style="color:#13227a;"
                 v-if="zan.isFromUser">
              {{ zan.length }}
            </div>
            <div style="color: #8c939d" v-else>
              {{ zan.length }}
            </div>
          </div>
          <div style="color: #8c939d" v-else>点赞</div>
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "Zan",
  props: ['userId', 'type', 'typeId'],
  data() {
    return {
      zan: {
        length: 0,
        isFromUser: false,
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.userId) {
        return
      } else {
        this.userId = window.localStorage.getItem("userId")
      }
      if (!this.userId || !this.type || !this.typeId) {
        return;
      }
      this.getZan();
    },
    getZan() {
      request("get", "/zan/get?typeId="+this.typeId+"&type="+this.type).then(res=> {
            if (res && res.code == 200) {
              this.zan = res.object;
            }
      })
    },
    addZan() {
      request("post", "/zan/add", {typeId: this.typeId, type: this.type}).then(res => {
        if (res && res.code == 200) {
          let count = this.zan.isFromUser ? -1 : 1;
          this.zan.isFromUser = !this.zan.isFromUser;
          this.zan.length += count;

        }
      })
    }
  }
}
</script>

<style scoped>

</style>