<template>
  <div>

  </div>
</template>

<script>
import Utils from "@/util/utils";

export default {
  layout: "empty",
  name: "Callback",
  data() {

  },
  mounted() {
    let tokenStr = this.$route.query.token
    let userId = this.$route.query.userId
    let openid = this.$route.query.openid
    // console.log(this.$route.query,9899)
    if (!tokenStr) {
      this.$message.error("登录失败");
      return
    }
    window.parent['loginCallback'](tokenStr, userId, openid)

  }
}
</script>

<style scoped>

</style>