<template>
  <div id="app"
       style="width: 100vw; justify-content: center;  overflow-x:hidden; flex-direction: column; align-items: center;background-color: #f6f6f6">
    <div v-if="$route.meta.keepAlive != false" class="row" style=" z-index: 10; position: fixed; top: 0; ">

      <el-menu v-if="!isPhone"
               default-active=""
               class=" row"
               mode="horizontal"
               style="width: 100vw; margin: 0 auto;height: fit-content; "
      >
        <div class="row"
             style="margin: 0 auto; width: 1000px; height: fit-content;  justify-content: center;">
          <div style="display: flex; flex-direction: column; justify-content: center;">
            <el-image @click.native="toIndex" src="/favicon.ico" fit="fit"
                      style="width: 50px; height:35px;padding: 5px; cursor: pointer; justify-content: center;"></el-image>
          </div>
          <MenuSingleText @click.native="toIndex" style="width: 60px;" v-bind:routers="['Index','']"
                          v-bind:text="'首页'"></MenuSingleText>
          <!--          <MenuSingleText @click.native="toFocus" style="" v-bind:routers="['FocusTopic','']"   v-bind:text="'关注'"></MenuSingleText>-->
          <MenuSingleText @click.native="toWriteTopic" style="width: 60px;" v-bind:routers="['WriteTopic','']"
                          v-bind:text="'提问'"></MenuSingleText>
          <MenuSingleText @click.native="toWriteMoment" style="width: 60px;" v-bind:routers="['WriteMoment','']"
                          v-bind:text="'创作'"></MenuSingleText>
          <MenuSingleText @click.native="openMoment" style="width: 60px;" v-bind:routers="['']"
                          v-bind:text="'圈子'"></MenuSingleText>


          <el-submenu @click="toPeople" style="font-size: medium;">
            <template slot="title">
              <router-link to="">
                <el-avatar :src="user.avatar"></el-avatar>
              </router-link>
            </template>

            <el-menu-item @click="toUserInfo">
              个人中心
            </el-menu-item>
            <el-menu-item @click="toPeople">
              我的主页
            </el-menu-item>
            <el-menu-item v-if="userRole == 'Admin' || userRole == 'Checker'" @click="toManage">
              后台管理
            </el-menu-item>
            <el-menu-item @click="toLogin">
              切换账号
            </el-menu-item>
            <el-menu-item @click="toLogout">
              退出登录
            </el-menu-item>


          </el-submenu>
          <div class="row my_menu"
               style="font-size: medium; cursor: pointer; justify-content: center; width: 60px;text-align: center; max-width: 60px;

"
               @click="toChats">

            <div v-if="$route.name !== 'Chats'" class="col"
                 style="padding: 0px; height: 100%; justify-content: center;">
              <el-badge style="height: 20px; width: 20px;" :hidden="unReadMessageNumber==0" :value="unReadMessageNumber"
                        class="">
                <div class="row" style="width: 100%; height: 20px; justify-content: center;">
                  <img style="width: 20px; height: 20px;" src="chat_logo.png">
                </div>
              </el-badge>
            </div>
            <div v-else class="col"
                 style="padding: 0px; height: 100%; justify-content: center; border-bottom: 2px solid #056de8;">
              <el-badge style="height: 20px; width: 20px;" :hidden="unReadMessageNumber==0" :value="unReadMessageNumber"
                        class="">
                <div class="row" style="width: 100%; height: 20px; justify-content: center;">
                  <img style="width: 20px; height: 20px;" src="chat_logo_active.png">
                </div>
              </el-badge>
            </div>
          </div>

          <div class="col"
               style=" height: 60px; justify-content: center; font-size: medium">
            <el-input style="width: 400px; cursor: pointer;" @keyup.enter.native="toSearchKeyWordTopic"
                      placeholder="请输入想要查的问题"
                      suffix-icon="el-icon-search"
                      v-model="keyWord"></el-input>
          </div>
          <div class="col" style="justify-content: center; margin-left: 10px;">
            <el-button @click="toSearchKeyWordTopic" style="border-radius: 100px;" type="primary" size="medium" plain>
              搜索
            </el-button>
          </div>
        </div>
      </el-menu>

      <el-menu v-else
               default-active=""
               class="col"
               mode="horizontal"
               style="width: 100vw; height: fit-content; "
      >
        <div class="row"
             style="margin: 0 auto; width: 100%; padding-right: 5%;height: 60px;  justify-content: center;">
          <MenuSingleText @click.native="toIndex" style="width: 15%;" v-bind:routers="['Index','']"
                          v-bind:text="'首页'"></MenuSingleText>
          <MenuSingleText @click.native="toWriteTopic" style="width: 15%;" v-bind:routers="['WriteTopic','']"
                          v-bind:text="'提问'"></MenuSingleText>
          <MenuSingleText @click.native="toWriteMoment" style="width: 15%;" v-bind:routers="['WriteMoment','']"
                          v-bind:text="'创作'"></MenuSingleText>
          <MenuSingleText @click.native="openMoment" style="width: 15%;" v-bind:routers="['']"
                          v-bind:text="'圈子'"></MenuSingleText>
          <el-submenu @click="toPeople" style="font-size: medium; width: 15%;">
            <template slot="title">
              <router-link to="">
                <el-avatar :src="user.avatar"></el-avatar>
              </router-link>
            </template>

            <el-menu-item @click="toUserInfo">
              个人中心
            </el-menu-item>
            <el-menu-item @click="toPeople">
              我的主页
            </el-menu-item>
            <el-menu-item v-if="userRole == 'Admin' || userRole == 'Checker'" @click="toManage">
              后台管理
            </el-menu-item>
            <el-menu-item @click="toLogin">
              切换账号
            </el-menu-item>
            <el-menu-item @click="toLogout">
              退出登录
            </el-menu-item>


          </el-submenu>
          <div class="row my_menu"
               style="font-size: medium; cursor: pointer; justify-content: center; width: 15%;text-align: center; max-width: 60px;"
               @click="toChats">
            <div class="col" style="padding: 0px; height: 100%; justify-content: center;">
              <el-badge style="height: 20px; width: 20px;" :hidden="unReadMessageNumber==0" :value="unReadMessageNumber"
                        class="">
                <div class="row" style="width: 100%; height: 20px; justify-content: center;">
                  <img style="width: 20px; height: 20px;" src="chat_logo.png">
                </div>
              </el-badge>
            </div>
          </div>
        </div>
        <div class="col" style="justify-content: center; width: 100%;">
          <div class="row"
               style=" height: 60px; justify-content: center; font-size: medium; margin-left: 10px; margin-right: 10px;">
            <el-input class="col" style="justify-content: center;" @keyup.enter.native="toSearchKeyWordTopic"
                      placeholder="请输入想要查的问题" suffix-icon="el-icon-search"
                      v-model="keyWord"></el-input>
            <div class="col" style="justify-content: center; margin-left: 10px;border-radius: 30px;">
              <el-button @click="toSearchKeyWordTopic" style="border-radius: 100px;" type="primary" size="" plain>搜索
              </el-button>
            </div>
          </div>
        </div>
      </el-menu>
    </div>
    <div v-if="$route.meta.keepAlive != false">

      <div v-if="!isPhone" style="height: 60px"></div>
      <div v-else style="height: 120px"></div>
    </div>

    <router-view class="index" style="padding-bottom: 50px;"></router-view>
    <div  v-if="$route.meta.noICP != true" class="row" style="width: 100vw;justify-content: center;text-align: center; height: 50px; line-height: 50px; margin-top: -50px;">
      © 2021 chujuei.top 版权所有ICP证<a style="color: darkgoldenrod" href="https://beian.miit.gov.cn/" type="_blank">粤ICP备2020142746号</a>
    </div>
    <transition name="el-zoom-in-bottom">
      <div class="fixed-component" v-if="showMoment">
        <div ref="parent" id="moment" class="col" @mousedown="arrowMove" style=" height: 900px; width: 100vw;position:fixed; max-width: 450px; overflow-x: hidden; right: 0;top: 0;
          z-index: 12;
          background-color: white;
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
      ">
          <div ref="draggable" id="moment_top" class="row "
               style="cursor: move; height: 40px; width: 100%;max-width: 450px;opacity: 1; right: 0px;top: 0px; z-index: 1">

            <div style="display: flex; width: 45%; flex-direction: row; height: 100%; justify-content: flex-start;">
              <div class="col" style="justify-content: center;">

                <div v-if="!loading"
                     class="row"
                     @click="flushMoment"
                     style="height: 25px; width: 25px; margin-left: 7px; ">

                  <div v-if="op == 1" style="margin-left: 0px;">
                    <!--                  #-->
                    <img class="moment-flush-active" src="flush_active.png"
                         style="z-index: 10; width: 15px;height: 15px; padding:5px;  cursor: pointer;">
                  </div>
                  <div v-else style="padding: 3px;">
                    <img class="moment-flush" src="flush.png"
                         style="width: 15px;height: 15px;padding:5px;cursor: pointer;">
                  </div>

                </div>
                <div v-else class="row"
                     style="height: 25px; width: 25px; margin-left: 7px; ">

                  <div style="margin-left: 0px;">
                    <!--                  #-->
                    <img src="moment_flush.gif"
                         style="width: 20px;height: 20px; padding:5px;  cursor: pointer;">
                  </div>

                </div>

              </div>
            </div>
            <div class="row"
                 style="width: 100%;max-width: 450px; text-align: center; justify-content: center;">
              <div @dblclick="scrollToTop" v-show="op == 1" class="col" style="justify-content: center;">
                圈子
              </div>
            </div>
            <div style="display: flex; width: 45%; flex-direction: row; height: 100%; justify-content: flex-end;">
              <div class="col" style="justify-content: center;">

                <div class="row"
                     @click="showMoment = false"
                     style="height: 20px; width: 20px; margin-right: 7px; justify-content: flex-end;">

                  <div v-if="op == 1" style="width: 20px;height: 20px;color: black; cursor: pointer;"
                       class="moment-error">
                    ×
                  </div>
                  <div v-else style="width: 20px;height: 20px; cursor: pointer;"
                       class="moment-error">
                    ×
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div id="moment_scroll" @scroll="scrollChange" class="topnav_box"
               style="width: 100%; position: relative; top: -40px; background-color: white;overflow: auto;">
            <el-image
                style="width: 100%; aspect-ratio: 3; overflow: hidden; "
                :src="user.background">
              <loading-image slot="placeholder"></loading-image>
              <error-image slot="error"></error-image>
            </el-image>
            <div class="flag"></div>
            <div class="row" style="width: 100%; height: 0px; justify-content: flex-end; margin-top: -4px;">
              <UserAvatarV1 style="position: relative; top: -50px; right: 20px; " v-bind:userId="userId"
                            v-bind:size="70"></UserAvatarV1>
            </div>
            <div style="padding: 0px; width: 100%; ">

              <Moments v-bind:user-id="userId" style="height: 800px; padding: 10px;"></Moments>
            </div>
          </div>
        </div>

      </div>
    </transition>

  </div>
</template>

<script>

import Utils from '@/util/utils.js';
import {request} from "./util/api";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";
import Moments from "@/views/Moments";
import MenuSingleText from "@/components/Menu/MenuSingleText";
import LoadingImage from "@/components/Icon/LoadingImage";
import ErrorImage from "@/components/Icon/ErrorImage";

let socket;

export default {
  name: 'app',
  components: {ErrorImage, LoadingImage, MenuSingleText, Moments, UserAvatarV1},
  data() {
    return {
      unReadedNumber: 0,
      unReadMessageNumber: 0,
      keyWord: "",
      isManager: false,
      user: {},
      userRole: '',
      token: '',
      users: [],
      socketDisable: false,
      unreadTotalNums: 0,
      chatListMap: {},
      showMoment: false,
      lastReadTime: {},
      lastUnreadNums: {},
      indexFlag: false,
      userId: 0,
      isPhone: false,
      unreadMap: {},
      tryTime: 0,
      op: 0,
      loading: false,

      top: 0,
      right: 0,
      isDragging: false,
      startX: 0,
      startY: 0,

    }
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        Utils.$emit("change_route")
      }
    }
  },
  computed: {},
  async mounted() {
    // this.getLastChatTimeByUserId(10);
    let str = window.localStorage.getItem("tokenStr")

    if (str) {

      // 更新user
      this.user = await this.getUserBasic();
      window.localStorage.setItem("user_basic:" + this.user.id, JSON.stringify(this.user))
      // 更新userId
      this.userId = this.user.id
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);


      window.localStorage.setItem("userId", this.user.id)
      this.getUnReadMessageNumber()
      this.getDefaultUserRole()

    }
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.stopDrag);
  },
  created() {
    let _this = this
    if (/mobile/i.test(navigator.userAgent)) {
      this.isPhone = true;
    }

    // 开启一个websocket服务
    this.initSocket()


    Utils.$on('WebSocket_SendMessage', function (data) {
      if (_this.socketDisable === true) {
        _this.$message.error("网络连接错误，请刷新！")
        return null;
      }

      let dataStr = JSON.stringify(data)
      // _this.$message.error("网络连接错误，请刷新！")

      socket.send(dataStr)
      // if (_this.socketDisable === false) {
      // window.localStorage.setItem(_this.genLastChatKey(data.fromId, data.toId), dataStr)
      // Utils.$emit('On_Chats_AddMessage_ChangeLastMessage', data)
      // Utils.$emit('WebSocket_AddMessageFromLocal_ChangeChatList', data)
      // Utils.$emit('AppVue_ChangeUnreadNums', data.fromId == _this.user.id ? data.toId : data.fromId, 0, 0);
      // _this.updateLastReadTime(data.toId)
      // _this.insertHistoryToCache(data.fromId, data.toId, data.text);
      // }
    })
    Utils.$on("AppVue_GetChatListFromLocalStorage", function (left, right) {
      let min = Math.min(left, right)
      let max = Math.max(left, right)
      if (!_this.chatListMap[min] || typeof _this.chatListMap[min] === typeof undefined) {
        _this.chatListMap[min] = {}
      }
      let ans = _this.chatListMap[min][max]
      if (!ans || typeof (ans) !== typeof ([])) {
        let key = _this.genChatKey(min, max)
        let str = window.localStorage.getItem(key)
        if (typeof (str) === undefined || str.length === 0) {
          ans = []
        } else {
          ans = JSON.parse(str)
        }
      }
      _this.chatListMap[min][max] = ans;
      return ans;
    })
    Utils.$on("AppVue_UpdateChatList", function (left, right, list) {
      let min = Math.min(left, right)
      let max = Math.max(left, right)
      if (typeof _this.chatListMap[min] === typeof undefined) {
        _this.chatListMap[min] = {}
      }
      if (typeof _this.chatListMap[min][max] === typeof undefined) {
        _this.chatListMap[min][max] = []
      }
      if (_this.chatListMap[min][max].length !== list.length) {

        _this.chatListMap[min][max] = list
        window.localStorage.setItem(_this.genChatKey(min, max), JSON.stringify(list))
      }
    })

    Utils.$on("AppVue_GetUnreadNums", async function (toId) {
      let res = await _this.getUnreadNumsByToId(toId)
      Utils.$emit("AppVue_ChangeUnreadNums", toId, 0, res)

    })

    Utils.$on("AppVue_updateLastReadTime", function (toId) {

      _this.updateLastReadTime(toId)

    })
    // 显示朋友圈
    Utils.$on("AppVue_ShowMoment", function () {
      _this.showMoment = true;
    })

  },
  destroyed() {
    socket.close();
  },
  methods: {


    scrollToTop() {
      const container = document.getElementById("moment_scroll")
      console.log(container)
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    startDrag(event) {
      if (event.target === this.$el) {
        this.isDragging = true;
        this.startX = event.pageX;
        this.startY = event.pageY;
      }
    },
    onDrag(event) {
      if (this.isDragging) {
        const deltaX = event.pageX - this.startX;
        const deltaY = event.pageY - this.startY;
        this.top += deltaY;
        this.right += deltaX;
        this.startX = event.pageX;
        this.startY = event.pageY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
    scrollChange(e) {
      var flag = document.getElementsByClassName("flag")[0]
      let op = ((e.target.scrollTop) + 36) / flag.offsetTop;
      if (op >= 1) {
        this.op = 1;
        document.getElementById("moment_top").style.background = "rgba(237, 237, 237, " + 1 + ")"
      } else {
        this.op = 0;
        document.getElementById("moment_top").style.background = "rgba(237, 237, 237, " + 0 + ")"

      }

    },
    openMoment() {
      this.showMoment = true;
    },
    flush() {
      window.location.reload()
    },
    toWriteMoment() {
      this.$router.push("/writeMoment")
    },
    flushMoment() {
      this.indexFlag = !this.indexFlag
      Utils.$emit("FlushMoment")
      // this.showMoment = !this.showMoment

    },
    arrowMove(e) {
      if (true) {
        // return
        // 元素大小
        // let e = document.getElementById("moment")
        let elW = e.currentTarget.offsetWidth
        let elH = e.currentTarget.offsetHeight
        // 元素位置
        let elL = e.currentTarget.offsetLeft
        let elT = e.currentTarget.offsetTop
        // 鼠标位置
        let x = e.clientX
        let y = e.clientY
        // 窗口大小
        let w = window.innerWidth
        let h = window.innerHeight
        // 鼠标到元素左边距离
        let moveX = x - elL
        let moveY = y - elT
        let el = e.currentTarget
        document.onmousemove = function (e) {
          el.style.right = w - (e.clientX - moveX) - elW + 'px'
          el.style.top = (h + (e.clientY - moveY) - elH) + 'px'
        }
        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    },
    getUnReadMessageNumber() {
      let item = window.localStorage.getItem(this.userId + ":unreadMessageNumber")
      if (typeof (item) === undefined) {
        this.unReadMessageNumber = 0
      } else {
        this.unReadMessageNumber = parseInt(item)
      }

    },
    genChatKey(min, max) {
      return min + ":" + max + ":chats"
    },

    updateLastReadTime(toId) {
      this.lastReadTime[toId] = this.getNowDateString()
      request("post", "/updateLastChatTimeByToId", {toId: toId}).then(res => {
        if (res && res.code == 200) {
          return
        } else {
          // this.$message.error("网络不稳定，请刷新")
        }
      })
    },
    async getChatsByStartTime(startTime) {
      return new Promise(resolve => {
        request("get", "/chat/getAllByStartTime/" + this.toUserId + "?startTime=" + startTime).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            return null
          }
        })
      })
    },
    // 获取未读时间 并计算未读数量 并改变data的全局
    async getUnreadNumsByToId(toId) {
      let _this = this
      let time = _this.lastReadTime[toId]
      if (!time || typeof (time) !== typeof ("") || time == null) {
        time = await _this.getLastChatTimeByUserIdFromWeb(toId)
        _this.lastReadTime[toId] = time
      }

      let fromId = _this.user.id
      if (typeof (fromId) === undefined) {
        return 0;
      }


      let list = _this.getChat(fromId, toId)
      let ans = 0;
      for (let i = list.length - 1; i >= 0; i--) {

        if (list[i].gmtCreate > time) {
          ans++;
        } else {
          break;
        }
      }
      return ans;

    },

    async getLastChatByToIdFromWeb(toId) {
      return new Promise(function (resolve, reject) {
        request("get", "/getLastChatTimeByToId?toId=" + toId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object);
          } else {
            reject(null)
          }
        })
      })
    },
    async getLastChatTimeByUserIdFromWeb(userId) {
      return new Promise(function (resolve) {
        request("get", "/getLastChatTimeByToId?toId=" + userId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          }
        })
      })

    },
    getChat(left, right) {
      let _this = this;
      let min = Math.min(left, right)
      let max = Math.max(left, right)
      let ans = _this.chatListMap[min][max]
      if (!ans || ans == null || typeof (ans) === undefined) {
        let key = _this.genChatKey(min, max)
        let str = window.localStorage.getItem(key)
        if (typeof (str) === undefined || str.length === 0) {
          ans = []
        } else {
          ans = JSON.parse(str)
        }
      }
      _this.chatListMap[min][max] = ans;
      return ans;
    },
    insertHistoryToCache(fromId, toId, text) {
      let datetime = this.getNowDateString()
      let min = Math.min(fromId, toId)
      let max = Math.max(fromId, toId)
      let list = []
      let listStr = window.localStorage.getItem(this.genChatKey(min, max))
      if (listStr && typeof listStr !== undefined) {
        list = JSON.parse(listStr)
      }
      let data = {fromId: fromId, toId: toId, text: text, gmtCreate: datetime, gmtUpdate: datetime};
      // localstorage持久化
      list.push(data)
      window.localStorage.setItem(this.genChatKey(min, max), JSON.stringify(list))
      // 内存持久化
      if (!this.unreadMap[fromId] || typeof this.unreadMap[fromId] === undefined) {
        this.unreadMap[fromId] = {}
      }
      if (!this.unreadMap[fromId][toId] || typeof this.unreadMap[fromId][toId] === undefined) {
        this.unreadMap[fromId][toId] = []
      }
      this.unreadMap[fromId][toId].push(data)

    },

    getNowDateString() {
      let res = ""
      let date = new Date()
      let time = date.toTimeString()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      res += year + '-';
      if (month < 10) {
        res += '0'
      }
      res += month + "-"
      if (day < 10) {
        res += '0'
      }
      res += day + " "
      res += time.substring(0, 8)
      return res

    },
    genLastChatKey(left, right) {
      let min = Math.min(left, right)
      let max = Math.max(left, right)
      return "last_chat:" + min + ":" + max
    },
    initSocket() {
      let token = window.localStorage.getItem("tokenStr")
      if (!token) {
        return;
      }
      let _this = this;
      // let ip = "localhost"
      // // let ip = "chujuei.top"
      // let port = 8082

      // let socketUrl = "ws://" + "localhost" + ":" + 8082 + "/imserver/" + token;
      let socketUrl = "wss://" + "chujuei.top:443" + "/imserver/" + token;
      socket = new WebSocket(socketUrl);
      //打开事件
      socket.onopen = function () {
        Utils.$emit('App_Websocket_Open')
        _this.socketDisable = false;
      };
      //  浏览器端收消息，获得从服务端发送过来的文本消息
      socket.onmessage = function (msg) {

        let data = JSON.parse(msg.data)  // 对收到的json数据进行解析， 类似这样的： {"users": [{"username": "zhang"},{ "username": "admin"}]}
        if (data.forceLogout) {
          // _this.logout();
          window.localStorage.removeItem("tokenStr")
          window.localStorage.removeItem("userId")
          _this.$message.error("另外一端上线，系统强迫您下线")
          _this.$router.replace("/login")
          return
        }

        if (data.tooLong) {
          _this.$message.warning("另外一端上线，系统强迫您下线")
          return
        }
        if (data.tooFast) {
          _this.$message.warning("你的发送太过于频繁")
          return
        }
        if (data.users) {  // 获取在线人员信息
          _this.users = data.users.filter(user => user.id !== _this.user.id)  // 获取当前连接的所有用户信息，并且排除自身，自己不会出现在自己的聊天列表里
        } else {
          // 构建消息内容
          if (data.fromId) {
            // let message = {fromId: data.fromId, toId: data.toId, text: data.text, gmtCreate: data.gmtCreate}
            if (!data.isLocal) {
              // 别人发的
              Utils.$emit('WebSocket_AddMessageFromWeb_ChangeChatList', data);
              _this.addUnReadMessageNumber();
              // _this.lastReadTime[data.fromId] = _this.getNowDateString();
              Utils.$emit('AppVue_ChangeUnreadNums', data.fromId, 1, 1);
              Utils.$emit('On_Chats_AddMessage_ChangeLastMessage', data)
            } else {
              // 自己发的
              // window.localStorage.setItem(_this.genLastChatKey(data.fromId, data.toId), dataStr)
              Utils.$emit('On_Chats_AddMessage_ChangeLastMessage', data)
              Utils.$emit('chatTextClear')
              Utils.$emit('WebSocket_AddMessageFromLocal_ChangeChatList', data)
              Utils.$emit('AppVue_ChangeUnreadNums', data.fromId == _this.user.id ? data.toId : data.fromId, 0, 0);
              _this.updateLastReadTime(data.toId)
              _this.insertHistoryToCache(data.fromId, data.toId, data.text);
            }


          }

        }
      };

      //关闭事件
      socket.onclose = function () {
        _this.socketDisable = true
        if (_this.tryTime <= 10) {
          _this.initSocket()
          _this.tryTime++
        }
        Utils.$emit('App_Websocket_Close')

      };
      //发生了错误事件
      socket.onerror = function () {

        if (_this.tryTime <= 10) {
          _this.initSocket()
          _this.tryTime++
        }
        Utils.$emit('App_Websocket_Error')

      }
    },
    addUnReadMessageNumber() {
      this.unReadMessageNumber++;
      window.localStorage.setItem(this.user.id + ":unreadMessageNumber", this.unReadMessageNumber)
    },
    updateLastChatTime(toId, time) {
      request("post",)
    },
    addMsg() {

    },

    appUpdateCacheDefaultUserBasic: function () {
      this.appGetAndUpdateDefaultUserBasic()
    },
    appGetAndUpdateDefaultUserBasic() {
      request("get", "/user/getBasic").then(res => {
        if (res && res.code == 200) {
          this.user = res.object;
          window.sessionStorage.setItem("user_basic:" + this.user.id, JSON.stringify(this.user))
        } else {
          this.$message.error("请先登录")
          return
        }
      })
      return this.user;
    },
    appGetDefaultUserBasic() {
      request("get", "/user/getBasic").then(res => {
        if (res && res.code == 200) {
          return res.object;
        }
      })
      return null
    },
    appUpdateCacheUserBasic(userId) {
      window.sessionStorage.removeItem("user_basic:" + userId)

      return this.appGetAndUpdateUserBasic(userId)

    },
    appGetAndUpdateUserBasic(userId) {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + userId)
      let userBasic = null
      if (userBasicStr == null || userBasic == undefined) {

        request("get", "/user/getBasicById?userId=" + userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + userBasic.id, JSON.stringify(userBasic))
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
      }
      return userBasic
    },
    updateUserBasic() {

      let that = this;
      request("get", "/user/basic").then(res => {
        if (res && res.object) {
          that.userbasic = res.object;
        }
      })
    },

    toLogout() {
      if (!window.localStorage.getItem('tokenStr')) {
        this.$router.replace("/")
        this.$message("请先登录！");
        return;
      }
      this.$confirm('是否确定退出登录?', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/logout")
        window.localStorage.removeItem("userId")
        window.localStorage.removeItem("tokenStr")
        window.localStorage.removeItem("topic_types")
        window.sessionStorage.clear()
        // window.localStorage.removeItem("tokenStr");
        // window.sessionStorage.removeItem("tokenStr");
        // window.sessionStorage.removeItem("userId");
        // window.sessionStorage.removeItem("userRole")
        this.$router.replace('/')
        this.userbasic = {}
      }).catch(() => {

      });

    },
    toChats() {
      if (/mobile/i.test(navigator.userAgent)) {
        this.$router.push("phoneChat")

        // this.$route.meta.keepAlive = false
      } else {
        this.$router.push("webChat")
        // this.$route.meta.keepAlive = true
      }

    },
    toSearchKeyWordTopic() {
      // if (this.keyWord.replace("\S"))
      let keyWord = this.keyWord.replace("\\S")
      if (keyWord.length > 0) {

        this.$router.replace({
          path: '/search',
          query: {
            keyWord: keyWord
          }
        });

        Utils.$emit("Index_Change_Mode", {mode: 'keyWord', data: keyWord})
      }
    },

    toFocus() {
      if (this.$route.name !== 'FocusTopic') {
        this.$router.replace("focusTopic")
        Utils.$emit("Index_Change_Mode", {mode: 'focus'})
      }
    },

    toPeople() {
      // Utils.$emit("init_People", this.userId)
      window.sessionStorage.setItem("peopleUserId", window.localStorage.getItem("userId"));
      this.$router.push("people")
      window.location.reload()
    },
    getDefaultUserRole() {
      request("get", "/user/getUserRole").then(res => {
        if (res && res.code == 200) {
          window.sessionStorage.setItem("userRole", res.object)
          this.userRole = res.object
        }
      })
    },
    toLogin() {
      this.$router.push("login")
    },
    toManage() {
      this.$router.push("adminManage")
    },

    toMessage() {
      this.$router.push("message")
    },

    toIndex() {
      if (this.$route.name !== 'Index') {
        this.$router.replace("index")
        Utils.$emit("Index_Change_Mode", {mode: 'index'})
      }
    },
    toSubmitVerify() {
      this.$router.push("submitVerify")
    },
    toUserInfo() {
      this.$router.push("userInfo")
    },
    toAnswer() {
      this.$router.push("answer")
    },
    toWriteTopic() {
      this.$router.push("writeTopic")
    },
    logout() {
      request("get", "/logout").then(res => {

        window.localStorage.removeItem("tokenStr")
        window.close();
      })
    },
    async getUserBasic() {
      let _this = this
      return new Promise(function (resolve) {

        let userBasicStr = window.sessionStorage.getItem("user_basic:" + _this.userId)
        if (!userBasicStr || _this.userId === 0) {
          request("get", "/user/getBasic").then(res => {
            if (res && res.code == 200) {
              resolve(res.object)

              // TODO
              // window.sessionStorage.setItem("user_basic:" + this.userId, JSON.stringify(userBasic))
            }
          })
        } else {
          resolve(JSON.parse(userBasicStr))
        }
      })

    },
    getUnReadedNotice() {
      request("get", "/message/unReaded").then(res => {
        this.unReadedNumber = res.object;
      })
    }

  }
}
</script>

<style lang="scss">
router-view {
  background-color: aliceblue;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  padding: 0px;

}

.el-button--primary.is-plain:active, .el-button--primary.is-plain:active {
  background: #0461cf;
  color: #f7fefe;
}

.el-button--primary.is-plain:hover, .el-button--primary.is-plain:hover {
  background: #0461cf;
  color: #f7fefe;
}

.el-button--primary.is-plain:focus, .el-button--primary.is-plain:focus {
  background: #0461cf;
  color: #f7fefe;
}

.el-button--primary.is-plain {
  background-color: #056de8;
  border-style: none;
  color: #f7fefe;
}

.my_menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: medium;
  cursor: pointer;
  width: 50px;
  text-align: center;
  max-width: 60px;
}

.el-menu-item {
  padding: 0 5px;
}

.el-submenu__title {
  padding: 0 5px;
}

.topnav_box::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: rgb(245, 245, 245);

}

.topnav_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  border-radius: 10px;
  background-color: rgb(245, 245, 245);

}


.topnav_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  background-color: #d2d2d2;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.moment-error {
  font-size: 20px;
  text-align: center;
  flex-direction: column;
  display: flex;
  color: whitesmoke;
  justify-content: center;
}

.moment-error:hover {
  border-radius: 3px;
  color: whitesmoke;
  background-color: #fb7474;
}

.moment-flush {
  font-size: 20px;
  text-align: center;
  flex-direction: column;
  display: flex;
  color: whitesmoke;
  justify-content: center;
}

.moment-flush:hover {
  border-radius: 3px;
  background-color: transparent;
}


.moment-flush-active {
  font-size: 20px;
  text-align: center;
  flex-direction: column;
  display: flex;
  color: whitesmoke;
  justify-content: center;
}

.moment-flush-active:hover {
  border-radius: 3px;
  background-color: #d5d5d5
}

.moment_top {
  background-color: rgba(237, 237, 237, 0)
}

.drag-box {
  height: 900px;
  width: 100vw;
  position: fixed;
  max-width: 450px;
  overflow-x: hidden;
  z-index: 10;
  background-color: white;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.scroll-top-enter-active,
.scroll-top-leave-active {
  transition: all 0.3s;
}

.scroll-top-enter,
.scroll-top-leave-to {
  opacity: 0;
  transform: translateY(50px);
}


.slide-enter-active,
.slide-leave-active,
.slide-leave-to-active {
  transition: all 0.3s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(0);
}

.slide-leave {
  transform: translateX(-100%);
}

</style>
