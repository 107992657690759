<template>
  <div class="row"
       style="">
    <div>
      <div class="row" v-if="fromId == userId">

        <div class="col" style="justify-content: center;">

          <el-switch
              class="col" style="justify-content: center;"
              @change="changeTopicUrgent(topicId)"
              v-model="isUrgent"
              inactive-color="#f6f6f6"
              active-color="#0461cf"
          >
          </el-switch>
        </div>
        <div class="col" style="justify-content: center; margin-left: 1vw;">
          <el-popover
              placement="bottom"

              class="col" style="justify-content: center;"
              title=""
              width=""
              trigger="hover"
              content="回答后会通过邮件提醒提问者">
            <el-button type="text" style="color: black;" slot="reference">特别提问 <i
                class="el-icon-warning-outline"></i></el-button>
          </el-popover>
        </div>
      </div>

      <div v-else-if="isUrgent">
        <el-popover

            placement="top-start"
            title=""
            width="200"
            trigger="hover"
            content="用户回答后会通过邮件提醒提问者">
          <el-tag slot="reference" type="success">特别提问</el-tag>
        </el-popover>
      </div>

    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  props: ['topicId', 'userId', 'fromId', 'isUrgent'],
  name: "TopicUrgent",
  watch: {
    topicId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.topicId = newVal
        this.init()
      }
    },
    userId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.userId = newVal
        this.init()
      }
    },
    fromId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fromId = newVal
        this.init()
      }
    },
    isUrgent: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isUrgent = newVal
        this.init()
      }
    },


  },
  mounted() {

    this.init();
  },
  data() {
    return {}
  },
  methods: {
    init() {
    },
    changeTopicUrgent() {
      request("post", "/topic/changeUrgentByTopicId", {topicId: this.topicId}).then(res => {
        if (res && res.code === 200) {
          // this.isUrgent = !this.myIsUrgent
          // this.myIsUrgent = !this.myIsUrgent
          this.$parent.topic.isUrgent = !this.$parent.topic.isUrgent
        }
      })
    },

  }
}
</script>

<style scoped>

</style>