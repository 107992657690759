<template style="width: 1000px;min-height: 100vh;">
  <div style="background-color: #F6F6F6;overflow-x: hidden;  margin: 0 auto;">
    <div>
      <div v-if="!isPhone">

        <div class="col" style="width: 1000px;margin: 0 auto; aspect-ratio:3;">
          <div v-if="userId == peopleUserId" class="row"
               style="position: relative; justify-content: flex-end;top: 20px; right: 20px; height: 0px;width: 1000px; z-index: 1;">
            <div style="justify-content: flex-end;">

              <el-button @click="changeFile" type="info" style="" plain>编辑个人封面</el-button>
              <input v-show="false" id="fileInput" @change="changeFileHidden($event)" type="file"/>
            </div>
          </div>
          <el-image
              lazy
              style="width: 1000px; aspect-ratio: 3; overflow: hidden; "
              :src="peopleUser.background">
            <loading-image slot="placeholder"></loading-image>
            <error-image slot="error"></error-image>
          </el-image>
        </div>
        <div class="row"
             style="width: 1000px;margin: 0px auto;height: auto; height: 250px; background-color: white; ">
          <div style="width: 40px;"></div>
          <div style="height: 25vw;width: 25%; position: relative;   max-width: 200px; padding-bottom: 10px;">

            <el-avatar
                style="position: relative; top: -4vw; width: 200px; height: 200px; border-radius: 10px;"
                :preview-src-list="[peopleUser.avatar]"
                shape="square" alt=""
                :src="peopleUser.avatar"></el-avatar>
          </div>
          <div class="row" style="width: 100vw; max-height: 260px; ">
            <div style="width: 40px;"></div>
            <div class="col" style=" flex: 1;">
              <div style="">
                <span style="float:left;font-size: 25px;padding: 10px;">{{ peopleUser.nickname }}</span>
                <!--            <span style="float: left;padding: 10px;line-height: 35px;">{{ peopleUser.sign }}</span>-->
              </div>

              <div v-if="peopleUser.sex==='男'" style="text-align: left;padding: 10px;font-size: 25px;">
                <i class="el-icon-male"></i>
              </div>
              <div v-else-if="peopleUser.sex==='女'" style="text-align: left;padding: 10px;font-size: 25px;">
                <i class="el-icon-female"></i>
              </div>
              <!--            <div v-else style="text-align: left;padding: 10px;font-size: 25px;">-->

              <!--            </div>-->

              <div v-if="peopleUser.authDetail.length > 0" style="flex: 1; text-align: left;" class="row">
                <div style="line-height: 30px;font-weight: 700;margin-right: 10px;">
                  认证信息：
                </div>
                <div style="line-height: 30px;">
                  {{ peopleUser.authName }}
                  <div class="ProfileHeader-divider"></div>
                  {{ peopleUser.authDetail }}
                  <el-tooltip content="官方认证" placement="top-start" style="cursor:pointer">
                    <img style="width: 20px;height: 20px;"
                         :src="peopleUser.authIconUrl"/>
                  </el-tooltip>
                </div>

              </div>

              <div v-if="peopleUser.sign.length > 0"
                   style="text-align: left;flex: 1; " class="row">
                <div style="line-height: 30px;font-weight: 700;margin-right: 10px;">
                  个性签名：
                </div>
                <div style="line-height: 30px;">
                  {{ peopleUser.sign }}
                </div>
              </div>

              <div class="row" style="width:100%;justify-content: flex-end;">
                <div class="row">
                  <div style="width: 2vw; max-width: 20px;"></div>
                  <chat v-if="userId != peopleUserId" v-bind:toUserId="peopleUserId"></chat>
                  <div style="width: 2vw; max-width: 20px;"></div>
                  <div v-if="userId != peopleUserId" style="padding: 0px;"
                       class="row">
                    <el-button icon="el-icon-plus" v-if="focusStatus==false"
                               @click="addFocus" type="primary" plain size="large">
                      关注{{
                        peopleUser.sex === '男' ? '他' :
                            peopleUser.sex === '女' ? '她' : 'TA'
                      }}
                    </el-button>
                    <el-button size="large" v-if="focusStatus==true" @click="addFocus"
                               type="info"
                    >取消关注
                    </el-button>

                    <div style="width: 2vw; max-width: 20px;"></div>

                  </div>
                  <div v-else style="padding: 0px;float: right;">
                    <el-button @click="toUserInfo" type="primary" plain>编辑个人信息</el-button>
                  </div>
                  <div style="width: 2vw; max-width: 20px;"></div>
                </div>
              </div>
              <div style="height: 2vw; max-height: 20px;"></div>
            </div>


          </div>

        </div>
      </div>
      <div v-else >
        <div class="col" style="width: 100vw;margin: 0 auto; aspect-ratio:3;">
          <div v-if="userId == peopleUserId" class="row"
               style="position: relative; justify-content: flex-end;top: 20px; right: 20px; height: 0px;width: 1000px; z-index: 1;">
            <div style="justify-content: flex-end;">

              <el-button @click="changeFile" type="info" style="" plain>编辑个人封面</el-button>
              <input v-show="false" id="fileInput" @change="changeFileHidden($event)" type="file"/>
            </div>
          </div>
          <el-image

              lazy
              style="width: 100vw; aspect-ratio: 3; overflow: hidden; "
              :src="peopleUser.background">
            <loading-image slot="placeholder"></loading-image>
            <error-image slot="error"></error-image>
          </el-image>
        </div>
        <div class="col phone"
             style="margin: 0px auto; background-color: white; ">
          <!--          <div style="width: 40px;"></div>-->
          <div class="row" style="height: 22vw;">
            <!--     头像       -->
            <div>
              <el-image
                  lazy
                  :preview-src-list="[peopleUser.avatar]"
                  style="width: 20vw; aspect-ratio: 1; overflow: hidden; border-radius: 10px;"
                  :src="peopleUser.avatar">
                <loading-image slot="placeholder"></loading-image>
                <error-image slot="error"></error-image>
              </el-image>
            </div>
            <div style="margin-left: 10px; ">
              <div class="col" style="height: 50%; justify-content: center;">
                <div style="line-height: 100%;">
                  {{ peopleUser.nickname }}
                </div>
              </div>
              <div class="col" style="height: 50%; justify-content: center;">
                <div style="line-height: 100%;">
                  <div v-if="peopleUser.sex==='男'" style="text-align: left;font-size: 25px;">
                    <i class="el-icon-male"></i>
                  </div>
                  <div v-else-if="peopleUser.sex==='女'" style="text-align: left;font-size: 25px;">
                    <i class="el-icon-female"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="row" style="flex: 1;justify-content: flex-end;">
              <div class="col">
                <chat class="btn" v-if="userId != peopleUserId" v-bind:toUserId="peopleUserId"></chat>
                <div v-if="userId != peopleUserId" style="padding: 0px; margin-right: 5px; margin-top: 10px;"
                     class="row">
                  <el-button class="btn" icon="el-icon-plus" v-if="focusStatus==false"
                             @click="addFocus" type="primary" plain size="medium">
                    关注
                  </el-button>
                  <el-button class="btn" size="medium" v-else  @click="addFocus"
                             type="info"
                  >取消关注
                  </el-button>
                </div>
                <div v-else style="width: 20vw;">
                  <el-button @click="toUserInfo" type="primary" plain>编辑</el-button>
                </div>
                <div style="width: 2vw; max-width: 20px;"></div>
              </div>
            </div>

          </div>
          <div class="row" style="margin-top: 2px;">
            <div class="col" style="">
              <div v-if="peopleUser.authDetail.length > 0" style=" text-align: left;" class="row">
                <div style="min-width: 25vw;word-break: break-word; line-height: 30px;font-weight: 700;">
                  认证信息：
                </div>
                <div style="line-height: 30px;">
                  {{ peopleUser.authName }}
                  <div class="ProfileHeader-divider"></div>
                  {{ peopleUser.authDetail }}
                  <el-tooltip content="官方认证" placement="top-start" style="cursor:pointer">
                    <img style="width: 20px;height: 20px;"
                         :src="peopleUser.authIconUrl"/>
                  </el-tooltip>
                </div>

              </div>

              <div v-if="peopleUser.sign.length > 0"
                   style="text-align: left;flex: 1; " class="row">
                <div style="min-width: 25vw;word-break: break-word; line-height: 30px;font-weight: 700;">
                  个性签名：
                </div>
                <div style="line-height: 30px;">
                  {{ peopleUser.sign }}
                </div>
              </div>


            </div>


          </div>

        </div>
      </div>

      <div class="row" style="width: 100vw; max-width: 1000px;margin: 5px auto; ">
        <div style="margin: 0px;width: 99vw; max-width: 650px;background-color: white;min-height: 800px;">

          <el-tabs style="padding:2vw; width: 96vw;  max-width: 580px; z-index: 0;"  v-model="activeName">
            <el-tab-pane lazy label="提问" name="first">
              <people-question></people-question>
            </el-tab-pane>
            <el-tab-pane lazy label="回答" name="second">
              <people-answer></people-answer>
            </el-tab-pane>
            <el-tab-pane lazy label="收藏" name="third">
              <people-collect></people-collect>
            </el-tab-pane>
            <el-tab-pane lazy label="关注" name="fourth">
              <el-tabs v-model="focusType">
                <el-tab-pane lazy label="关注的人" name="focusPeople">
                  <people-focus style="width: 100vw; max-width: 700px;"></people-focus>
                </el-tab-pane>
                <el-tab-pane lazy label="关注的话题" name="focusTopic">
                  <people-focus-topic style="width: 100vw; max-width: 700px;"></people-focus-topic>
                </el-tab-pane>

              </el-tabs>

            </el-tab-pane>
            <el-tab-pane lazy label="粉丝" name="firth">
              <people-fans style="width: 100vw; max-width: 700px;"></people-fans>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="col" style="flex: 1;">

          <div class="col"
               style="margin-left:10px;padding: 10px; background-color: white;height: 160px;">
            <div style="text-align: left;padding: 3px;font-size: 18px;color: #606266;font-weight: 800">
              个人成就
            </div>
            <el-divider></el-divider>
            <div class="row" style="float:left;margin-left: 10px;padding: 5px;">
              <el-image class="good" style="width: 20px;height: 20px;margin-right: 10px;"
                        src="good1.png">
              </el-image>
              <div>获得 {{ zanLength }} 次赞同</div>
            </div>
            <div class="row" style="float:left;margin-left: 10px;padding: 5px;margin-top: 5px;">
              <el-image class="good" style="width: 20px;height: 20px;margin-right: 10px;margin-top: 1px;"
                        src="collection1.png">
              </el-image>
              <div>被收藏 {{ collectLength }}次</div>
            </div>
          </div>
          <div class="row"
               style="margin-left:10px;margin-top:10px;padding: 1px;background-color: white;height: 90px;">
            <div style="width: 49%;height: 100%">
              <div class="col">
                <el-button @click="choiceShowFocusPeople" type="text" style="font-size: 18px;">

                  <div style="line-height: 40px;height: 45px;color: #909399">关注了</div>
                  <div style="line-height: 5px;height: 45px;color: #606266;font-size: 20px;font-weight: 800">
                    {{ focusLength }}
                  </div>
                </el-button>
              </div>
            </div>
            <div style="width: 1px;background-color: rgb(246,246,246)"></div>
            <div style="width: 49%;height: 100%">
              <div class="col">
                <el-button @click="activeName = 'firth'" type="text" style="font-size: 18px;">

                  <div style="line-height: 40px;height: 45px;color: #909399">关注者</div>
                  <div style="line-height: 5px;height: 45px;color: #606266;font-size: 20px;font-weight: 800">
                    {{ focusedLength }}
                  </div>
                </el-button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <el-dialog title="图片剪裁" width="100%" style="width: 100vw; max-width: 800px; margin: 0 auto;"
                 :visible.sync="dialogVisible" append-to-body>
        <div class="cropper-content">
          <div class="cropper" style="text-align:center">
            <VueCropper


                ref="cropper"
                :img="option.img"
                :outputSize="option.outputSize"
                :outputType="option.outputType"
                :info="option.info"
                :canScale="option.canScale"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :fixed="option.fixed"
                :fixedBox="option.fixedBox"
                :fixedNumber="option.fixedNumber"
            ></VueCropper>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="finishCrop">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PeopleQuestion from "../components/people/PeopleQuestion";
import {request} from "../util/api";
import PeopleAnswer from "../components/people/PeopleAnswer";
import PeopleFocus from "../components/people/PeopleFocus";
import PeopleFans from "../components/people/PeopleFans";
import PeopleCollect from "../components/people/PeopleCollect";
import PeopleFocusTopic from "../components/people/PeopleFocusTopic";
import Chat from "@/components/im/Chat";
import Utils from "@/util/utils";
import {VueCropper} from 'vue-cropper'
import LoadingImage from "@/components/Icon/LoadingImage";
import ErrorImage from "@/components/Icon/ErrorImage";

Vue.component('myTopic', PeopleQuestion)

export default {
  name: "People",
  data() {
    return {
      dialogVisible: false,
      focusStatus: false,
      activeName: "first",
      focusType: "focusPeople",
      peopleUser: {},
      peopleUserId: null,
      zanLength: 0,
      collectLength: 0,
      focusLength: 0,
      focusedLength: 0,
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 500, // 默认生成截图框宽度
        autoCropHeight: 250, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [3, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
      },
      isPhone: false,
    }
  },
  methods: {

    changeFile() {
      console.log(document.getElementById("fileInput"))
      document.getElementById("fileInput").click()
    },
    changeFileHidden(e) {
      let file = e.target.files[0]
      this.$nextTick(() => {
        this.option.img = URL.createObjectURL(file)
        this.dialogVisible = true;
      })
    },
    finishCrop() {
      this.$refs.cropper.getCropBlob((data) => {
        var formData = new FormData();
        formData.append("file", data);
        request("post", "/user/updateBackground", formData).then(res => {
          if (res && res.code == 200) {
            window.sessionStorage.getItem("")
            this.peopleUser.background = res.object;
            this.dialogVisible = false
            this.$message.success("更换背景成功")
            window.location.reload()
          }
        })
      })
    },
    init() {
      if (window.localStorage.getItem('tokenStr')) {
        this.peopleUserId = window.sessionStorage.getItem("peopleUserId");
        if (!this.peopleUserId) {
          return;
        }
        window.sessionStorage.removeItem("user_basic:" + this.peopleUserId);
        this.getUserInfo()
        request("post", "/user/getAchievementByUserId", {id: this.peopleUserId}).then(res => {
          if (res.code == 200) {
            this.zanLength = res.object.zanLength;
            this.collectLength = res.object.collectLength;
            this.focusLength = res.object.focusLength;
            this.focusedLength = res.object.focusedLength;

          }
        })
        this.userId = window.localStorage.getItem("userId");
        if (this.peopleUserId != this.userId)
          this.getFocusStatus();

      } else {
        this.$router.replace("login")
        this.$message("请先登录！")
        return
      }
    },
    choiceShowFocusPeople() {
      this.activeName = 'fourth';
      this.focusType = 'focusPeople'
    },
    toUserInfo() {
      this.$router.push("userInfo")
    },
    toPeople() {
      window.sessionStorage.setItem("peopleUserId", window.localStorage.getItem("userId"));
      this.$router.push("people")
    },
    getFocusStatus() {
      request("post", "/focus/getStatus", {toId: this.peopleUserId}).then(res => {
        if (res.code == 200) {
          this.focusStatus = res.object;

        }
      })

    },
    addFocus() {
      request("post", "/focus/add", {toId: this.peopleUserId}).then(res => {
        if (res.code == 200) {
          this.focusStatus = !this.focusStatus;
        }
      })
    },
    getUserInfo() {
      let that = this
      request("post", "/user/getInfoById", {id: this.peopleUserId}).then(res => {
        if (res.code == 200) {
          that.peopleUser = res.object
        }
      })
    },
  },
  toUserInfo() {
    this.$router.push("userInfo")
  },
  mounted() {
    this.init()
    let _this = this
    if (/mobile/i.test(navigator.userAgent)) {
      this.isPhone = true
    }
    Utils.$on("init_People", function () {
      // _this.init()
      window.location.reload()
    })


  },
  components: {
    ErrorImage,
    LoadingImage,
    VueCropper,
    Chat,
    PeopleFocusTopic,
    PeopleCollect,
    PeopleFans,
    PeopleFocus,
    PeopleAnswer,
    PeopleQuestion,

  }
}
</script>

<style lang="scss" scoped>
.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
}

.cropper {
  width: 500px;
  height: 500px;
  background: yellow;
}

.el-button--info.is-plain:focus, .el-button--info.is-plain:hover {
  background: transparent;
  color: #babbbc;
}

.el-button--info.is-plain {

  background-color: transparent;

  color: #babbbc;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background: #ebebeb;
}
.btn {
  width: 100px;
}
.phone {
  padding: 20px;
  width: calc(100vw - 40px);
}
</style>
