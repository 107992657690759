<template>
  <div style="flex-direction: column;display:flex;">
    <div style="flex-direction: row;display:flex;">
      <el-avatar slot="" :size="45">
        <img :src="user.avatar"/>
      </el-avatar>
      <div style="display: flex;flex-direction: column;margin: 0 10px">
        <div style="display: flex; flex-direction: row;">

          <div style="height: 50%;line-height: 22.5px">
            {{ user.nickname }}
          </div>
          <el-tooltip v-if="user.authIconUrl && user.authIconUrl.length > 0" :content="user.authName" placement="top-start"
                      style="cursor:pointer">
            <img style="width: 15px;height: 15px;"
                 :src="user.authIconUrl"/>
          </el-tooltip>

        </div>
        <div style="font-size: 10px;height: 50%;line-height: 22.5px;color: #909399">
          {{ user.sign }}
        </div>
      </div>


    </div>
    <div  v-if="user.authName && user.authName && user.authName.length > 0">
      <div class="my_devider"></div>
      <div class="row">
        <el-tooltip content="官方认证" placement="top-start" style="cursor:pointer;padding: 1px;">
          <img style="width: 20px;height: 20px;"
               :src="user.authIconUrl"/>
        </el-tooltip>
        <div style="height: 50%;line-height: 22.5px" class="row">
          {{ user.authName }}
          <div class="ProfileHeader-divider"></div>
          {{ user.authDetail }}
        </div>
      </div>


    </div>
    <div class="row" style="margin-top: 20px;">
      <el-button @click="showPeopleInfo(user.id)"  type="primary" plain>主页
      </el-button>

      <Chat v-if="flag"  style="margin-left: 10px;" v-bind:toUserId="user.id"></Chat>
    </div>

  </div>
</template>

<script>
import {request} from "@/util/api";
import Chat from "@/components/im/Chat";
import Utils from "@/util/utils";

export default {
  name: "UserIntroduction",
  components: {Chat},
  props: ['parentUser', 'userId'],
  data() {
    return {
      user:{},
      flag: true,
    }
  },
  watch: {
    parentUser: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.parentUser = newVal
        this.user = newVal
      }
    },
    // userId:  function (newVal, oldVal) {
    //   // if (newVal !==  oldVal) {
    //   //
    //   // }
    // }
  },
  mounted() {
    if ((typeof (this.userId) === undefined && typeof (this.parentUser) === undefined)) {
      return
    }
    this.getUserInfo()
    this.flag = this.user.id != window.localStorage.getItem('userId')
  },
  methods: {
    showPeopleInfo(id) {
      window.sessionStorage.setItem("peopleUserId", id);
      // window.open("/#/people", "_blank");
      if (this.$route.name == "People") {
        window.location.reload()
      } else {
        this.$router.replace("/people")

      }
    },
    getUserInfo() {
      if (this.parentUser == undefined) {
        request("get", "/user/getInfoById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            this.user = res.object
          }

        })
      } else {
        this.user = this.parentUser
      }

    },
  }

}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 4px 4px;
  background: #ebebeb;
}


.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>