import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import ElementUI from "element-ui"

import router from './router'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.use(router)





new Vue({

  router,
  render: h => h(App)
}).$mount('#app')
