<template>
  <div style="">
    <div>
      <div style="text-align: left">它的收藏({{ answers.length }})</div>
      <el-divider></el-divider>

      <div v-for="(answer) in answers.slice((currentPage-1)*pageSize,pageSize*currentPage)">
        <topic-title style="margin-bottom: 10px;" v-bind:topicID="answer.topicId"></topic-title>
        <answer-single v-bind:user-id="userId" v-bind:answer-id="answer.id"></answer-single>
        <el-divider></el-divider>
      </div>
      <div class="block" style="text-align: left">
        <el-pagination
            :hide-on-single-page="false"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="answers.length">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "../../util/api";
import TopicTitle from "@/components/single/TopicTitle";
import AnswerSingle from "@/components/single/AnswerSingle";

export default {
  name: "PeopleCollect",
  components: {AnswerSingle, TopicTitle},
  data() {
    return {
      answers: [],
      userId: 0,
      //分页
      pageSize: 10,
      currentPage: 1,

    }
  },
  methods: {
    getCollectAnswerByUserId(userID) {
      request("get", "/answer/getCollectAnswerByUserId?userID="+userID).then(res => {
        if(res && res.code == 200) {
          this.answers = res.object.reverse()
        }
      })
    },
    init() {
      this.userId = window.sessionStorage.getItem("peopleUserId")
      if (!this.userId) {
        return
      }
      this.getCollectAnswerByUserId(this.userId)

    },

    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
  },
  created() {
    if (window.localStorage.getItem('tokenStr')) {
      this.init();
    }
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
