<template>
  <div>

    <div style="">
      <el-form :rules="emailRules" ref="emailForm" :model="emailForm">
        <el-form-item prop="email" label="">
          <el-input placeholder="邮箱地址" style="" v-model="emailForm.email"
                    autocomplete="off"></el-input>

        </el-form-item>
        <el-form-item prop="emailCode">
          <div class="row">
            <el-input placeholder="输入6位验证码"
                      v-model="emailForm.emailCode"
            ></el-input>
            <div style="margin-left: 20px;">
              <el-button :loading="!canPost" @click="postEmailCode(60)" type="primary">
                {{ forgetBtnTips }}
              </el-button>
            </div>
          </div>

        </el-form-item>
      </el-form>
      <div style="text-align: right;height: 40px;">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="width: 100%;" type="primary" @click="submitEmailAndCode()">绑定
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "BindEmail",
  data() {
    return {
      emailRules: {
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: "email", message: "请输入正确的邮箱地址", trigger: "blur"}
        ],
      },
      forgetBtnTips: "发送验证码",
      emailForm: {
        email: '',
        emailCode: '',
      },
      canPost: true,
    }
  },
  methods: {
    submitEmailAndCode() {
      this.$refs['emailForm'].validate((valid) => {
        if (valid) {
          if (this.emailForm.emailCode.length !== 6) {
            this.$message.error("请输入正确的验证码");
            return
          }

          request("post", "/user/bindEmail", {
            email: this.emailForm.email,
            code: this.emailForm.emailCode,
          }).then(res => {
            if (res && res.code == 200) {
              window.location.reload()
              this.$message.success("绑定成功");
            }
          })
        }
      })
    },

    postEmailCode(time) {

      this.$refs['emailForm'].validate((valid) => {
        if (valid) {
          if (time === 60) {
            this.canPost = false;
            request("post", "/registerCode/postEmailCode", {
              email: this.emailForm.email,
              type: 1
            }).then(res => {
              if (res.code !== 200) {
                this.forgetBtnTips = "重新发送验证码"
                this.canPost = true;
                return
              }
            })
          }
          setTimeout(() => {
            this.forgetBtnTips = time + "s";
            if (time > 0)
              this.postEmailCode(time - 1)
            else {
              this.forgetBtnTips = "重新发送验证码"
              this.canPost = true;
              return
            }
          }, 1000)
        }
      })


    },
  }
}
</script>

<style scoped>

</style>