<template>
  <div style="width: 98%;">
    <div class="row" style="">

      <div style="width: 50px;">
        <UserAvatarV1 v-bind:size="50" v-bind:user-id="moment.fromId"></UserAvatarV1>
      </div>
      <div class="col moment-detail" style="margin-left: 10px;">
        <UserName v-bind:user-id="moment.fromId"></UserName>
        <div style="text-align: left;margin-top: 10px;white-space: pre-wrap;word-wrap: break-word;">{{ text }}</div>
        <div style="text-align: left" v-if="fullText.length > 500">
          <el-button @click="showAll()" v-if="moment.isShowAll !== true" type="text">阅读全文<i
              class="el-icon-arrow-down"></i></el-button>
          <el-button @click="showAll()" v-else type="text">收起<i
              class="el-icon-arrow-up"></i></el-button>
        </div>
        <div
            style="width: 100%; max-width: 420px;margin-top: 10px; flex-direction: row; display: flex; flex-wrap: wrap;">
          <div style="margin-top: 5px; width: 30%; margin-right: 5px;" v-for="(url) in moment.imgUrlList">
            <div class="col" style="">


              <el-image
                  style=" aspect-ratio:1; border-radius: 0px; margin-right: 0px;"
                  :preview-src-list="moment.imgUrlList"
                  class="el-upload-list__item-thumbnail"
                  :src="url" alt=""
                  fit="cover"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>

        </div>
        <div style="text-align: left;color: #C0C4CC;margin-top: 10px;"> &ensp; &ensp;{{ func(moment.gmtCreate) }}</div>

        <div style="">
          <div class="row" style="left:0px; margin-top: 10px;">
            <!--                            点赞-->
            <div style="height: 30px;padding: 0px;">
              <el-button v-model="zan.length" style="margin-left: 0px;height: 30px;" size="small" type="text">
                <div style="display: flex;flex-direction: row;"
                     @click="addZan()">
                  <!--                图案-->
                  <div style="" v-if="zan.isFromUser">
                    <el-image class="good" style="width: 20px;height: 20px;"
                              src="good2.png">
                    </el-image>
                  </div>
                  <div v-else>
                    <el-image class="good" style="width: 20px;height: 20px; "
                              src="good1.png">
                    </el-image>
                  </div>
                  <!--数字-->
                  <div style="line-height: 20px;padding: 1px;"
                       v-if="zan.length>0">
                    <div style="color:#13227a;" v-if="zan.isFromUser">
                      {{ zan.length }}
                    </div>
                    <div style="color: #8c939d" v-else>
                      {{ zan.length }}
                    </div>
                  </div>
                  <div style="color: #8c939d;line-height: 20px;" v-else>点赞</div>
                </div>
              </el-button>
            </div>

            <el-button type="text"
                       @click="showComment"
                       style="color: #8c939d;font-size: 12px;margin-left: 5px;"
                       size="small">
              <div style="flex-direction: row;display: flex">
                <div style="line-height: 20px;">
                  <el-image class="good" style="width: 20px;height: 20px;"
                            src="comment.png">
                  </el-image>
                </div>
                <div style="line-height: 20px;margin-left: 2px;">
                  {{ comment.length }}条评论
                </div>

              </div>
            </el-button>
            <el-button type="text" style="margin-left: 10px;line-height: 20px;"
                       v-if="moment.fromId==userId"
                       @click="deleteById(moment.id)" size="small">
              <el-image class="good" style="width: 20px;height: 20px;" src="delete1.png">
              </el-image>
            </el-button>
          </div>
          <Comment v-if="isShowComment"
                   style="width: 100%; height: fit-content;  border-style: solid;border-width: 1px; border-color: #DCDFE6;border-radius: 3px;"
                   v-bind:user-id="userId"
                   v-bind:type="5"
                   v-bind:typeId="moment.id"></Comment>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {request} from "@/util/api";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";
import func from "@/util/getDateTime";
import UserName from "@/components/UserInfo/UserName";
import Utils from "@/util/utils";
import Comment from "@/components/Comment";

export default {
  name: "MomentSingle",
  components: {Comment, UserName, UserAvatarV1},
  props: ['momentId'],
  watch: {
    momentId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init();
      }
    }
  },
  data() {
    return {
      moment: {},
      zan: {
        isFromUser: false,
        length: 0,
      },
      collect: {
        isFromUser: false,
        length: 0,
      },
      comment: {
        length: 0,
      },
      fullText: "",
      text: "",
      isShowComment: false,
      userId: 0,

    }
  },
  created() {
    let _this = this;
    Utils.$on("FlushMoment",async function () {
      _this.zan = await _this.getZanByMomentId(_this.momentId)
      _this.comment.length = await _this.getCommentNumsByMomentId(_this.momentId)
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    func(time) {
      return func(time);
    },
    async init() {

      if (typeof this.momentId === typeof undefined || this.momentId === undefined) {
        this.userId = 0
      }
      this.userId = window.localStorage.getItem("userId")
      if (typeof this.userId === typeof undefined || this.userId === undefined) {
        return
      }
      this.moment = await this.getMomentById(this.momentId)
      this.initMoment()
      // this.answerId = this.answer.id
      this.zan = await this.getZanByMomentId(this.momentId)
      this.comment.length = await this.getCommentNumsByMomentId(this.momentId)

    },
    getMomentById(id) {
      return new Promise(function (resolve) {

        request("get", "/moment/getMomentById?id=" + id).then(res => {
          if (res && res.code == 200) {
            resolve(res.object);

          }
        })
      })
    },

    showComment() {
      this.isShowComment = true;
    },

    initMoment() {
      this.moment.isShowAll = false
      this.fullText = this.moment.text;
      this.text = this.moment.text;
      if (this.text.length > 500) {
        this.text = this.text.slice(0, 500) + "..."
      }
    },

    async getZanByMomentId(momentId) {
      return new Promise(function (resolve) {
        request("get", "/zan/getByMomentId?momentId=" + momentId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object);
          }
        })
      })
    },

    async getCommentNumsByMomentId(momentId) {
      return new Promise(function (resolve) {
        request("get", "/comment/getNumsByMomentId?momentId=" + momentId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object);
          }
        })
      })
    },

    deleteById(id) {
      this.$confirm('确认删除该朋友圈吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/moment/deleteMomentById", {id: id}).then(res => {
          if (res.code == 200) {
            Utils.$emit('DeleteMoment', id)
          }
        })
      });
    },


    toColletion() {
      request("post", "/collect/add", {answerId: this.answer.id}).then(res => {
        if (res && res.code == 200) {

          if (res.object == -1) {
            this.collect.length--;
            this.collect.isFromUser = false;
          }
          if (res.object == 1) {
            this.collect.length++;
            this.collect.isFromUser = true;
          }
        }

      })
    },
    showAll() {
      if (this.text.length > 503) {
        this.text = this.text.slice(0, 500) + "..."
      } else {
        this.text = this.fullText
      }
      this.moment.isShowAll = !this.moment.isShowAll
    },
    addZan() {
      let _this = this
      request("post", "/zan/add", {typeId: this.moment.id, type: 5}).then(res => {
        if (res && res.code == 200) {

          if (_this.zan.isFromUser) {
            _this.zan.length -= 1;
          } else {
            _this.zan.length += 1;
          }
          console.log(_this.zan)
          this.zan.isFromUser = !this.zan.isFromUser;
          this.$forceUpdate()
        } else {
          this.$message.warning("点赞失败");
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.moment-detail {
  width: calc(100% - 50px);
}
</style>