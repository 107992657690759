<template>
  <div style="min-height: 1000px;background-color: white; overflow-x: hidden; width: 100vw;  max-width: 1000px; margin: 0 auto">
<!--    <div style="height: 1px;"></div>-->
    <div style="width:100%; margin: 0px auto;background-color: white;min-height: 100vh">
      <div style=" margin-top: 0px; width:100%;background-color: white;min-height: 800px;">

        <el-tabs  style="margin-left: 20px;" v-model="activeName">
          <el-tab-pane lazy label="问题" name="first">
            <Index></Index>
          </el-tab-pane>
          <el-tab-pane lazy label="用户" name="second">
            <SearchPeople></SearchPeople>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import SearchPeople from "@/components/search/SearchPeople";
import Index from "@/views/Index";

export default {
  name: "Search",
  components: {SearchPeople, Index},
  data() {
    return {
      keyWord: '',
      activeName:"first"
    }
  },
  mounted() {
    this.keyWord = this.$route.query.keyWord
  },
  methods: {}
}
</script>

<style scoped>

</style>