<template>
  <div style="display: flex; height: 100%; flex-direction: row; justify-content: center;"
       class="image-slot">
    <div style="height: 100%; display: flex; flex-direction: column; justify-content: center;">
      <div class="row">
        <div style="line-height: 100%;">

          加载失败
        </div>
        <div style="line-height: 100%;">

          <i style="" class="el-icon-picture-outline"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorImage"
}
</script>

<style scoped>

</style>