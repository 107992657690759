import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from "../views/Register";
import WriteTopic from "../views/WriteTopic";
import Answer from "../views/Answers";
import UserInfo from "../views/UserInfo";
import Message from "../views/Message";
import People from "../views/People";
import PeopleQuestion from "../components/people/PeopleQuestion";
import PeopleAnswer from "../components/people/PeopleAnswer";
import PeopleFocus from "../components/people/PeopleFocus";
import SendMessage from "../components/SendMessage";
import SubmitVerify from "@/components/verify/SubmitVerify";
import MyVerify from "@/components/verify/MyVerify";
import Verify from "@/views/Verify";
import Manage from "@/views/admin/Manage";
import AdminVerify from "@/views/admin/AdminVerify";
import Search from "@/views/Search";
import Index from "@/views/Index";
import WriteMoment from "@/views/WriteMoment";
import Callback from "@/components/wx/Callback";
import WX from "@/views/WX";
import WXWeb from "@/views/WX-Web";
import WXPhone from "@/views/WX-Phone";


// 重写push|replace方法
//先把VueRouter的push和replace方法保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
    // 此函数上下文(this指向)为VueRouter的一个实例
    if (resolve && reject) {    //如果我们自己指定了成功/失败的回调，则自己传入
        originPush.call(this, location, resolve, reject)
        //若此时直接使用originPush()方法，则函数内的this指向window（内部代码将无法执行）。故应用call或apply方法修改this指向
    } else {    //如果我们没有指定成功/失败的回调，则自动帮我们生成，防止报错
        originPush.call(this, location, () => {
        }, () => {
        })
    }
}

VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => {
        }, () => {
        })
    }
}
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/writeMoment',
        name: 'WriteMoment',
        component: WriteMoment
    },
    {
        path: '/adminManage',
        name: 'Manage',
        component: Manage
    },
    {
        path: '/adminVerify',
        name: 'AdminVerify',
        component: AdminVerify
    },
    {
        path: '/login',
        name: 'Register',
        component: Register,
        meta: {
            keepAlive: false
        }
    },

    {
        path: '/writeTopic',
        name: 'WriteTopic',
        component: WriteTopic
    },
    {
        path: '/search',
        name: 'Search',
        component: Search
    },
    {
        path: '/verify',
        name: 'Verify',
        component: Verify
    },

    {
        path: '/index',
        name: 'Index',
        component: Index,

    },
    {
        path: '/answer',
        name: 'Answer',
        component: Answer
    },
    {
        path: '/peopleAnswer',
        name: 'PeopleAnswer',
        component: PeopleAnswer
    }
    , {
        path: '/peopleQuestion',
        name: 'PeopleQuestion',
        component: PeopleQuestion
    }
    , {
        path: '/peopleFocus',
        name: 'PeopleFocus',
        component: PeopleFocus
    }
    , {
        path: '/sendMessage',
        name: 'SendMessage',
        component: SendMessage
    }
    , {
        path: '/focusTopic',
        name: 'FocusTopic',
        component: Index
    },
    {
        path: '/webChat',
        name: 'webChat',
        component: WXWeb,
        meta: {
            keepAlive: true,
        }
    },
    {
        path: '/phoneChat',
        name: 'phoneChat',
        component: WXPhone,
        meta: {
            keepAlive: false,
            noICP: true
        }
    },
    {
        path: '/userInfo',
        name: 'UserInfo',
        component: UserInfo
    },
    {
        path: '/message',
        name: 'Message',
        component: Message
    },
    {
        path: '/people',
        name: 'People',
        component: People,

    },
    {
        path: '/submitVerify',
        name: 'SubmitVerify',
        component: SubmitVerify,

    },
    {
        path: '/myVerify',
        name: 'MyVerify',
        component: MyVerify,

    }
    ,
    {
        path: '/callback',
        name: 'Callback',
        component: Callback,

    }

]

const router = new VueRouter({
    routes,
})

export default router
