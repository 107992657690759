<template>
  <div class="my" style="background-color: rgb(245,245,245); ">
    <el-button class="btn" icon="el-icon-s-promotion" @click="showChatBox()" size="medium" type="info" plain>私信
    </el-button>
    <el-dialog
        class="my-dialog-name"
        width="100%"

        :title="toUser.nickname"
        @opened="toBottom()"
        style="padding: 0; width: 800px;height: 100vh; margin: 100px auto;   overflow: hidden; font-size: 20px;  z-index: 99999999999;  "
        :append-to-body="true"
        :visible.sync="chatBox">
      <div v-if="!isMobile" style="margin-top: 0px;">
        <div  style="height: 30px;  border-bottom: 1px solid #ccc; padding: 2px; background-color: whitesmoke;">
          <div  style="width:fit-content;  margin-left: 20px;  justify-content: flex-start;">
            <user-name  style="font-weight: 500; margin-top: -15px;font-size: 20px; width: 300px;  "
                       v-bind:userId="toUserId"></user-name>
          </div>
        </div>
        <Im  id="immm"
            style="width: 100%; height: 100%; min-width: 250px; overflow-y: hidden;" v-bind:toUserId="toUserId"></Im>
      </div>
    </el-dialog>


    <div v-if="isMobile && chatBox" style="margin-top: 0px;">
      <WXChatWindowPhone style="overflow-x: hidden;"   v-bind:to-user-id="toUserId"></WXChatWindowPhone>
    </div>

  </div>
</template>

<script>
import {request} from "@/util/api";
import Im from "@/components/im/Im";
import WXChatWindowPhone from "@/views/WX-Chat-Window-Phone";
import UserName from "@/components/UserInfo/UserName";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";

export default {
  name: "Chat",
  components: {UserAvatarV1, UserName, WXChatWindowPhone, Im},
  props: ['toUserId'],
  data() {
    return {
      toUser: {},
      chatBox: false,
      isMobile: false,
    }
  },
  watch: {
    toUserId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.toUserId = newVal;
        this.init();
      }
    }
  },
  mounted() {
    if (/mobile/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    this.init()
  },
  methods: {
    init() {
      if (!this.toUserId || typeof this.toUserId === typeof undefined) {
        return;
      }
      this.getUserBasic(this.toUserId)
    },
    showChatBox() {
      this.toUserId = parseInt(this.toUserId)
      this.chatBox = true

    },
    getUserBasic(userId) {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + userBasic.id, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }
    },
  }
}
</script>

<style lang="scss">

.my-dialog-name {
  .el-dialog__header {

    padding: 15px 10px 15px 20px !important;
    background: rgb(245, 245, 245);
    min-width: 220px;
  }

  .el-dialog__body {
    padding: 0px 0px 0px 0px !important;

    ///*  background: rgb(245,245,245)*/
  }

  .el-dialog {
    margin-top: 0 !important;
  }


}
.btn {
  width: 100px;
}
</style>