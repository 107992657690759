<template>
  <div>

    <el-input type="textarea" rows="10" v-model="sql"></el-input>
    <div style="padding: 10px;">

      <el-button type="primary" plain @click="submitSql"> 执行</el-button>
    </div>
    <el-table
        ref="filterTable"
        :data="objects.slice((currentPage-1)*pageSize,pageSize*currentPage)"
        style="">

      <el-table-column v-for="(key, index) in keys" :key="index"
                       :prop="key"
                       :label="key"
      >
      </el-table-column>
    </el-table>
    <el-pagination
        :hide-on-single-page="false"
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[5,10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="objects.length">
      >
    </el-pagination>
    <div style="height: 100px;"></div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "SqlManage",
  data() {
    return {
      sql: "",
      text: "",
      objects: [{"key": "value"}],
      keys: [],
      //分页
      pageSize: 40,
      currentPage: 1,
    }
  },
  mounted() {

  },
  methods: {
    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
    submitSql() {
      request("post", "/admin/submitSql", {sql: this.sql}).then(res => {
        if (res && res.code == 200) {
          this.objects = res.object.data;
          this.keys = res.object.keys;
        }
      })
    }
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>