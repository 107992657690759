<template>
  <div v-loading="loading" style="max-width: 1000px;overflow-x: hidden; min-height: 160px;padding-bottom: 8px; ">
    <div>
      <div class="row" style="text-align: left">
        <UserBasic style="width: 60%;" v-bind:userId="topic.userId"></UserBasic>
        <div class="row" style="width: 40%;justify-content: flex-end;">

          <div class="col">
            <div class="row" style="justify-content: flex-end;">
              <el-dropdown v-if="topic.userId==userId">
                <span style="cursor: pointer;" class="el-dropdown-link">
                  ···
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="deleteTopicById">

                      删除
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <topic-urgent v-bind:topic-id="topicId"
                          v-bind:user-id="userId" v-bind:from-id="topic.userId"
                          v-bind:is-urgent="topic.isUrgent"></topic-urgent>
          </div>
        </div>
      </div>
      <div class="row" style="text-align: left;padding: 5px; flex: 1; word-wrap: break-word;">
        <div class="topic_title" @click="toAnswer(false)"
             style="flex: 1; width: fit-content; min-width: 200px;  font-size: 20px;cursor:pointer"
        >
          {{ topic.title }}
        </div>

        <div class="" style="width: fit-content;text-align: right;padding: 2px; margin-left: 4px;">
          <div class="row" style="justify-content: flex-end;">
            <el-button type="text" style="font-size: 15px;width: 50px;">
              <div style="line-height: 10px;height: 25px;color: #909399">关注量</div>
              <div style="line-height: 0px;height: 20px;color: #606266;font-size: 15px;font-weight: 800">
                {{ focus.length }}
              </div>
            </el-button>
            <el-button type="text" style="font-size: 15px;width: 50px; margin-left: 20px;">
              <div style="line-height: 10px;height: 25px;color: #909399">回答</div>
              <div style="line-height: 0px;height: 20px;color: #606266;font-size: 15px;font-weight: 800">
                {{ answerNums }}
              </div>
            </el-button>
          </div>
        </div>
      </div>

      <div style="padding: 5px;margin-left: 10px;" v-html="text"></div>
      <div style="text-align: left;margin-left: 10px;padding: 2px;" v-if="fullText.length > 500">
        <el-button @click="showAll()" v-if="topic.isShowAll!=true" type="text">阅读全文<i
            class="el-icon-arrow-down"></i></el-button>
        <el-button @click="showAll()" v-if="topic.isShowAll==true" type="text">收起<i
            class="el-icon-arrow-up"></i></el-button>
      </div>


      <div class="row" style="width: 100%; height: 30px;line-height: 30px;">
        <div class="row" style="width: 50%;">
          <div v-if="!focus.isFromUser" style="text-align:left;margin-left: 10px;">
            <el-button type="primary" plain size="small"
                       @click="focusProblem()">
              关注问题
            </el-button>
          </div>
          <div v-else style="text-align:left;margin-left: 10px;">
            <el-button type="info" size="small"
                       @click="focusProblem()">
              取消关注
            </el-button>
          </div>
          <div style="text-align:left;margin-left: 15px;"
               v-if="$route.name != 'answer' || $route.query.isAnswer !== 'true'">
            <el-button icon="el-icon-edit" type="primary" plain size="small"
                       @click="toAnswer(true)">
              写回答
            </el-button>
          </div>
          <div class="col"
               style="justify-content: center;margin-left: 10px;margin-top: 5px; ">

            <Share style=" justify-content: center;" v-bind:type="'topic'"
                   v-bind:type-id="topicId"></Share>
          </div>

        </div>
        <div style="width: 50%;justify-content: flex-end; word-break: break-word;" class="row">
          <div style="  text-align: right;color: #C0C4CC">{{ func(topic.gmtCreate) }}</div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";
import func from "@/util/getDateTime";
import UserBasic from "@/components/UserInfo/UserBasic";
import Utils from "@/util/utils";
import Share from "@/components/Icon/Share";
import TopicUrgent from "@/components/Icon/TopicUrgent";

export default {
  name: "TopicSingle",
  components: {TopicUrgent, Share, UserBasic},
  props: ['topicId'],
  data() {
    return {
      focusNums: 0,
      answerNums: 0,
      canViewAll: true,
      focus: {
        isFromUser: false,
        length: 0,
      },
      fullText: "",
      text: "",
      loading: false,
      topic: {},
      userId: 0,


    }
  },
  watch: {
    // userId: function (newVal, oldVal) {
    //   if (newVal.topicId !== oldVal.topicId) {
    //     this.userId = newVal
    //   }
    // },
    // topic: function (newVal, oldVal) {
    //   if (newVal.topicId !== oldVal.topicId) {
    //     this.topic = newVal;
    //     this.init();
    //   }
    // },
    topicId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.topic = {}
        this.topicId = newVal;
        this.init();
      }
    }


  },
  async mounted() {
    await this.init();
  },
  methods: {
    toTopic() {

    },
    toAnswer(isAnswer) {
      if (this.$route.name != 'Answer') {
        this.$router.push({
          path: '/answer',
          query: {
            isAnswer: isAnswer,
            topicId: this.topic.topicId,
          }
        });
      } else {
        Utils.$emit('Answers_ChangeIsAnswer', this.topic.topicId)
      }


    },
    focusProblem() {
      if (this.topic.topicId == null) {
        return;
      }
      request("post", "/focusTopic/add", {topicId: this.topic.topicId}).then(res => {
        if (res && res.code == 200) {
          this.focus.isFromUser = !this.focus.isFromUser;

          if (res.object == -1) {
            this.focus.length--;
          }
          if (res.object == 1) {
            this.focus.length++;
          }
        }

      })
    },
    async init() {
      if (this.topicId == null || this.topicId == undefined || typeof this.topicId === typeof undefined) {
        return;
      }
      this.userId = window.localStorage.getItem("userId")
      if (typeof this.userId === typeof undefined || this.userId === undefined) {
        this.userId = 0
      }


      this.getTopicById(this.topicId)

      if (!this.topic.topicId) {
        return
      }

      this.getFocusByTopicId(this.topicId)
      this.getAnswerNumsByTopicId(this.topicId)


    },
    showComment() {
      this.isShowComment = true;
    },
    initTopic() {
      this.topic.isShowAll = false
      this.fullText = this.topic.context;
      this.text = this.topic.context;
      if (this.topic.context.length > 500) {
        this.text = this.topic.context.slice(0, 500) + "..."
      }
    },
    async getFocusByTopicId(topicId) {
      let _this = this;
      return new Promise(function (resolve) {
        request("get", "/focus/getByTopicId?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {
            _this.focus = res.object
            // resolve(res.object);
          }
        })
      })
    },
    async getAnswerNumsByTopicId(topicId) {
      let _this = this;
      return new Promise(function (resolve) {

        request("get", "/topic/getAnswerNums?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {
            _this.answerNums = res.object;
            resolve(res.object)
          }
        })
      })
    },

    deleteTopicById() {
      this.$confirm('确认删除该问题吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/topic/deleteById", {topicId: this.topic.topicId}).then(res => {
          console.log(res, 666)
          if (res && res.code == 200) {
            console.log(Utils.$emit('On_AppVue_DeleteTopic', this.topic.topicId), 666)
          }
        })

      }).catch(() => {
      });
    },

    async getTopicById(topicId) {
      this.loading = true;
      request("get", "/topic/v2/getByTopicId?topicId=" + topicId).then(res => {
        this.loading = false;
        if (res && res.code == 200) {
          this.topic = res.object;
          this.initTopic()
        }

      })

    },
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 500) {
        this.answer.isShowAll = false;
        return value.slice(0, 500) + "...";

      }
      return value;
    },
    toColletion() {
      request("post", "/collect/add", {answerId: this.answer.id}).then(res => {
        if (res && res.code == 200) {

          if (res.object == -1) {
            this.collect.length--;
            this.collect.isFromUser = false;
          }
          if (res.object == 1) {
            this.collect.length++;
            this.collect.isFromUser = true;
          }
        }

      })
    },
    showAll() {
      if (this.text.length > 503) {
        this.text = this.text.slice(0, 500) + "..."
      } else {
        this.text = this.fullText
      }
      this.answer.isShowAll = !this.answer.isShowAll
    },
    func(time) {
      return func(time)
    },
  }

}
</script>

<style scoped>
.topic_title {
  margin-left: 10px;
  color: black;
  font-weight: 700;
}

.topic_title:hover {
  color: #175199;
}

.el-dropdown-link {
  padding: 5px;
}

.el-dropdown-link:hover {
  background-color: #f6f6f6;
}

.el-dropdown-link:active {
  background-color: #f6f6f6;
}
</style>