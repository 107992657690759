<template>
  <div class="col"
       style="padding: 0px; height: 100vh; overflow: hidden; position: fixed; top: 0; left: 0; width: 100vw; z-index: 200000">
    <div style="width: 100%; height: 100%; margin: 0px auto; background-color: rgb(245, 245, 245);
                    border-radius: 5px; text-align: center">
      <div style="height: 5vh; justify-content: center;  margin-left: 10px;width: 100vw;" class="col">
        <div class="row">

          <div class="col" style="width: 5vw; justify-content: center;" @click="goBack()"><img class="" src="back.png"
                                                                                               style="width: 15px;height: 15px;cursor: pointer;">
          </div>
          <UserNameV2 class="row" style="justify-content: center; font-size: 18px; width: 90vw; text-align: center;"
                      v-bind:user-id="toUserId"></UserNameV2>
          <div style="width: 5vw;"></div>
        </div>
      </div>

      <div style="height: 88vh; " id="chatShow">
        <ChatList style="width: 100vw; height: 100%; "
                  v-bind:to-user-id="toUserId" v-bind:chat-list="chatList"></ChatList>
      </div>

      <div @click="updateUnreadNums" class="row"
           style="position: fixed; bottom: 0; width: 100vw;  background-color: rgb(245,245,245);border-top: 1px solid #ccc; ">
        <div class="row" style="padding: 5px 5px 5px 5px; margin-left: 5px; margin-right: 5px; flex: 1;">

          <textarea
              placeholder=""
              :disabled="disable"
              v-model="text"
              style="height: 25px;
                        border-radius: 2px;
                        background-color: white;
                        flex: 1;

                        line-height: 25px;
                        font-size: 18px;
                        resize:none;
                        font-family: inherit;
                        border: none;
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;
                        border-bottom: 0px;
                        outline: none">

          </textarea>

          <div style="margin-left: 7px;" v-if="text.length > 0">
            <el-button type="info" style="color:#f7fefe; height: 30px; line-height: 0px;" plain @click="send">
              发送
            </el-button>
          </div>
          <div class="row" v-else style="height: 25px; height: 25px;margin-left: 5px;justify-content: center;">
            <el-upload
                :auto-upload="true"
                :data={toId:toUserId}
                :headers="headers"
                action="/api/chat/sendImage/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img style="height: 25px; width: 25px; flex-direction: column; " src="add.png"/>
            </el-upload>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import Utils from '@/util/utils.js';
import {request} from "@/util/api";
import ChatList from "@/components/im/ChatList";
import UserNameV2 from "@/components/UserInfo/userNameV2";

export default {
  name: "WX-Chat-Window-Phone",
  components: {UserNameV2, ChatList},
  props: ['toUserId'],
  data() {
    return {
      user: {},
      userId: null,
      isCollapse: false,
      users: [],
      chatUser: '',
      chatUserId: null,
      text: "",
      content: '',
      token: '',
      disable: false,
      chatList: [],
      isMobile: false,
    }
  },
  watch: {

    toUserId: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
        this.chatList = await this.getHistory()
      }
    }
  },
  async created() {
    if (/mobile/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    let _this = this
    Utils.$on('WebSocket_AddMessageFromWeb_ChangeChatList', function (data) {
      if (data) {
        Utils.$emit("chatList_AddChat", data)
        _this.chatList = [data, ..._this.chatList]
      }
    })
    Utils.$on('WebSocket_AddMessageFromLocal_ChangeChatList', function (data) {
      if (data && data.toId == _this.toUserId) {
        // _this.chatList.push(data)
        _this.chatList = [data, ..._this.chatList]
        Utils.$emit("chatList_AddChat", data)
      }
    })
    this.init()
    this.chatList = await this.getHistory()

  },
  async mounted() {


  },
  methods: {
    updateUnreadNums() {
      let _this = this;
      Utils.$emit('AppVue_ChangeUnreadNums', _this.userId, 0, 0);
    },
    goBack() {
      this.$parent.isShowChatWindow = false;
      this.$parent.chatBox = false;
    },
    async getHistory() {
      // let _this = this;
      // return  await Utils.$on("AppVue_GetHistoryFromWeb", _this.user.id, _this.toUserId)
      let _this = this
      let min = Math.min(this.userId, this.toUserId)
      let max = Math.max(this.userId, this.toUserId)
      let list = []

      Utils.$emit("AppVue_GetChatListFromLocalStorage", _this.userId, _this.toUserId, res => {
        list = res;
      })
      let startTime = list.length > 0 ? list[list.length - 1] : '1990-02-16 14:36:57'

      let res = await this.getChatsByStartTime(startTime)
      if (res) {
        list = list.concat(res)
      }
      Utils.$emit("AppVue_UpdateChatList", min, max, list)
      return list.reverse()
    },
    async getAllFromSQL() {
      return new Promise(resolve => {
        request("get", "/chat/getAll/" + this.toUserId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            return null
          }
        })
      })
    },
    async getChatsByStartTime(startTime) {
      return new Promise(resolve => {
        request("get", "/chat/getAllByStartTime/" + this.toUserId + "?startTime=" + startTime).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            return null
          }
        })
      })
    },
    genChatKey(min, max) {
      return min + ":" + max + ":chats"
    },
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        this.text = this.text + '\n';
      }
    },

    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + userBasic.id, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }
    },

    send() {
      if (!this.chatUserId) {
        this.$message({type: 'warning', message: "请选择聊天对象"})
        return;
      }
      if (!this.text || this.text.replace(/ /g, "").length === 0) {
        this.$message({type: 'warning', message: "请输入有效内容"})
      } else {
        if (this.text.length > 2000) {
          this.$message.warning("文字长度不能超过2000")
        }
        if (typeof (WebSocket) == "undefined") {
        } else {
          // 组装待发送的消息 json
          // {"from": "zhang", "to": "admin", "text": "聊天文本"}
          let message = {
            fromId: this.userId,
            toId: this.chatUserId,
            text: this.text,
            gmtCreate: this.getNowDateString(),
            type: 0
          }
          Utils.$emit('WebSocket_SendMessage', message)
          // 构建消息内容，本人消息
          this.text = '';
        }
      }
    },
    insertHistroyToCache(fromId, toId, text) {
      let datetime = this.getNowDateString()
      let min = Math.min(fromId, toId)
      let max = Math.max(fromId, toId)
      let list = []
      let listStr = window.localStorage.getItem(this.genChatKey(min, max))
      if (listStr) {
        list = JSON.parse(listStr)
      }
      list.push({fromId: fromId, toId: toId, text: text, gmtCreate: datetime, gmtUpdate: datetime})
      window.localStorage.setItem(this.genChatKey(min, max), JSON.stringify(list))
    },
    getNowDateString() {
      let res = ""
      let date = new Date()
      let time = date.toTimeString()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      res += year + '-';
      if (month < 10) {
        res += '0'
      }
      res += month + "-"
      if (day < 10) {
        res += '0'
      }
      res += day + " "
      res += time.substring(0, 8)
      return res

    },
    init() {

      this.initChatUser()
      this.initThisUser()
    },
    initThisUser() {
      let userId = window.localStorage.getItem("userId")
      if (!userId) {
        return;
      }
      this.userId = userId;
      let user = window.sessionStorage.getItem("user_basic:" + userId)
      if (!user) {
        return;
      }
      this.user = JSON.parse(user);
    },
    initChatUser() {
      if (this.toUserId) {
        let user = window.sessionStorage.getItem("user_basic:" + this.toUserId)
        if (user) {
          this.chatUser = JSON.parse(user);
          this.chatUserId = this.chatUser.id;
        }
      }
    }
  }
  ,

}
</script>
<style lang="scss" scoped>
.el-button--info.is-plain:active, .el-button--info.is-plain:active {
  background: #07ae56;
  border-color: rgb(210, 210, 210);
  color: #f7fefe;
}

.el-button--info.is-plain {
  background-color: #05c160;
  border-style: none;
  color: #f7fefe;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
