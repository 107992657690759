<template>
  <div>
    <div v-if="chat.fromId != toUserId || chat.fromId==chat.toId"
         style="text-align: right; width: 100%; margin-top: 0px; ">


      <div class="row" style="justify-content: flex-end; overflow-wrap: break-word;">
        <div v-if="type == 1">

          <TopicBox style=" width: 60vw; max-width: 200px; height: 120px; margin-left: 20px; margin-right: 10px;"
                    v-bind:topic="topic"
                    v-bind:answerNums="answerNums"
                    v-bind:focus="focus"></TopicBox>

        </div>
        <div v-else-if="type == 2">
          <AnswerBox style="width: 200px; height: 120px; margin-right: 10px;" v-bind:topic="topic"
                     v-bind:answer="answer"
          ></AnswerBox>
        </div>
        <div v-else-if="type == 3">
          <el-image style="border-radius: 10px; margin-left: 10px;  margin-right: 10px; max-width: 150px;" lazy
                    :src="url" :preview-src-list="urlList">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div v-else-if="type == 0" class="row">

          <div class="test">
            <div class="tip right" style="">
              {{ chat.text }}
            </div>
            <div class="triangle-right"></div>
          </div>
        </div>

        <UserAvatarV1 style="margin-right: 2px; " v-bind:size="40"
                      v-bind:user-id="chat.fromId"></UserAvatarV1>
      </div>


    </div>
    <div class="row" v-else
         style="overflow-wrap: break-word; min-height: 20px; line-height: 20px;">

      <div class="row">

        <UserAvatarV1 v-bind:user-id="chat.fromId"></UserAvatarV1>

        <div style="margin-left: 0px;">

          <div v-if="type == 1">
            <TopicBox style="width: 200px; height: 120px; margin-left: 10px;" v-bind:topic="topic"
                      v-bind:answerNums="answerNums"
                      v-bind:focus="focus"></TopicBox>
          </div>
          <div v-else-if="type == 2">
            <AnswerBox style="width: 200px; height: 120px; margin-left: 10px;" v-bind:topic="topic"
                       v-bind:answer="answer"
            ></AnswerBox>
          </div>
          <div v-else-if="type == 3">
            <el-image style="border-radius: 10px; margin-left: 10px;  margin-right: 10px; max-width: 150px;" lazy
                      :src="url" :preview-src-list="urlList">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>

          <div v-else-if="type == 0" class="test">
            <div class="triangle-left"></div>
            <div class="tip left" style=" ">
              {{ chat.text }}
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {request} from "@/util/api";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";
import TopicBox from "@/components/chat/TopicBox";
import AnswerBox from "@/components/chat/AnswerBox";
import axios from "axios";

export default {
  name: "ChatBox",
  components: {AnswerBox, TopicBox, UserAvatarV1},
  props: ['chat', 'toUserId'],
  watch: {
    chat: function (newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.chat = newVal
        this.type = 2
        this.init();
      }
    },
    // toUserId: function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.toUserId = newVal
    //     this.type = 2
    //     this.init();
    //   }
    // },
  },
  data() {
    return {
      url: "background/default.jpg",
      urlList: [],
      topic: null,
      topicId: 0,
      answerNums: 0,
      focus: {},
      type: 0,
      topicPre: 'https://chujuei.top/topic/',
      answerPre: 'https://chujuei.top/answer/',
      answerId: 0,
      answer: {},
    }
  },
  async mounted() {

    this.init();

  },
  methods: {
    async init() {

      if (this.tryImage()) {
        this.url = this.chat.text;
        this.urlList = [this.url]
        this.type = 3;
      } else if (this.tryTopic()) {
        this.topicId = parseInt(this.chat.text.slice(this.topicPre.length))
        this.topic = await this.getTopic(this.topicId)
        if (this.topic != null) {
          this.focus = await this.getFocusByTopicId(this.topicId)
          this.answerNums = await this.getAnswerNumsByTopicId(this.topicId)
          this.type = 1;
          return
        } else {
          this.type = 0;
        }
      } else if (this.tryAnswer()) {
        this.answerId = parseInt(this.chat.text.slice(this.answerPre.length))
        this.answer = await this.getAnswer(this.answerId)
        this.topic = await this.getTopic(this.answer.topicId)

        this.type = 2;
      } else {
        this.type = 0;
      }
    },
    tryImage() {
      return this.chat.type == 3;
    },
    tryAnswer() {
      if (this.chat.text.length >= this.answerPre.length + 1 && this.chat.text.length <= 6 + this.answerPre.length) {
        let numStr = this.chat.text.slice(this.answerPre.length)

        if (this.answerPre !== this.chat.text.slice(0, this.answerPre.length)) {
          return false;
        }

        for (let i = 0; i < numStr.length; i++) {
          if (numStr[i] < '0' || numStr[i] > '9') {
            return false
          }
        }
        return true;
      } else {
        return false;
      }
    },
    tryTopic() {
      if (this.chat.text.length >= this.topicPre.length + 1 && this.chat.text.length <= 6 + this.topicPre.length) {
        let numStr = this.chat.text.slice(this.topicPre.length)
        if (this.topicPre !== this.chat.text.slice(0, this.topicPre.length)) {
          return false;
        }
        for (let i = 0; i < numStr.length; i++) {
          if (numStr[i] < '0' || numStr[i] > '9') {
            return false
          }
        }
        return true;
      } else {
        return false;
      }
    },

    async getTopic(topicId) {
      if (typeof topicId === undefined || !topicId) {
        return null;
      }
      return new Promise(function (resolve) {

        let topicStr = window.localStorage.getItem("topic:" + topicId)
        if (topicStr) {
          resolve(JSON.parse(topicStr))
        } else {
          request("get", "/topicGetById?topicId=" + topicId).then(res => {
            if (res && res.code == 200) {
              window.localStorage.setItem("topic:" + topicId, JSON.stringify(res.object))
              resolve(res.object);
            }
          })
        }
      })
    },

    async getAnswer(answerId) {
      if (typeof answerId === undefined || !answerId) {
        return null;
      }
      return new Promise(function (resolve) {
        request("get", "/answer/getAnswerById?answerId=" + answerId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            resolve(null)
          }
        })
      })
    },
    async getFocusByTopicId(topicId) {
      return new Promise(function (resolve) {
        request("get", "/focus/getByTopicId?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object);
          }
        })
      })
    },
    async getAnswerNumsByTopicId(topicId) {
      return new Promise(function (resolve) {

        request("get", "/topic/getAnswerNums?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          }
        })
      })
    },
  }
}
</script>


<style scoped lang="scss">
.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.tip {
  color: black;
  overflow-wrap: break-word;
  max-width: 300px;
  border-radius: 6px;
  /*font-family: "Microsoft JhengHei";*/
  padding: 10px;
  width: fit-content;
  word-break: break-word;
  display: inline-block !important;
  display: inline;
  min-height: 20px;
  line-height: 20px;
}

/*.left::before {*/
/*  content: '1';*/
/*  display: inline-block;*/
/*  width: 0;*/
/*  height: 0;*/
/*  border: 8px solid transparent;*/
/*  border-left: 8px solid #AFABAB;*/
/*  position: relative;*/
/*  top: 2px;*/
/*  left: 10px;*/
/*}*/

.right {
  text-align: left;
  min-height: 20px;
  line-height: 20px;
  background-color: rgb(149, 236, 105);

}

.left {
  min-height: 20px;
  line-height: 20px;
  text-align: left;
  background-color: white;
}

/*.left::before {*/
/*  width: 0;*/
/*  height: 0;*/

/*  line-height: 40px;*/
/*  margin: 15px 0;*/
/*  border-style: solid;*/
/*  border-color: transparent;*/
/*  border-width: 7px 7px 7px 7px;*/
/*  border-right-color: white;*/

/*}*/
.test {
  display: flex;
  background-color: transparent;

}

.test:hover {
  .left {
    background-color: #ebebeb;
  }

  .triangle-left {
    border-style: solid;
    border-color: transparent;
    border-width: 7px 7px 7px 7px;
    border-right-color: #ebebeb;
  }
}

.test:hover {
  .right {
    background-color: #89d961;
  }

  .triangle-right {
    border-style: solid;
    border-color: transparent;
    border-width: 7px 7px 7px 7px;
    border-left-color: #89d961;
  }
}

.triangle-left {
  width: 0;
  height: 0;

  line-height: 40px;
  margin: 15px 0;
  border-style: solid;
  border-color: transparent;
  border-width: 7px 7px 7px 7px;
  border-right-color: white;

}

//.right:hover {
//  background-color: #89d961;
//}
//
//.left:hover {
//  background-color: #ebebeb;
//}


.triangle-right {
  width: 0;
  height: 0;
  line-height: 40px;
  margin: 15px 0;
  border-style: solid;
  border-color: transparent;
  border-width: 7px 7px 7px 7px;
  border-left-color: rgb(149, 236, 105);;
  border-radius: 0px;
}


.topnav_box::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  /*:overflow-y:;*/
  background-color: rgb(245, 245, 245);

}

.topnav_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  border-radius: 10px;
  background-color: rgb(245, 245, 245);

}


.topnav_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  background-color: #d2d2d2;
}
</style>