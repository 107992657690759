<template>
  <div style="text-align: left;">
    <el-backtop right="10" bottom="10" target=""></el-backtop>
    <div style="width: 100vw; max-width: 1000px; margin: 0 auto; ">
      <div style="overflow-y: auto; overflow-x: hidden; min-height: 1000px;">
        <div style="width: 100vw; max-width: 1000px;margin: 0px auto;background-color: white">
          <div>
            <topic-single style="padding: 10px;" v-bind:topic-id="$route.query.topicId"></topic-single>
            <div v-show="isAnswer" class="mainEditor ">
              <div style="text-align: right">
                <el-button :loading="!isCanAnswer" @click="addAnswer" type="primary" plain>回复<i
                    class="el-icon-upload el-icon--right"></i></el-button>
              </div>
              <div style="text-align: left;">
                <div id="toolbar-container" class="toolbar"></div>
                <div id="text-container" style="text-align: left;" class="text"></div>
              </div>
              <div style="text-align: right">
                <i @click="deleteText" class="el-icon-delete"></i>
              </div>
            </div>

            <div class="answer">
              <div style="text-align: left">
                <el-tag>{{ answerIds.length }}个回答</el-tag>
              </div>
              <el-divider></el-divider>

              <div v-for="(answerId) in answerIds.slice((currentPage-1)*pageSize,pageSize*currentPage)">
                  <answer-single v-bind:answer-id="answerId"></answer-single>
                <el-divider></el-divider>
              </div>
              <div class="block" style="text-align: left; width: auto;">
                <el-pagination
                    :hide-on-single-page="false"
                    @size-change="sizeChange"
                    @current-change="currentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="answerIds.length">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor';
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import {request} from "../util/api";
import TopicSingle from "@/components/single/TopicSingle";
import Utils from "@/util/utils";
import AnswerSingle from "@/components/single/AnswerSingle";

export default {
  name: "Answer",
  components: {AnswerSingle, TopicSingle},
  data() {
    return {
      userId: 0,
      editor: null,
      answer:{},
      topic: {},
      topicId: 0,
      isCanAnswer: true,
      isAnswer: true,
      answerId:0,




      messageFormRules: {
        title: [{max: 20, message: '标题最大长度为10位', trigger: 'blur'}],
        context: [{required: true, message: '请输入内容', trigger: 'blur'}, {
          min: 2,
          message: '内容最小长度为2位',
          trigger: 'blur'
        }, {max: 100, message: '内容最大长度为100位', trigger: 'blur'}],

      },
      answerIds: [],
      //分页
      pageSize: 10,
      currentPage: 1,
      canSendMessage: true,

    };
  },
  methods: {

    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
    getAnswerBytopicId() {
      request("get", "/answer/getAnswerIdsByTopicId?topicId=" + this.topicId, {topicId: this.topic.id}).then(res => {
        if (res && res.code === 200) {
          let ids = res.object.reverse();
          if(this.$route.query.answerId) {
            let answerId =  parseInt(this.$route.query.answerId)
            for(let i = 0; i < ids.length; i++) {
              if (ids[i] === answerId) {
                let t = ids[i];
                ids[i] = ids[0];
                ids[0] = t;
                break;
              }
            }
          }

          this.answerIds = ids
        }
      })


    },
    addAnswer() {
      this.answer.context = this.getHtml();
      this.answer.topicId = this.topicId;
      // console.log(this.topic, this.topicId, 111)

      if (this.answer.context.length == 0) {
        this.$message('回复内容不能为空！');
        return;
      }
      this.isCanAnswer = false;
      request("post", "/answer/add", this.answer).then(res => {
        if (res.code && res.code == 200) {
          this.$message.success(res.message);
          this.isCanAnswer = true
          this.editor.txt.clear();
        }
      })
    },
    getHtml() {
      return this.editor.txt.html();
    },
    deleteText() {
      this.editor.txt.clear();
    },
    initEditor() {
      // 创建编辑器
      this.editor = new E('#toolbar-container', '#text-container') // 传入两个元素
      // this.editor.height=200;
      this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        resultFiles.forEach((item) => {
          let fd = new FormData();
          fd.append('imgFile', item);
          request("post", "/uploadImg", fd).then(res => {
            let imgUrl = res.object.fileUrl;
            //插入图片到前端
            insertImgFn("/" + imgUrl)
          })
        })

      }
      this.editor.config.uploadImgMaxLength = 2;
      this.editor.config.pasteIgnoreImg = true
      this.editor.config.menus = [
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        'quote',  // 引用
        'image',  // 插入图片
        'video',  // 插入视频
        'code',  // 插入代码
        'undo',  // 撤销
        'redo'  // 重复
      ]
      this.editor.config.languageType = [
        'Bash',
        'C',
        'C#',
        'C++',
        'CSS',
        'Java',
        'JavaScript',
        'JSON',
        'TypeScript',
        'Plain text',
        'Html',
        'XML',
        'SQL',
        'Go',
        'Kotlin',
        'Lua',
        'Markdown',
        'PHP',
        'Python',
        'Shell Session',
        'Ruby',
      ]
      this.editor.config.languageTab = '    ';
      this.editor.config.uploadFileName = 'imgFile'
      this.editor.config.height = 500
      this.editor.highlight = hljs
      this.editor.zIndex.baseZIndex = 0 //方法
      this.editor.create()
      document.getElementById("text-container").style.textAlign = "left"

    }
  },

  created() {
    if (true || window.localStorage.getItem('tokenStr')) {
      this.userId = window.localStorage.getItem("userId")
      if (!this.userId || typeof this.userId === typeof undefined) {
        this.userId = 0
      }

      if (this.$route.query.isAnswer === "true") {
        this.isAnswer = true
      } else {
        this.isAnswer = false;
      }
      this.topicId = this.$route.query.topicId


      if (this.isAnswer === true) {
        this.timeBtn = setTimeout(() => {
          this.initEditor()
        }, 1000)
      }
      this.getAnswerBytopicId();

      let _this = this
      Utils.$on('Answers_ChangeIsAnswer', function (topicId) {
        if (_this.$route.query.topicId == topicId) {
          _this.isAnswer = ! _this.isAnswer
          // if (_this.isAnswer) {
          //   _this.$route.query.isAnswer = "true"
          // } else {
          //   _this.$route.query.isAnswer = "false"
          // }
          if (_this.isAnswer) {

            _this.timeBtn = setTimeout(() => {
              _this.initEditor()
            }, 1000)
          } else {
            clearInterval(_this.timeBtn)
            _this.editor.destroy()
            _this.editor = null
          }
        }
      })

    } else {
      this.$router.replace("login")
      this.$message("请先登录！")

    }

  },
  beforeDestroy() {
    // 销毁编辑器
    clearInterval(this.timeBtn)
    this.editor.destroy()
    this.editor = null
  }
}

</script>

<style scoped>
.answer {
  margin-top: 20px;
  background-color: white;
  padding: 30px;
}

#text-container {
  min-height: 500px;
}

.mainEditor {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  text-align: left;
  /*border-radius: 10px;*/
}

.maincontex {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);*/
}
.good {
  width: 12px;
  height: 12px;
}
.text {
  text-align: left;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
