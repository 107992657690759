<template>
  <div>
    <!--    <el-button @click="resetDateFilter">清除日期过滤器</el-button>-->
    <!--    <el-button @click="clearFilter">清除所有过滤器</el-button>-->
    <div class="row">
      <el-input placeholder="输入id/email/昵称查询" @keyup.enter.native="toSearchUserWithRole" v-model="keyWord"
                style="width: 300px;"></el-input>
      <el-button @click="toSearchUserWithRole" style="margin-left: 10px;" type="primary">搜索</el-button>
      <el-button v-if="userRole == 'Admin'" @click="updateCode" style="margin-left: 10px;" type="primary">升级项目</el-button>
<!--      <el-button v-if="userRole == 'Admin'" @click="updateBack" style="margin-left: 10px;" type="primary">仅升级前端项目123</el-button>-->
    </div>

    <div style="padding: 10px 0 10px ;" class="row">

      <el-radio-group style="line-height: 35px;" class="row" @input="changeSelectRole" size="medium" v-model="role">
        <div v-for="item in roleText">
          <el-radio-button :label="item.text"></el-radio-button>
        </div>
      </el-radio-group>
      <el-button @click="cancelRole" style="color: #8c939d; padding: 0px 10px 0px;" type="text"><i
          class="el-icon-error"></i></el-button>
    </div>
    <el-table
        ref="filterTable"
        :data="userList.slice((currentPage-1)*pageSize,pageSize*currentPage)"
        style="width: 100vw; max-width: 100%;">
      <el-table-column
          prop="id"
          label="用户"
          width=""
      >
        <template slot-scope="scope">
          <UserAvatar v-bind:user-id="scope.row.id"></UserAvatar>
          <!--          <user-basic v-bind:userId="scope.row.id"></user-basic>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width=""
      >
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱"
      >

      </el-table-column>
      <el-table-column
          prop="role"
          label="角色"
          width="">
        <template slot-scope="scope">
          <el-dropdown @command="changeRole">
            <span class="el-dropdown-link">
              {{ roleENToZH[scope.row.role] }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <div v-for="role in AllRole">
                <div v-if="compare(userRole, role) && compare(userRole, scope.row.role)">
                  <div v-if="scope.row.role == role">
                    <el-dropdown-item icon="el-icon-check" disabled>{{ roleENToZH[role] }}
                    </el-dropdown-item>
                  </div>
                  <div v-else>
                    <el-dropdown-item :command="({role:role,userId:scope.row.id,index:scope.$index})">
                      {{ roleENToZH[role] }}
                    </el-dropdown-item>
                  </div>
                </div>
                <div v-else>
                  <div v-if="scope.row.role == role">
                    <el-dropdown-item icon="el-icon-check" disabled>{{ roleENToZH[role] }}</el-dropdown-item>
                  </div>
                  <div v-else>
                    <el-dropdown-item disabled>{{ roleENToZH[role] }}</el-dropdown-item>
                  </div>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :hide-on-single-page="false"
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[5,10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="userList.length">
      >
    </el-pagination>
  </div>
</template>

<script>
import {request} from "@/util/api";
import UserBasic from "@/components/UserInfo/UserBasic";
import UserAvatar from "@/components/UserInfo/UserAvatar";

export default {
  components: {UserAvatar},
  props: ['roleId'],
  name: "RoleManageTable",
  data() {
    return {
      keyWord: "",
      startUserId: 0,
      userCount: 500,
      userRoleList: [],
      role: {},
      AllRole: ['Admin', 'Checker', 'User', 'BadUser',],
      roleRank: {'Admin': 99, 'Checker': 98, 'User': 97, 'BadUser': 0},
      roleENToZH: {'Admin': '管理员', 'Checker': '审核员', 'User': '普通用户', 'BadUser': '违规用户'},
      roleText: [{text: '管理员', value: 'Admin'}, {text: '审核员', value: 'Checker'}, {
        text: '普通用户',
        value: 'User'
      }, {text: '违规用户', value: 'BadUser'},],
      userRole: '',
      userList: [],
      allUserList: [],
      //分页
      pageSize: 10,
      currentPage: 1,
      canSendMessage: true,
    }
  },
  mounted() {
    // this.getUserListByRole()
    this.getUsers(0);
    this.getUserCount()
    this.getUserRole()
  },
  methods: {
    updateCode() {
      request("post", "/admin/updateCode")
    },
    updateBack() {
      request("post", "/admin/updateBack")
    },
    cancelRole() {
      this.role = {}
      this.userList = this.copy(this.allUserList)
    },
    copy(list) {
      let res = []
      for (let i = 0; i < list.length; i++) {
        res.push(list[i])
      }
      return res
    },
    changeSelectRole(e) {
      let res = []
      for (let i = 0; i < this.allUserList.length; i++) {
        if (this.roleENToZH[this.allUserList[i].role] == e) {
          res.push(this.allUserList[i])
        }
      }

      this.userList = res
    },
    toSearchUserWithRole() {
      if (this.keyWord && this.keyWord.length > 0) {
        request("get", "/checker/getUserListByKey?key=" + this.keyWord).then(res => {
          if (res && res.code == 200) {
            this.userList = res.object.userList
            this.allUserList = this.copy(res.object.userList)
          }
        })
      } else {
        this.getUsers(0)
      }
    },
    getUserRole() {
      let role = window.sessionStorage.getItem("userRole")
      if (role == null) {
        request("get", "/checker/getUserRole").then(res => {
          if (res && res.code == 200) {
            role = res.object
            window.sessionStorage.setItem("userRole", role)
          }
        })
      }
      this.userRole = role
      return role == "Admin" || role == "Checker"
    },
    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
    changeRole(e) {
      let userId = e.userId
      let targetRole = e.role
      request("post", "/checker/updateUserRole", {userId: userId, targetRole: targetRole}).then(res => {
        if (res && res.code == 200) {
          for (let i = 0; i < this.userList.length; i++) {
            if (this.userList[i].userId == userId) {
              this.userList[i].role = targetRole
              this.$forceUpdate()
              break
            }
          }
          for (let i = 0; i < this.allUserList.length; i++) {
            if (this.allUserList[i].userId == userId) {
              this.allUserList[i].role = targetRole
              this.$forceUpdate()
              break
            }
          }
          this.$message.success("变更成功！");
        }
      })
    },
    getUsers(startUserId) {
      request("get", "/checker/getUserList?startUserId=" + startUserId).then(res => {
        if (res && res.code == 200) {
          this.userList = res.object.userList
          this.allUserList = this.copy(res.object.userList)
        }
      })
    },
    getUserCount() {
      request("get", "/checker/getUserCount").then(res => {
        if (res && res.code == 200) {
          this.userCount = res.object.userCount;
        }
      })
    },
    compare(UserRole, role) {
      return this.roleRank[UserRole] > this.roleRank[role]
    },
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('date');
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    filterTag(value, row) {
      return row.role === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    }
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>