<template>
  <div style="flex-direction: row;display:flex;">
    <!--    <el-button style="padding: 0px;" type="text" @click="showPeopleInfo(user.id)">-->
    <el-popover
        placement="top-start"
        title=""
        width="auto"
        trigger="hover"
        content=""
    >
      <UserIntroduction v-bind:parentUser="user"></UserIntroduction>
      <el-button slot="reference" style="padding: 0px;" type="text">
        <el-avatar :size="45">
          <img :src="user.avatar"/>
        </el-avatar>
      </el-button>
    </el-popover>
    <div style="display: flex;flex-direction: column;margin-left: 10px">
      <div style="height: 50%;line-height: 22.5px" class="row">
        <div>
          {{ user.nickname }}
        </div>
        <el-tooltip v-if="user.authIconUrl &&user.authIconUrl.length > 0" :content="user.authName &&user.authName" placement="top-start"
                    style="cursor:pointer">
          <img style="width: 15px;height: 15px;"
               :src="user.authIconUrl"/>
        </el-tooltip>
      </div>
      <div style="font-size: 10px;height: 50%;line-height: 22.5px;color: #909399">
        {{ user.sign }}
      </div>
    </div>

  </div>
</template>

<script>
import {request} from "@/util/api";
import UserIntroduction from "@/components/UserInfo/UserIntroduction";
import LoadingImage from "@/components/Icon/LoadingImage";
import ErrorImage from "@/components/Icon/ErrorImage";

export default {
  name: "UserBasic",
  props: ['userId'],
  components: {UserIntroduction},
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    if (!this.userId || typeof (this.userId) === undefined) {
      return
    }
    this.getUserBasic()
  },
  methods: {
    showPeopleInfo(id) {
      window.sessionStorage.setItem("peopleUserId", id);
      // window.open("/#/people");
      this.$router.push("/people")
    },
    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + userBasic.id, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }
    },
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (typeof newVal === typeof undefined || newVal === undefined) {
        return
      }
      if (newVal !== oldVal) {
        this.userId = newVal
        this.getUserBasic()
      }
    }
  }
}
</script>

<style scoped>

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 4px 4px;
  background: #ebebeb;
}


.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>