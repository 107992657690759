<template>
  <div style="width: 100vw; overflow-x: hidden; max-width: 1000px;margin: 0px auto;">
    <div style="">

      <div style="width: 100vw;overflow-x: hidden; padding: 20px; max-width: 1000px;margin: 0px auto;background-color: white;min-height: 100vh">
        <div style="">
          <el-tabs v-model="activeName">
            <el-tab-pane label="提交审核" name="first">
              <submit-verify></submit-verify>
            </el-tab-pane>
            <el-tab-pane lazy label="我的审核" name="second">
              <MyVerify></MyVerify>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitVerify from "@/components/verify/SubmitVerify";
import MyVerify from "@/components/verify/MyVerify";

export default {
  name: "Verify",
  components: {SubmitVerify, MyVerify},
  data() {
    return {
      activeName: "first"
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>