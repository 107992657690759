<template>
  <div
      style="display: flex; flex-direction: column; justify-content: center;cursor: pointer;
    ;
">
    <div style="display: flex; flex-direction: row; justify-content: center; height: 100%;">
      <div v-if="flag" style=" height: 100%; color: #056de8; border-bottom: 2px solid #056de8;">
        <div class="text_name" style="height: 100%; font-weight: 600; line-height: 100%;display: flex; flex-direction: column; justify-content: center; ">
          {{ text }}
        </div>

      </div>
      <div v-else class="not_choice">
        <div class="text_name" style="height: 100%;line-height: 100%;display: flex; flex-direction: column; justify-content: center; ">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/util/utils";

export default {
  props: ['text', 'routers'],
  name: "MenuSingleText",
  data() {
    return {
      flag: false,
    }
  },
  mounted() {
    let _this = this;
    Utils.$on("change_route", function () {
      _this.init()
    })
    this.init()

  },
  methods: {
    init() {
      for (let i = 0; i < this.routers.length; i++) {

        if (this.$route.name && this.$route.name == this.routers[i]) {
          this.flag = true;
          return
        }
      }
      this.flag = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.not_choice:hover {
  color: #8590a6;
}

//.text_name {
//  height: calc(100% - 4px);
//}
</style>