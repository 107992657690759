<template>
  <div style="min-height: 1000px;background-color: #F6F6F6">
    <div style="width: 100vw; max-width: 1000px;margin: 0px auto;background-color: #F6F6F6; ">
      <div style="border-radius: 0px;height: 1000px; margin: 0 auto; padding:5px;" class=" maincontext">

        <el-form ref="form" :model="form" label-width="80px" :style="{width: '90%', margin: '0 auto'}">
          <el-form-item style="" label="学校">
            <el-input style="" v-model="form.first"></el-input>
          </el-form-item>
          <el-form-item label="专业/班级">
            <el-input style="" v-model="form.second"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input style="" type="textarea" v-model="form.notes"></el-input>
          </el-form-item>
          <el-form-item label="附件">
            <div style="max-width: 480px; flex-direction: row; display: flex; flex-wrap: wrap;">

              <div v-for="(url, i) in form.imgs">
                <div class="col" style="height: 180px;">

                  <el-image
                      style="width: 150px; height: 150px;border-radius: 10px; margin-right: 10px;"
                      :preview-src-list="form.imgs"
                      class="el-upload-list__item-thumbnail"
                      :src="url" alt=""
                      fit="cover"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div style="width: 150px; height: 5px; text-align: center;">
                    <el-button @click="deleteImg(i)" style="width: 5px;height: 5px;" type="text">

                      <i class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </div>

              </div>
              <div style="width: 150px;height: 150px; line-height: 150px;">
                <el-upload
                    v-if="form.imgs.length < 9"
                    :auto-upload="true"
                    :data={}
                    :headers="headers"
                    action="/api/verify/uploadImg"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                  <img
                      style="height: 50px;width: 50px;"
                      class="el-upload-list__item-thumbnail"
                      src="upload.png" alt=""

                  >
                </el-upload>
              </div>
            </div>

          </el-form-item>
          <div class="row" style="justify-content: center;">
            <el-button type="primary" plain @click="submitVerifyForm()">提交</el-button>
          </div>
        </el-form>


      </div>
    </div>


  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "SubmitVerify",
  data() {
    return {
      form: {
        first: '',
        second: '',
        notes: '',
        imgs: [],
      },
      headers: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      isPhone:false,
    }
  },
  mounted() {
    if (/mobile/i.test(navigator.userAgent)) {
      this.isPhone = true;
    }
    let token = window.localStorage.getItem("tokenStr")
    this.headers = {Authorization: token}
  },
  methods: {
    submitVerifyForm() {
      request("post", "/verify/submit",
          this.form
      ).then(res => {
        if (res && res.code && res.code == 200) {
          this.form = {
            first: '',
            second: '',
            notes: '',
            imgs: [],
          }
        }
      })
    },
    deleteImg(index) {
      this.form.imgs.splice(index, 1)
    },
    beforeAvatarUpload(file) {
      if (this.form.imgs.length >= 9) {
        this.$message.warning("最多上传9张图片")
        return
      }
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }

      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file, fileList) {
      //上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.form.imgs.push(file.response.object.fileUrl)
        this.dialogVisible = true
      })
    },
  }
}
</script>

<style scoped>

.maincontext {
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
}

table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}

table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}

pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 4px;
  background: #ebebeb;
}

.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
}

.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>