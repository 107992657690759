

let func = function(timeStr) {

  let p = Date.parse(timeStr)
  let pd = new Date(p)
  let nd = new Date()
  let n = nd.valueOf()
  if (nd.getFullYear() !== pd.getFullYear()) {
    return timeStr;
  }

  if ((n - p) / 1000 < 60) {
    return '刚刚'
  }

  if ((n - p) / 1000 / 60 < 60) {
    return Math.ceil((n - p) / 1000 / 60) + '分钟前'
  }



  let pYear = pd.getFullYear()
  let nYear = nd.getFullYear()
  let ptowMonthDay = ((pYear % 4 === 0) && (pYear % 100 !== 0) || pYear % 400 === 0) ? 29 : 30
  let ntowMonthDay = ((nYear % 4 === 0) && (nYear % 100 !== 0) || nYear % 400 === 0) ? 29 : 30
  let pMonthDays = [0, 31, ptowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  let nMonthDays = [0, 31, ntowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  for (let i = 1; i < 12; i++) {
    pMonthDays[i] += pMonthDays[i - 1]
    nMonthDays[i] += nMonthDays[i - 1]
  }

  let pMonth = pd.getMonth() + 1;
  let nMonth = nd.getMonth() + 1;
  // let startTime = '2023-02-16 14:36:57'
  let pDays = pd.getDate() + pMonthDays[pMonth]
  let nDays = nd.getDate() + nMonthDays[nMonth]

  if (pDays + 1 === nDays) {
    return "昨天 " + timeStr.substring(11, 19)
  }
  if (pDays + 2 === nDays) {
    return "前天 " + timeStr.substring(11, 19)
  }

  if ((n - p) / 1000 / 60 / 60 < 24) {
    return Math.ceil((n - p) / 1000 / 60 / 60) + '小时前'
  }

  return timeStr.substring(5, 19)


}

export default func;
