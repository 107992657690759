<template id="myTopic">
  <div>
    <div style="text-align: left">它的提问({{ topicIDs.length }})</div>
    <el-divider></el-divider>
    <div v-for="(topicID) in topicIDs.slice((currentPage-1)*pageSize,pageSize*currentPage)">
      <topic-single v-bind:user-id="userId" v-bind:topic-id="topicID"></topic-single>
      <div style="">
        <div  style="text-align: left; ">
          <key-answer style="padding: 10px;" v-bind:topic-id="topicID"></key-answer>
        </div>
      </div>
      <el-divider></el-divider>
    </div>
    <div class="block" style="text-align: left">
      <el-pagination
          :hide-on-single-page="false"
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="topicIDs.length">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {request} from "../../util/api";
import func from "../../util/getDateTime";
import TopicSingle from "@/components/single/TopicSingle";
import KeyAnswer from "@/components/single/KeyAnswer";
import Utils from "@/util/utils";

export default {
  name: "PeopleQuestion",
  components: {KeyAnswer, TopicSingle},
  data() {
    return {
      peopleUserId: null,
      userId: null,
      topics: [],
      topicIDs: [],
      //分页
      pageSize: 10,
      currentPage: 1,
    }
  },
  methods: {
    changeTopicUrgent(topicId, index) {
      request("post", "/topic/changeUrgentByTopicId", {topicId: topicId}).then(res => {
        if (res.code == 200) {
          this.topics[index].isUrgent = this.topics[index].isUrgent ^ 1;
        }
      })
    },
    showPeopleInfo(id) {
      window.sessionStorage.setItem("peopleUserId", id);
      // window.open("/#/people", "_blank");
      if (this.$route.name == "People") {
        Utils.$emit("init_People")
      } else {
        this.$router.replace("/people")

      }
    },
    showAll(index, type) {
      if (type == 1) {
        this.topics[index].isShowAllTopic = !this.topics[index].isShowAllTopic
      } else {
        this.topics[index].startAnswer.isShowAll = !this.topics[index].startAnswer.isShowAll
      }
    },
    ellipsis(value, index, type) {
      if (!value) return "";
      if (value.length > 500) {
        if (type == 1) {
          this.topics[index].isShowAllTopic = false;
          this.topics[index].isNeedShowTopic = true;
        } else {
          this.topics[index].startAnswer.isShowAll = false;
          this.topics[index].startAnswer.isNeedShow = true;
        }
        // this.topics[index].isNeedShow = true;
        return value.slice(0, 500) + "...";

      }
      return value;
    },
    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
    deleteTopicById(topicId, index) {
      this.$confirm('确认删除该问题吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/topic/deleteById", {topicId: topicId}).then(res => {
          if (res.code == 200) {
            this.topics.splice(index, 1)
          }
        })

      }).catch(() => {

      });
    },
    toAnswer(topicId, isAnswer) {
      this.$router.push({
        path: '/answer',
        query: {
          isAnswer: isAnswer,
          topicId: topicId,
        }
      });
    },
    func(time) {
      return func(time)
    },
  },
  created() {
    if (window.localStorage.getItem('tokenStr')) {

      this.peopleUserId = window.sessionStorage.getItem("peopleUserId")
      if (this.peopleUserId === "null") {
        this.$router.replace("login")
        return
      }
      this.userId = window.localStorage.getItem("userId")


      request("get", "/topic/v2/getTopicIDsByUserId?userId="+this.peopleUserId).then(res => {
        if (res && res.code == 200 && res.object) {
          this.topicIDs = res.object.reverse();
        }

      })
    }
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
