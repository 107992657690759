<template>
  <div @click="updateUnreadNums">

    <div v-if="!isMobile" id="chatsss"
         class="topnav_box"
         style="width: 100%;height: 500px; min-height: 500px;padding: 0px; display: flex; flex-direction: column-reverse; overflow: auto; overflow-y: visible; border-top: 1px solid #ccc">
      <div class="chat" style="padding: 5px 10px 10px 15px" v-for="(chat, index) in chatList" :key="chat">
        <div id="singleChat" class="col" style="">
          <div style="height: 30px;width: 100%;margin-bottom: 10px;"
               v-if="index === (chatList.length - 1) || !isNearTime(chatList[index + 1].gmtCreate, chatList[index].gmtCreate)">
            <div
                style="margin: 0 auto; border-radius: 2px; width: fit-content; padding: 4px; background-color: #dadada; color: white">
              {{ toEasyTimeStr(chatList[index].gmtCreate) }}
            </div>
          </div>

            <ChatBox style="width: 100%;" v-bind:chat="chat" v-bind:toUserId="toUserId"></ChatBox>

        </div>

      </div>
    </div>


    <div v-else
         id=""
      class="topnav_box"
      style="width: 100%;height: 100%; display: flex; flex-direction: column-reverse; padding: 0px; overflow: auto;  border-top: 1px solid #ccc">
      <div class="chat" style="padding: 5px 10px 10px 15px" v-for="(chat, index) in chatList" :key="chat">
        <div id="singleChat" class="col" style="">
          <div style="height: 30px;width: 100%;margin-bottom: 10px;"
               v-if="index === (chatList.length - 1) || !isNearTime(chatList[index + 1].gmtCreate, chatList[index].gmtCreate)">
            <div
                style="margin: 0 auto; border-radius: 2px; width: fit-content; padding: 4px; background-color: #dadada; color: white">
              {{ toEasyTimeStr(chatList[index].gmtCreate) }}
            </div>
          </div>
          <ChatBox style="width: 100%;" v-bind:chat="chat" v-bind:toUserId="toUserId"></ChatBox>
        </div>

      </div>
      <div style="height:35px; "></div>
    </div>

  </div>
</template>

<script>
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";
import TopicBox from "@/components/chat/TopicBox";
import ChatBox from "@/components/chat/ChatBox";
import Utils from "@/util/utils";

export default {
  name: "ChatList",
  components: {ChatBox},
  props: ['chatList', 'toUserId'],
  data() {
    return {
      first: true,
      isMobile: false,
    }
  },
  watch: {

    chatList: function (newVal, oldVal) {
      // var box = document.getElementById("chats");
      // box.scrollTop = box.scrollHeight
      if (newVal != oldVal) {
        this.chatList = newVal;
        this.$forceUpdate()
      }
    },
    toUserId: function (newVal, oldVal) {
      if (newVal !== oldVal) {

        this.toUserId = newVal
        var box = document.getElementById("chats");
        // box.scrollTop = box.scrollHeight
      }
    },
  },
  created() {
    let _this = this;
    Utils.$on("chatList_AddChat", function (data) {
      // _this.chatList = [data, ..._this.chatList]
    })
    var box = document.getElementById("chats");
    if (box) {
      // box.scrollTop = box.scrollHeight
    }
  },
  mounted() {
    if ( /mobile/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    var box = document.getElementById("chats");
    if (box) {
      // box.scrollTop = box.scrollHeight
    }

  },
  beforeUpdate() {

  },
  updated() {
    // var box = document.getElementById("chats");
    // if (this.first) {
    //   // box.scrollTop = box.scrollHeight
    //   this.first = false;
    //   return
    // }
    //
    // if (this.chatList[this.chatList.length - 1].fromId == this.toUserId) {
    //   if (Math.abs(box.scrollHeight - box.scrollTop - 557) < 5) {
    //     box.scrollTop = box.scrollHeight
    //   }
    // } else {
    //   box.scrollTop = box.scrollHeight
    // }

  },
  methods: {
    updateUnreadNums() {
      let _this = this;
      Utils.$emit('AppVue_ChangeUnreadNums', _this.userId, 0, 0);
    },
    toEasyTimeStr(timeStr) {
      let p = Date.parse(timeStr)
      let pd = new Date(p)
      let nd = new Date()
      let n = nd.valueOf()
      if (nd.getFullYear() !== pd.getFullYear()) {
        return timeStr;
      }

      if ((n - p) / 1000 < 60) {
        return '刚刚'
      }

      if ((n - p) / 1000 / 60 < 60) {
        return Math.ceil((n - p) / 1000 / 60) + '分钟前'
      }

      if ((n - p) / 1000 / 60 / 60 < 24) {
        return Math.ceil((n - p) / 1000 / 60 / 60) + '小时前'
      }

      let pYear = pd.getFullYear()
      let nYear = nd.getFullYear()
      let ptowMonthDay = ((pYear % 4 === 0) && (pYear % 100 !== 0) || pYear % 400 === 0) ? 29 : 30
      let ntowMonthDay = ((nYear % 4 === 0) && (nYear % 100 !== 0) || nYear % 400 === 0) ? 29 : 30
      let pMonthDays = [0, 31, ptowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      let nMonthDays = [0, 31, ntowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      for (let i = 1; i < 12; i++) {
        pMonthDays[i] += pMonthDays[i - 1]
        nMonthDays[i] += nMonthDays[i - 1]
      }

      let pMonth = pd.getMonth() + 1;
      let nMonth = nd.getMonth() + 1;
      // let startTime = '2023-02-16 14:36:57'
      let pDays = pd.getDate() + pMonthDays[pMonth]
      let nDays = nd.getDate() + nMonthDays[nMonth]

      if (pDays + 1 === nDays) {
        return "昨天 " + timeStr.substring(11, 19)
      }
      if (pDays + 2 === nDays) {
        return "前天 " + timeStr.substring(11, 19)
      }

      return timeStr.substring(5, 19)


    },
    dateTimeToTime(dateTime) {
      return dateTime.substring(0, 8)
    },
    isNearTime(prev, next) {
      if (!prev || !next) {
        return true
      }
      let p = Date.parse(prev)
      let n = Date.parse(next)
      return (n - p) / 1000 <= 5 * 60
    },

  }

}
</script>

<style scoped lang="scss">
.tip {
  color: black;
  overflow-wrap: break-word;
  max-width: 300px;
  border-radius: 6px;
  /*font-family: "Microsoft JhengHei";*/
  padding: 10px;
  width: auto;
  display: inline-block !important;
  display: inline;
  min-height: 20px;
  line-height: 20px;
}

/*.left::before {*/
/*  content: '1';*/
/*  display: inline-block;*/
/*  width: 0;*/
/*  height: 0;*/
/*  border: 8px solid transparent;*/
/*  border-left: 8px solid #AFABAB;*/
/*  position: relative;*/
/*  top: 2px;*/
/*  left: 10px;*/
/*}*/

.right {
  text-align: left;
  min-height: 20px;
  line-height: 20px;
  background-color: rgb(149, 236, 105);

}

/*.left::before {*/
/*  width: 0;*/
/*  height: 0;*/

/*  line-height: 40px;*/
/*  margin: 15px 0;*/
/*  border-style: solid;*/
/*  border-color: transparent;*/
/*  border-width: 7px 7px 7px 7px;*/
/*  border-right-color: white;*/

/*}*/
.test {
  display: flex;
  background-color: transparent;

}

.test:hover {
  .left {
    background-color: #ebebeb;
  }

  .triangle-left {
    border-style: solid;
    border-color: transparent;
    border-width: 7px 7px 7px 7px;
    border-right-color: #ebebeb;
  }
}

.test:hover {
  .right {
    background-color: #89d961;
  }

  .triangle-right {
    border-style: solid;
    border-color: transparent;
    border-width: 7px 7px 7px 7px;
    border-left-color: #89d961;
  }
}

.triangle-left {
  width: 0;
  height: 0;

  line-height: 40px;
  margin: 15px 0;
  border-style: solid;
  border-color: transparent;
  border-width: 7px 7px 7px 7px;
  border-right-color: white;

}

//.right:hover {
//  background-color: #89d961;
//}
//
//.left:hover {
//  background-color: #ebebeb;
//}

.left {
  height: 20px;
  text-align: left;
  background-color: white;
}


.triangle-right {
  width: 0;
  height: 0;
  line-height: 40px;
  margin: 15px 0;
  border-style: solid;
  border-color: transparent;
  border-width: 7px 7px 7px 7px;
  border-left-color: rgb(149, 236, 105);;
  border-radius: 0px;
}


.topnav_box::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  /*:overflow-y:;*/
  background-color: rgb(245, 245, 245);

}

.topnav_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  border-radius: 10px;
  background-color: rgb(245, 245, 245);

}


.topnav_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  background-color: #d2d2d2;
}
</style>