<template>
  <div v-if="keyAnswerId !== null">
    <el-tag >精选回答</el-tag>
    <answer-single style="margin-top: 10px;" v-bind:answer-id="keyAnswerId"></answer-single>
  </div>
</template>

<script>
import {request} from "@/util/api";
import AnswerSingle from "@/components/single/AnswerSingle";

export default {
  components: {AnswerSingle},
  props:['topicId'],
  name: "KeyAnswer",
  watch: {
    topicId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.topicId = newVal;
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return{
      keyAnswerId: null,
    }
  },
  methods: {
    init() {
      if (!this.topicId || typeof this.topicId === typeof undefined) {
        return
      }
      this.getKeyAnswerIDByTopicId(this.topicId)

    },
    getKeyAnswerIDByTopicId(id) {
      request("get", "/answer/getKeyAnswerIdByTopicId?topicId=" + id).then(res => {
        if (res && res.code == 200) {
          this.keyAnswerId = res.object
        }
      })
    }
  }
}
</script>

<style scoped>

</style>