<template>
  <div style="">
    <div class="topic_title" @click="toAnswer(false)" style="flex: 1; width: fit-content; min-width: 200px;  font-size: 20px;cursor:pointer"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";
import Utils from "@/util/utils";

export default {
  name: "TopicTitle",
  props:['topicID'],
  watch: {
    topicID: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.topicID = newVal
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      title:''
    }
  },
  methods: {
    toAnswer(isAnswer) {
      if (this.$route.name != 'Answer') {
        this.$router.push({
          path: '/answer',
          query: {
            isAnswer: isAnswer,
            topicId: this.topicID,
          }
        });
      } else
        Utils.$emit('Answers_ChangeIsAnswer', this.topicID)
    },
    init() {
      this.getTopicTitleByID(this.topicID)
    },
    getTopicTitleByID(ID) {
      request("get", "/topic/v2/getTopicTitleByID?topicID="+ID).then(res=>{
        if (res && res.code == 200) {
          this.title = res.object;
        }
      })
    }
  }
}
</script>

<style scoped>
.topic_title {
  margin-left: 10px;
  color: black;
  font-weight: 700;
}
.topic_title:hover {
  color: #175199;
}
</style>