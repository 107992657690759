<template>
  <div style="flex-direction: row;display:flex;">
    <el-button style="padding: 0px;" type="text" @click="showPeopleInfo(user.id)">
        <el-avatar :size="size" >
          <img :src="user.avatar"/>
        </el-avatar>
    </el-button>
  </div>
</template>

<script>
import {request} from "@/util/api";
import Utils from "@/util/utils";

export default {
  name: "UserAvatar",
  props: ['userId', 'fromSize'],
  data() {
    return {
      user:{
      },
      size: 45,
    }
  },
  mounted() {
    if (!this.userId || typeof (this.userId) === undefined) {
      return
    }
    if (this.fromSize) {
      this.size = this.fromSize
    }
    this.getUserBasic()
  },
  methods: {
    showPeopleInfo(id) {
      window.sessionStorage.setItem("peopleUserId", id);
      // window.open("/#/people", "_blank");
      if (this.$route.name == "People") {
        Utils.$emit("init_People")
      } else {
        this.$router.replace("/people")

      }
    },
    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + this.userId, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }

    },
  },
  watch: {
    userId: function (newVal, oldVal) {
      this.userId = newVal
      if (newVal == undefined) {
        return
      }
      this.getUserBasic()
    }
  }
}
</script>

<style scoped>

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 4px 4px;
  background: #ebebeb;
}


.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>