<template>
  <div style="float: top">
      <el-avatar style="border-radius: 1px;" :size="size" shape="square">
        <img :src="user.avatar"/>
      </el-avatar>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "UserAvatarV2",
  props: ['userId', 'size', 'shape'],
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    if (!this.userId || typeof (this.userId) === undefined) {
      return
    }
    this.getUserBasic()
  },
  methods: {
    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (typeof userBasicStr === typeof undefined || userBasicStr === undefined) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + this.userId, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }

    },
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (newVal !== oldVal && typeof newVal !== typeof undefined) {
        this.userId = newVal
        this.getUserBasic()
      }

    }
  }
}
</script>

<style scoped>

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 4px 4px;
  background: #ebebeb;
}


.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>