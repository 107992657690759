<template>
  <div style="flex-direction: row;display:flex; width: 100%">
    <div  @click="showPeopleInfo(user.id)" style="padding: 0px; cursor: pointer; color: black; font-weight: inherit;" type="text"
         >

      <!--        <UserIntroduction v-bind:parentUser="user"></UserIntroduction>-->
      <div style="width: 100%;">
        {{ user.nickname }}
      </div>

    </div>

  </div>
</template>

<script>
import {request} from "@/util/api";
import Utils from "@/util/utils";

export default {
  name: "UserName",
  props: ['userId'],
  components: {},
  data() {
    return {
      user: {
        nickname: ''
      }
    }
  },
  mounted() {
    if (!this.userId || typeof (this.userId) === undefined) {
      return
    }
    if (this.userId && this.userId !== 0) {

      this.getUserBasic()
    }
  },
  methods: {

    showPeopleInfo(id) {
      if (id && id != 0) {

        window.sessionStorage.setItem("peopleUserId", id);
        // window.open("/#/people", "_blank");
        if (this.$route.name == "People") {
          Utils.$emit("init_People")
        } else {
          this.$router.replace("/people")

        }
      }
    },
    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + this.userId, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }
      return userBasic

    },
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (newVal == undefined) {
        return
      }
      if (newVal !== oldVal) {

        this.userId = newVal
        this.getUserBasic()
      }
    }
  }
}
</script>

<style scoped>

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 4px 4px;
  background: #ebebeb;
}


.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>