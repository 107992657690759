<template>
  <div>
    <div style="min-height: 200px;">

      <div style="width: auto;">
        <div style="margin:30px auto;">
          <div style="text-align: right;margin-bottom:0px;">
          </div>
          <el-form ref="loginForm" :rules="rules" class="loginContainer" :model="loginForm">
            <div style="flex-direction: row;display: flex;text-align: center">
              <!--                <h3 class="loginTitle">登录</h3>-->
            </div>
            <el-form-item prop="email">
              <el-input type="text" auto-complete="false" v-model="loginForm.email"
                        placeholder="请输入邮箱地址"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input show-password @keyup.enter.native="submitLogin" type="password" auto-complete="false"
                        v-model="loginForm.password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <div style="text-align: right">
              <el-button @click="dialogFormVisible = true" type="text">
                修改密码
              </el-button>
            </div>
            <!--                        <el-checkbox style="margin-bottom: 10px" v-model="checked">记住我</el-checkbox>-->
            <el-button type="primary" plain style="width: 100%" @click="submitLogin">登录</el-button>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog title="修改密码" width="100%" style="width: 100vw; max-width: 600px; margin: 0 auto;"
               :visible.sync="dialogFormVisible">
      <div style="width: 100%;">
        <el-form :rules="emailRules" ref="emailForm" :model="emailForm">

          <el-form-item prop="email" label="邮箱地址：">
            <div class="row">
              <el-input v-model="emailForm.email" autocomplete="off"></el-input>
              <div style="margin-left: 20px;">
                <el-button :loading="!canPost" @click="postEmailCode(60)" type="primary" plain>
                  {{ forgetBtnTips }}
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <el-form :rules="forgetRules" ref="forgetPasswordFrom" :model="forgetPasswordFrom">

          <el-form-item prop="emailCode" label="验证码：">
            <el-input v-model="forgetPasswordFrom.emailCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="修改后的密码">
            <el-input type="password" v-model="forgetPasswordFrom.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="checkPassword" label="确认密码">
            <el-input type="password" v-model="forgetPasswordFrom.checkPassword"
                      autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" plain @click="submitUpdateCode">确 定</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>


<script>
import {request} from "../util/api";
import md5 from '@/util/md5.js'

export default {
  name: "Login",
  props: ["userbasic"],
  data() {
    var validatePass = (rule, value, callback) => {
      var that = this
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (that.forgetPasswordFrom.checkPassword !== '') {
          that.$refs.forgetPasswordFrom.validateField('checkPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      var that = this
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== that.forgetPasswordFrom.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      forgetBtnTips: "发送验证码",
      loginForm: {
        email: "",
        password: ""
      },
      dialogFormVisible: false,
      activeIndex2: 3,
      checked: true,
      rules: {
        email: [{required: true, message: '请输入正确的邮箱地址', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}]
      },
      forgetPasswordFrom: {
        password: "",
        checkPassword: "",
        emailCode: "",
      },
      forgetRules: {
        password: [{required: true, validator: validatePass, trigger: 'blur'}, {
          min: 8,
          message: '密码最小长度为8位',
          trigger: 'blur'
        }, {max: 20, message: '密码最大长度为20位', trigger: 'blur'}],
        checkPassword: [{required: true, validator: validatePass2, trigger: 'blur'}, {
          min: 8,
          message: '密码最小长度为8位',
          trigger: 'blur'
        }, {max: 20, message: '密码最大长度为20位', trigger: 'blur'}],
        emailCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],

      },
      emailRules: {
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: "email", message: "请输入正确的邮箱地址", trigger: "blur"}
        ],
      },
      emailForm: {
        email: null,
      },
      canPost: true

    }
  },
  methods: {
    // 忘记密码
    submitUpdateCode() {
      this.$refs['forgetPasswordFrom'].validate((valid) => {
        if (valid) {
          request("post", "/user/forgetPassword", {
            email: this.emailForm.email,
            code: this.forgetPasswordFrom.emailCode,
            password: this.forgetPasswordFrom.password
          }).then(res => {
            if (res.code == 200) {
              this.dialogFormVisible = false
            }
          })
        }
      })
    },
    postEmailCode(time) {
      this.$refs['emailForm'].validate((valid) => {
        if (valid) {
          this.canPost = false;
          if (time === 60) {
            request("post", "/registerCode/forget", {
              email: this.emailForm.email,
              type: 1
            }).then(res => {

              if (res.code !== 200) {
                this.forgetBtnTips = "重新发送验证码"
                this.canPost = true;
                return
              }
            })
          }
          setTimeout(() => {
            this.forgetBtnTips = time + "s";
            if (time > 0)
              this.postEmailCode(time - 1)
            else {
              this.forgetBtnTips = "重新发送验证码"
              this.canPost = true;
              return
            }
          }, 1000)
        }
      })


    },
    updateUserBasic() {
      this.$parent.$parent.$parent.updateUserBasic()

    },
    toRegister() {
      this.$router.push("/register")
    },
    // 登录
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          request("post", "/login", {email: this.loginForm.email, password: this.loginForm.password}).then(res => {
            if (res.code == 200) {
              let tokenStr = res.object.tokenHead + res.object.token;
              window.localStorage.removeItem("userId")
              window.localStorage.removeItem("tokenStr")
              window.localStorage.removeItem("topic_types")
              window.sessionStorage.clear()

              window.localStorage.setItem("tokenStr", tokenStr);
              window.localStorage.setItem("userId", res.object.userId);
              window.sessionStorage.setItem("userId", res.object.userId);

              this.$emit("updateUserBasic");
              this.$router.push({
                path: '/index',
              })
              // this.userbasic =
              window.location.reload()
            }
          })
        } else {
          this.$message.error("请输入正确信息")
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
