<template>
  <div>
    <div >
      <div style="width: 100vw; max-width: 800px; overflow-x: hidden; margin: 0px auto;background-color: white; height: 100vh;  ">
        <div style="text-align: center; margin-top: 10px;">分享你的想法</div>
        <div style="margin-left: 10px; margin-right: 10px;" class="">
          <el-input style="margin-top: 20px;" rows="6" type="textarea" maxlength="1000" show-word-limit v-model="text"></el-input>
          <div style="width: 100vw; max-width: 600px; flex-direction: row; display: flex; flex-wrap: wrap; margin-top: 10px;">

            <div v-for="(url, i) in imgUrlList">
              <div class="col" style="">
                <el-image
                    style="width: 25vw; height: 25vw; max-width: 160px; max-height: 160px;    border-radius: 10px; margin-left: 1vw"
                    :preview-src-list="imgUrlList"
                    class="el-upload-list__item-thumbnail"
                    :src="url" alt=""
                    fit="fit"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div style="width: 10vw; height: 5px; text-align: center;">
                  <el-button @click="deleteImg(i)" style="width: 5px;height: 5px;" type="text">

                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
              </div>

            </div>
            <div  v-if="imgUrlList.length < 9" style="margin-left: 20px; width: 150px;height: 150px; line-height: 150px; margin-top: 10px;">
              <el-upload
                  :auto-upload="true"
                  :data={}
                  :headers="headers"
                  action="/api/verify/uploadImg"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                <img
                    style="height: 50px;width: 50px;"
                    class="el-upload-list__item-thumbnail"
                    src="upload.png" alt=""

                >
              </el-upload>
            </div>
          </div>
        </div>
        <div class="row" style="width: 100%;margin-top: 20px;">
          <div class="row" style="width: 50%;text-align: left;margin: auto  20px;">
            <el-switch
                v-model="onlyFriend"
                inactive-color="#f6f6f6"
            >
            </el-switch>
            <div style="margin-left: 10px;">
              <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  content="开启后，只有互相关注的朋友可见">
                <el-button type="text" style="color: black;margin-top: -10px;" slot="reference">仅朋友可见<i
                    class="el-icon-warning-outline"></i></el-button>
              </el-popover>

            </div>
          </div>
          <div style="width:50%;text-align: right; margin-right: 20px;">
            <el-button @click="addMoment" type="primary" plain>发布<i class="el-icon-upload el-icon--right"></i>
            </el-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/monokai-sublime.css'
import {request} from "@/util/api";
import Utils from "@/util/utils";

export default {
  name: "WriteMoment",
  components: {},
  data() {
    return {
      onlyFriend: false,
      text: '',
      imgUrlList: [],
      headers: {},
    };
  },

  methods: {

    getMoment() {

    },
    addMoment() {
      request("post", "/moment/add", {text:this.text, onlyFriend: this.onlyFriend,  imgUrlList: this.imgUrlList}).then(res=> {
        if (res && res.code) {
          this.$message.success("发布成功");
          this.onlyFriend = false;
          this.imgUrlList = [];
          this.text = ""
          Utils.$emit("AppVue_ShowMoment")
        }
      })
    },
    deleteText() {
      this.text = ''
    },

    deleteImg(index) {
      this.imgUrlList.splice(index, 1)
    },
    beforeAvatarUpload(file) {
      if (this.imgUrlList.length >= 9) {
        this.$message.warning("最多上传9张图片")
        return
      }
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 30;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 30MB!');
      }

      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file, fileList) {
      //上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.imgUrlList.push(file.response.object.fileUrl)
        this.dialogVisible = true
      })
    },
  },
  mounted() {
    if (window.localStorage.getItem('tokenStr')) {
      let token = window.localStorage.getItem("tokenStr")
      this.headers = {Authorization: token}


    } else {
      this.$router.replace("login")
      this.$message("请先登录！")
    }
  },

}

</script>

<style scoped>
.mainEditor {
  margin-top: 10px;
  padding: 20px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);*/
  border-radius: 10px;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
