<template>
  <div>

    <WXPhone style="overflow-y:hidden;" v-if="isPhone"></WXPhone>
    <WXWeb v-else></WXWeb>
  </div>
</template>

<script>
import WXPhone from "@/views/WX-Phone";
import WXWeb from "@/views/WX-Web";
import {request} from "@/util/api";

export default {
  name: "WX",
  components: {WXWeb, WXPhone},
  data() {
    return {
      isPhone: false,
      sql: "",
      text:"",
    }
  },
  mounted() {
    if (/mobile/i.test(navigator.userAgent)) {
      this.isPhone = true;

      // this.$route.meta.keepAlive = false
    } else {
      this.$route.meta.isPhone = true
      // this.$route.meta.keepAlive = true
    }
  },
  methods: {
    // submitSql() {
    //   request("post", "/admin/submitSql", {sql: this.sql}).then(res => {
    //     if (res && res.code == 200) {
    //       this.text = JSON.stringify(res.object);
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>

</style>