<template>
  <div>
    <div v-if="user.authName && user.authName.length > 0">
      <div style="line-height: 100%;">
        {{ user.authName }}
        <div class="ProfileHeader-divider"></div>
        {{ user.authDetail }}
        <el-tooltip content="官方认证" placement="top-start" style="cursor:pointer">
          <img style="width: 20px; height: 20px;"
               :src="user.authIconUrl"/>
        </el-tooltip>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "UserAuth",
  props: ['userId'],
  components: {},
  data() {
    return {
      user: {
        nickname: ''
      }
    }
  },
  mounted() {
    if (!this.userId || typeof (this.userId) === undefined) {
      return
    }
    if (this.userId && this.userId !== 0) {

      this.getUserBasic()
    }
  },
  methods: {
    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + this.userId, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }
      return userBasic

    },
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (newVal == undefined) {
        return
      }
      if (newVal !== oldVal) {

        this.userId = newVal
        this.getUserBasic()
      }
    }
  }
}
</script>

<style scoped>

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 4px 4px;
  background: #ebebeb;
}


.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>