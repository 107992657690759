<template>
  <div style="width: 100%">
    <div style="">

      <div style="width: 1000px;margin: 0px auto;background-color: white;padding: 10px;min-height: 100vh">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="收到的消息" name="first">
            <div style="border-radius: 0px;">
              <div style="text-align: left;margin-left: 20px;line-height: 20px;">
                <el-button @click="clearAllMessage" type="text" style="color: #8c939d;">
                  <el-image class="good" style="width: 20px;height: 20px;position:relative;top: 3px;"
                            src="clear.png">
                  </el-image>
                  全部已读
                </el-button>
              </div>
              <div class="messageItem" style="" v-for="(item,index) in receiveMessage">
                <el-card class="box-card">
                  <div style="min-height: 50px;" class="">
                    <span
                        style="float:left;font-size: 25px;margin-right: 20px;line-height: 50px;">{{ item.title }}</span>
                    <div class="row">

                      <user-avatar v-if="item.fromNickname!='匿名用户'" v-bind:userId="item.fromId"></user-avatar>
                      <span style="margin-left:10px;float:left;line-height: 50px;color: #C0C4CC">{{
                          item.fromNickname
                        }}</span>
                    </div>

                    <el-button @click="showMessageBox(item,index,0)"
                               style="float: right;padding: 5px;" type="text">回复
                    </el-button>


                    <el-button @click="deleteMessage(item,0)" style="float: right;padding: 5px;"
                               type="text">删除
                    </el-button>


                    <el-button @click="setReaded(item,index)" v-if="item.readed===false"
                               style="float: right;padding: 5px;" type="text">设为已读
                    </el-button>
                    <span v-if="item.readed===true"
                          style="float:right;line-height: 50px;font-size: 13px;">已读</span>
                    <span v-else style="float:right;line-height: 50px; font-size: 13px;">未读</span>

                  </div>
                  <div class="col">
                                        <span style="padding:10px;text-align: left;min-height: 50px;">
                                            {{ item.context }}
                                        </span>
                    <span style="text-align: right;">
                                            {{ func(item.gmtCreate) }}
                                        </span>
                  </div>
                </el-card>

              </div>

              <el-card v-if="receiveMessage.length==0" class="messageItem" style="min-height: 50px;">
                o(╥﹏╥)o你的收信箱竟然是空的
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的发送" name="second">
            <div style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 0px">
              <div style="border-radius: 0px;">
                <div class="messageItem" style="" v-for="(item,index) in sendMessage">
                  <el-card class="box-card">
                    <div style="min-height: 50px;" class="">
                      <div class="row">
                        <div style="line-height: 50px; margin-right: 10px;">

                        发送给：
                        </div>

                        <user-avatar v-if="item.toNickname!='匿名用户'" v-bind:userId="item.toId"></user-avatar>
                        <span style="margin-left:10px;float:left;line-height: 50px;color: #C0C4CC">{{
                            item.toNickname
                          }}</span>
                      </div>
                      <el-button @click="showMessageBox(item,index,1)"
                                 style="float: right;padding: 5px;" type="text">再发一封
                      </el-button>

                      <el-button @click="deleteMessage(item,1)" style="float: right;padding: 5px;"
                                 type="text">删除
                      </el-button>

                      <span v-if="item.readed===true"
                            style="float:right;line-height: 50px;font-size: 13px;">已读</span>
                      <span v-if="item.readed===false"
                            style="float:right;line-height: 50px; font-size: 13px;">未读</span>

                    </div>
                    <div class="col">
                                        <span style="padding:10px;text-align: left;min-height: 50px;">
                                            {{ item.context }}
                                        </span>
                      <span style="text-align: right;">
                                            {{ func(item.gmtCreate) }}
                                        </span>
                    </div>
                  </el-card>
                </div>
                <el-card v-if="sendMessage.length==0" class="messageItem">
                  o(╥﹏╥)o你的私信箱居然是空的
                </el-card>
              </div>

            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="私信"
               width="30%"
               :visible.sync="messageBox">
      <div>
        <el-form ref="messageForm" :model="messageForm" :rules="messageFormRules">
          <div style="text-align: left;">
            发送给：{{ messageForm.nickname }}
          </div>
          <el-form-item prop="title" label="标题">
            <el-input v-model="messageForm.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="context" label="内容">
            <el-input type="textarea" v-model="messageForm.context" autocomplete="off"></el-input>
          </el-form-item>
          <div style="text-align: left">
            <el-switch
                v-model="messageForm.fromShowName"

                active-text="匿名">
            </el-switch>
          </div>

        </el-form>

        <span slot="footer" class="dialog-footer">
                <el-button @click="cancelSendMessage">取 消</el-button>
                <el-button :loading="!canSendMessage" type="primary" @click="submitMessage">确 定</el-button>
            </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {request} from "../util/api";
import func from "../util/getDateTime";
import UserAvatar from "@/components/UserInfo/UserAvatar";

export default {
  name: "Message",
  components: {UserAvatar},
  data() {
    return {
      activeName: "first",
      receiveMessage: [],
      sendMessage: [],
      messageForm: {
        id: null,
        toId: null,
        fromId: null,
        context: "",
        title: "",
        fromShowName: false,
      },
      messageBox: false,
      sendMessageIndex: null,
      messageFormRules: {
        title: [{max: 20, message: '标题最大长度为10位', trigger: 'blur'}],
        context: [{required: true, message: '请输入内容', trigger: 'blur'}, {
          min: 0,
          message: '内容最小长度为2位',
          trigger: 'blur'
        }, {max: 100, message: '内容最大长度为100位', trigger: 'blur'}],

      },
      canSendMessage: true
    }
  },
  methods: {
    clearAllMessage() {
      request("post", "/message/clearAll").then(res => {
        if (res.code == 200) {
          if (res.message === "") {
            window.location.reload()
          }
        }
      })
    },
    cancelSendMessage() {
      this.messageBox = false;
      this.canSendMessage = true;
    },
    deleteMessage(item, flag) {
      this.$confirm('确认删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/message/delete", {id: item.id, type: flag}).then(res => {
          if (res.code == 200) {
            this.$parent.getUnReadedMessage();
            if (flag == 0)
              this.getReceiveMessage()
            else
              this.getSendMessage();
          }
        })
      }).catch(() => {

      });

    },
    showMessageBox(item, index, flag) {
      this.sendMessageIndex = index;
      this.messageBox = true;
      this.messageForm.id = item.id;
      if (flag == 0)
        this.messageForm.nickname = item.fromNickname
      if (flag == 1)
        this.messageForm.nickname = item.toNickname;

    },
    submitMessage() {
      // this.messageForm.fromShowName = !this.messageForm.fromShowName;

      this.$refs['messageForm'].validate((valid) => {
        if (valid) {
          let form = {...this.messageForm}
          this.canSendMessage = false
          form.fromShowName = !form.fromShowName;
          request("post", "/message/add", form).then(res => {
            if (res.code == 200) {
              this.messageBox = false;
              this.messageForm = {
                toId: null,
                fromId: null,
                context: "",
                title: "",
                fromShowName: false,
              }
            }
            this.canSendMessage = true;
          })
        }
      })
    },
    setReaded(item, index) {
      request("post", "/message/setReaded", {id: item.id}).then(res => {
        if (res.code == 200) {
          this.getReceiveMessage();
          this.$parent.getUnReadedMessage();
        }
      })

    },
    func(time) {
      return func(time)
    },
    getReceiveMessage() {
      request("get", "/message/getReceive").then(res => {
        if (res.code == 200) {

          this.receiveMessage = res.object.reverse()
        }

      })
    },
    getSendMessage() {
      request("get", "/message/getSend").then(res1 => {
        if (res1.code == 200) {

          this.sendMessage = res1.object.reverse()
        }
      })
    }
  },
  mounted() {

    if (window.localStorage.getItem('tokenStr')) {
      this.getReceiveMessage();
      this.getSendMessage();
      // this.$parent.getUserBasic();
    } else {
      this.$router.replace("/")
      this.$message("请先登录！")
    }

  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.messageItem {

  padding: 20px;
  border-radius: 0px

}
</style>
