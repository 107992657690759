<template>
  <div>
    <el-table
        ref="filterTable"
        :data="userList.slice((currentPage-1)*pageSize,pageSize*currentPage)"
        style="width: 100%">
      <el-table-column
          prop="id"
          label="用户"
          width=""
      >
        <template slot-scope="scope">
          <user-basic v-bind:userId="scope.row.id"></user-basic>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width=""
      >
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱"
      >

      </el-table-column>
    </el-table>
    <el-pagination
        :hide-on-single-page="false"
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[5,10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="userList.length">
      >
    </el-pagination>
  </div>
</template>

<script>
import {request} from "@/util/api";
import UserBasic from "@/components/UserInfo/UserBasic";

export default {
  components: {UserBasic},
  props: ['roleId'],
  name: "SearchPeople",
  data() {
    return {
      keyWord: "",
      startUserId: 0,
      userCount: 500,
      userList: [],
      allUserList: [],
      //分页
      pageSize: 10,
      currentPage: 1,
      canSendMessage: true,
    }
  },
  mounted() {
    this.keyWord = this.$route.query.keyWord
    this.toSearchUserByKey()
  },
  methods: {

    toSearchUserByKey() {
      if (this.keyWord && this.keyWord.length > 0) {
        request("get", "/user/getUserListByKey?key=" + this.keyWord).then(res => {
          if (res && res.code == 200) {
            this.userList = res.object.userList
          }
        })
      } else {
        this.getUsers(0)
      }
    },
    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>