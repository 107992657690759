import axios from 'axios'
import {Message} from 'element-ui'
import router from '../router'

axios.interceptors.request.use(config => {
        if (window.localStorage.getItem('tokenStr')) {
            config.headers['Authorization'] = window.localStorage.getItem('tokenStr');
        }
        return config;
    }, error => {
        console.log(error)
    }
)

axios.interceptors.response.use(success => {

    // if (window.sessionStorage.getItem('tokenStr')){
    //业务逻辑错误
    if (success.status && success.status == 200) {
        if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {

            if (success.data.message.length > 0) {
                Message.error({message: success.data.message});
            }

            if (success.data.code == 401) {
                router.replace("/login")
            }
            if (success.data.code == 403) {
                window.localStorage.removeItem("tokenStr")
            }
            return;
        }
        if (success.data.message) {
            Message.success({message: success.data.message});
        }
        return success.data;
    }

}, error => {
    router.replace("/login")
    return null;
})

let base = '/api'

export const request = (method, url, params) => {
    return axios({
        method: method,
        url: base + url,
        data: params
    })
}

