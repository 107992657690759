<template>
  <div id="index"
       style="max-width: 1000px; overflow-x: hidden; height: 100%; margin: 0px auto;background-color: white">
    <div v-if="isPhone">

      <div style="height: 20px;padding: 10px; width: 100%; overflow-x: auto;">
        <div class="row type_scroll" style=" justify-content: center; overflow-x: auto; ">

          <div class="row" style="justify-content: flex-start; overflow-x: auto;">
            <div @click="changeChoiceType(type)" v-for="type in typeNameList"
                 style="margin-left: 20px; margin-right: 20px; cursor: pointer; white-space:nowrap;  ">
              <div v-if="choiceType.name === type.name" style="font-weight: 700">
                {{ type.name }}
              </div>
              <div v-else style="">
                {{ type.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="height: 20px;padding: 10px;">
      <div class="row type_scroll" style=" justify-content: center;  ">
        <div class="horizontal-scroll" ref="scrollContainer">
          <div class="content" ref="scrollContent">
            <div class="row" style="justify-content: flex-start; width: max-content;  overflow-y: hidden;">
              <div @click="changeChoiceType(type)" v-for="(type,index) in typeNameList" :key="index"
                   style="margin-left: 20px; margin-right: 20px; cursor: pointer; white-space:nowrap;  ">
                <div v-if="choiceType.name === type.name" style="font-weight: 700">
                  {{ type.name }}
                </div>
                <div v-else style="">
                  {{ type.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop right="10" bottom="10" target=""></el-backtop>

    <div id="indexList" style="border-radius: 0px;overflow: auto;min-height: 1000px; overflow-x: hidden;" class="grid-content bg-purple-light maincontext">
      <div v-for="(id) in topics.slice((currentPage-1)*pageSize,pageSize*currentPage)">
        <!--    话题      -->
        <topic-single style="width: 100%;"
                      v-bind:topic-id="(id)"></topic-single>
        <!--          <topic-single v-else-if="topic" style="width: 100%;" v-bind:user-id="userId"-->
        <!--                        v-bind:topic="topic"></topic-single>-->

        <!--    精选回答      -->
        <div>
          <div v-if="id" style="text-align: left;padding: 10px 0 10px ">
            <key-answer style="padding: 10px;" v-bind:topic-id="id"></key-answer>
          </div>
        </div>
        <el-divider></el-divider>
      </div>

      <div class="col"
           style="padding: 20px;text-align: center;height: 100vh; max-height: 1000px;justify-content: center;"
           v-if="topics.length==0">
        o(╥﹏╥)o啥也没有
      </div>
      <div class="block" style="text-align: left">
        <el-pagination
            :hide-on-single-page="false"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="currentPage"
            :page-sizes="[5,10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="topics.length">
        </el-pagination>
      </div>

    </div>

  </div>
</template>

<script>
import {request} from "../util/api";
import func from "@/util/getDateTime.js";
import AnswerSingle from "@/components/single/AnswerSingle";
import TopicSingle from "@/components/single/TopicSingle";
import Utils from "@/util/utils";
import KeyAnswer from "@/components/single/KeyAnswer";


export default {
  name: "Index",
  components: {KeyAnswer, TopicSingle},
  props: ['flag'],
  watch: {
    flag: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.topics = []
        await this.init()
        this.$forceUpdate();
      }
    }
  },
  data() {
    return {
      isPhone: false,
      userId: null,
      topics: [],
      //分页
      pageSize: 10,
      currentPage: 1,
      keyAnswers: {},
      typeNameList: [],
      choiceType: {name: "全部", id: 0},
    }
  },
  created() {
    if (/mobile/i.test(navigator.userAgent)) {
      this.isPhone = true;
    }
    let _this = this
    Utils.$on('On_AppVue_DeleteTopic', function (topicId) {
      for (let i = 0; i < _this.topics.length; i++) {
        if (_this.topics[i].topicId === topicId) {
          // _this.topics.splice(i, 1)
          _this.topics.splice(i, 1)
          break
        }
      }

    })
    Utils.$on('On_AppVue_DeleteAnswer', function (answerId) {
      for (let i = 0; i < _this.topics.length; i++) {
        if (_this.topics[i].keyAnswerId === answerId) {
          _this.topics[i].keyAnswerId = null
          break
        }
      }
    })
    Utils.$on('Index_Change_Mode', async function () {
          _this.init()

        }
    )
  },
  async mounted() {
    await this.init()
// 获取滚动容器和内容元素
    const container = this.$refs.scrollContainer;
    const content = this.$refs.scrollContent;

    // 鼠标按下的位置
    let startX;
    // 鼠标按下时滚动容器的位置
    let scrollLeft;

    container.addEventListener("mousedown", e => {
      // 记录鼠标按下的位置和滚动容器的位置
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;

      // 添加鼠标移动和抬起事件监听
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    });

    function onMouseMove(e) {
      // 计算鼠标移动的距离
      const x = e.pageX - container.offsetLeft;
      const distance = x - startX;

      // 设置滚动容器的位置
      container.scrollLeft = scrollLeft - distance;
    }

    function onMouseUp() {
      // 移除鼠标移动和抬起事件监听
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

  }
  ,
  methods: {


    async changeChoiceType(type) {
      this.choiceType = type
      this.topics = await this.getTopicByType(type)
    },
    getTopicTypeList() {
      var types = window.localStorage.getItem("topic_types");
      if (types && types != null) {
        this.typeNameList = [{name: "全部", id: 0}, ...JSON.parse(types)]
        return
      }
      request("get", "/type/getAllTypes").then(res => {
        if (res && res.code == 200) {
          this.typeNameList = [{name: "全部", id: 0}, ...res.object];
          window.localStorage.setItem("topic_types", JSON.stringify(res.object))
        }
      })
    },
    async init() {
      this.getTopicTypeList()
      if (this.$route.query.keyWord && this.$route.query.keyWord.length > 0) {
        this.topics = await this.getTopicsByKeyWord(this.$route.query.keyWord)
      } else if (this.$route.name === "FocusTopic") {
        this.topics = await this.getFocusTopic()
      } else {
        this.topics = await this.getTopics()
      }
    },
    async getTopicByType(type) {
      return new Promise(function (resolve) {
        request("get", "/topic/getTopicIDsByTypeID?typeID=" + type.id).then(res => {
          if (res && res.code == 200) {
            if (res.object == null) {
              resolve([])
            } else {
              resolve(res.object.reverse())

            }
          }
        })

      })
    },
    async getTopics() {
      return new Promise(function (resolve) {
        request("get", "/topic/v2/getAllIDs").then(res => {
          if (res && res.code == 200) {
            resolve(res.object.reverse())
          }
        })
      })

    },
    async getFocusTopic() {
      return new Promise(function (resolve) {
        request("get", "/topic/v2/getTopicIDsByFocus",).then(res => {
          if (res && res.code == 200) {
            resolve(res.object.reverse())
          }
        })
      })
    }
    ,
    async getTopicsByKeyWord(keyWord) {
      let _this = this
      return new Promise(function (resolve) {
        request("get", "/topic/v2/getTopicIDsByKeyWord?keyWord=" + keyWord,).then(res => {
          if (res.code && res.code == 200) {
            if (res.object.length > 0) {
              resolve(res.object.reverse())
            } else {
              _this.$message('没有你想搜的问题喔！')
              resolve([])
            }
          }
        })
      })
    }
    ,

    ellipsis(value, index, type) {
      if (!value) return "";
      if (value.length > 500) {
        if (type == 1) {
          this.topics[index].isShowAllTopic = false;
          this.topics[index].isNeedShowTopic = true;
        } else {
          this.topics[index].startAnswer.isShowAll = false;
          this.topics[index].startAnswer.isNeedShow = true;
        }
        // this.topics[index].isNeedShow = true;
        return value.slice(0, 500) + "...";

      }
      return value;
    }
    ,
    currentChange(e) {
      let box = document.getElementById("index")
      box.scrollTop = 0
      this.currentPage = e;
    }
    ,
    sizeChange(e) {
      this.pageSize = e;
    }
    ,
    showPeopleInfo(id) {
      window.sessionStorage.setItem("peopleUserId", id);
      // window.open("/#/people", "_blank");
      if (this.$route.name == "People") {
        Utils.$emit("init_People")
      } else {
        this.$router.replace("/people")

      }
    },

    func(time) {
      return func(time)
    },
  }
  ,


}
</script>

<style scoped>
.maincontext {
  padding: 10px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);*/
  border-radius: 10px;
  background-color: white;
}

table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.choice_type_name {
  font-weight: 500;
}

table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}

table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}

.type_scroll ::-webkit-scrollbar {
  height: 0px;
  width: 0 !important;
}

pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 4px;
  background: #ebebeb;
}

.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}

.horizontal-scroll {
  overflow: hidden;
  white-space: nowrap;
}

.content {
  display: inline-block;
  /* 根据实际内容宽度设置宽度 */
  width: 1000px;
  /* 禁用文本选择 */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}
</style>
