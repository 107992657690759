<template>
  <div>
    <div v-if="user.sex==='男'" >
      <i class="el-icon-male"></i>
    </div>
    <div v-else-if="user.sex==='女'">
      <i class="el-icon-female"></i>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "UserSex",
  props:['userId'],
  data() {
    return{
      user:{},
    }
  },
  mounted() {
    if (!this.userId) {
      return
    }
    this.getUserBasic()
  },
  methods: {
    getUserBasic() {
      let userBasicStr = window.sessionStorage.getItem("user_basic:" + this.userId)
      let userBasic = null
      if (userBasicStr == null) {

        request("get", "/user/getBasicById?userId=" + this.userId).then(res => {
          if (res && res.code == 200) {
            userBasic = res.object
            window.sessionStorage.setItem("user_basic:" + this.userId, JSON.stringify(userBasic))
            this.user = userBasic
          }
        })
      } else {
        userBasic = JSON.parse(userBasicStr)
        this.user = userBasic
      }
      return userBasic

    },
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (newVal == undefined) {
        return
      }
      if (newVal !== oldVal) {
        this.userId = newVal
        this.getUserBasic()
      }
    }
  }
}
</script>

<style scoped>

</style>