<template>
  <div>
    <el-button icon="el-icon-s-promotion" v-if="!flag" @click="showMessageBox()" size="large" type="info" plain>发私信
    </el-button>
    <el-dialog title="私信"
               width="30%"
               :visible.sync="messageBox">
      <div>
        <el-form ref="messageForm" :model="messageForm" :rules="messageFormRules">
          <div style="text-align: left;">
            发送给：{{ messageForm.nickname }}
          </div>
          <el-form-item prop="title" label="标题">
            <el-input v-model="messageForm.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="context" label="内容">
            <el-input type="textarea" v-model="messageForm.context" autocomplete="off"></el-input>
          </el-form-item>
          <div style="text-align: left;margin-bottom: 10px;">
            <el-switch
                v-model="messageForm.fromShowName"

                active-text="匿名">
            </el-switch>
          </div>

        </el-form>

        <span slot="footer" class="dialog-footer">
                <el-button @click="cancelSendMessage">取 消</el-button>
                <el-button :loading="!canSendMessage" type="primary" @click="sendMessage">确 定</el-button>
            </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {request} from "../util/api";

export default {
  name: "SendMessage",
  data() {
    return {
      messageBox: false,
      messageForm: {
        toId: null,
        fromId: null,
        context: "",
        title: "",
        fromShowName: false,
      },
      sendMessageIndex: null,
      messageFormRules: {
        title: [{max: 20, message: '标题最大长度为10位', trigger: 'blur'}],
        context: [{required: true, message: '请输入内容', trigger: 'blur'}, {
          min: 2,
          message: '内容最小长度为2位',
          trigger: 'blur'
        }, {max: 100, message: '内容最大长度为100位', trigger: 'blur'}],

      },
      peopleUserId: null,
      userId: null,
      flag: true,
      canSendMessage: true
    }
  },
  methods: {
    cancelSendMessage() {
      this.messageBox = false;
      this.canSendMessage = true;
    },
    getUserInfo(id) {
      let that = this
      request("post", "/user/getInfoById", {id: id}).then(res => {
        if (res.code && res.code == 200) {
          that.peopleUser = res.object
          that.messageForm.nickname = this.peopleUser.nickname;
        }
      })
    },
    showMessageBox() {
      this.messageForm.toId = window.sessionStorage.getItem("peopleUserId");
      this.peopleUserId = this.messageForm.toId
      this.getUserInfo(this.messageForm.toId)


      this.messageBox = true;
    },
    sendMessage() {
      // this.messageForm.fromShowName = !this.messageForm.fromShowName;

      this.$refs['messageForm'].validate((valid) => {
        if (valid) {
          let form = {...this.messageForm}
          this.canSendMessage = false
          form.fromShowName = !form.fromShowName;
          request("post", "/message/add", form).then(res => {
            if (res.code == 200) {
              this.messageBox = false;
              this.messageForm = {
                toId: null,
                fromId: null,
                context: "",
                title: "",
                fromShowName: false,
              }
            }
            this.canSendMessage = true;
          })
        }
      })


    },
  },
  mounted() {
    this.userId = window.localStorage.getItem("userId");
    this.peopleUserId = window.sessionStorage.getItem("peopleUserId");
    this.flag = this.userId == this.peopleUserId
  }
}
</script>

<style scoped>

</style>
