<template>
  <div style="width: 100%;background-color: white;">
    <div style="background-color: white;">

      <div style="width: 1300px;margin: 0px auto;min-height: 100vh">

        <el-tabs v-model="choiceRole" style="">

          <el-tab-pane lazy label="角色管理" name="role" >
            <RoleManageTable :roleId="3"></RoleManageTable>
          </el-tab-pane>

          <el-tab-pane lazy label="审核订单" name="verify" >
            <AdminVerify ></AdminVerify>
          </el-tab-pane>

          <el-tab-pane lazy label="标签管理" name="type" >
            <TopicTypeManage ></TopicTypeManage>
          </el-tab-pane>
          <el-tab-pane lazy label="sql执行" name="sql" >
            <SqlManage ></SqlManage>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import RoleManageTable from "@/views/admin/RoleManageTable";
import {request} from "@/util/api";
import AdminVerify from "@/views/admin/AdminVerify";
import TopicTypeManage from "@/views/admin/TopicTypeManage";
import SqlManage from "@/views/admin/SqlManage";
export default {
  name: "Manage",
  components: {SqlManage, TopicTypeManage, AdminVerify, RoleManageTable},

  data() {
    return {
      choiceRole: 'role',
      userRole: 'Admin'
    }
  },
  mounted() {
    if (!this.judge()) {
      this.$message.error("无权限");
      this.$router.replace("/index")
    }
  },
  methods: {
    judge() {
      let role = window.sessionStorage.getItem("userRole")
      if (role == null) {
        request("get", "/admin/getUserRole").then(res=> {
          if (res && res.code == 200) {
            role = res.object
            window.sessionStorage.setItem("userRole", role)
          }
        })
      }
      return role == "Admin" || role == "Checker"
    },
    compare(userRole, role) {
      let m = {'Admin': 99, 'Checker': 98, 'User': 97, 'BadUser': 0}

      return m[userRole] - m[role] >= 0
    }
  }
}
</script>

<style scoped>

</style>