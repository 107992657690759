<template>

  <div style="min-height: 1000px;background-color: #F6F6F6">
<!--    <div style="height: 1px;"></div>-->
    <div style="width: 100vw;max-width: 1000px;margin: 0px auto;background-color: #F6F6F6; ">
      <div style="border-radius: 0px; overflow-x: hidden; height: 1000px; width: 100vw; max-width: 1000px " class="grid-content bg-purple-light maincontext">

<!--        <h1>审核</h1>-->
        <div style="width: 90vw; max-width: 800px; margin: 0 auto;">
          <el-table
              :data="authList"
              border>
            <el-table-column
                fixed
                prop="first"
                label="学校"
                width="">
            </el-table-column>
            <el-table-column
                prop="second"
                label="专业/班级"
                width="">
            </el-table-column>
            <el-table-column
                prop="notes"
                label="备注"
                width="">
            </el-table-column>
            <el-table-column
                prop="imgs"
                label="审核图片"
                width=""
            >
              <template slot-scope="scope">
                  <div style="width: 180px; flex-direction: row; display: flex; flex-wrap: wrap;">
                    <div v-for="url in scope.row.imgs">
                      <div class="col" style="height: 50px;">
                        <el-image
                            style="width: 50px; height: 50px;border-radius: 10px; margin-right: 10px;"
                            :preview-src-list="scope.row.imgs"
                            class="el-upload-list__item-thumbnail"
                            :src="url" alt=""
                            fit="cover"
                        >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                    </div>
                  </div>
              </template>
            </el-table-column>

            <el-table-column
                prop="valid"
                label="审核状态"
                width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 1">
                  <el-tag >
                    审核通过
                  </el-tag>
                </div>
                <div v-else-if="scope.row.status == 0">
                  <el-tag type="warning" >
                    审核中
                  </el-tag>
                </div>
                <div v-else>
                  <el-tag type="danger" >
                    不通过
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="valid"
                label="操作"
                >
              <template slot-scope="scope">
                <el-button
                    @click="deleteVerifyById(scope.$index, scope.row.id)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>

              <el-empty description="描述文字"></el-empty>

          </el-table>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "MyVerify",
  data() {
    return {
      authList: []
    }
  },
  mounted() {
    this.getMyVerify()
  },
  methods: {
    deleteVerifyById(index, id){
      this.$confirm('确认删除该审核吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/verify/deleteByID",{id: id}).then(res=> {
          if (res && res.code == 200) {
            this.authList.splice(index, 1);
          }
        })

      }).catch(() => {

      });

    },
    getMyVerify() {
      request("get", "/verify/get"
      ).then(res => {
        if (res && res.code == 200 && res.object) {
          this.authList = res.object
        } else {
          this.$message.warning("获取审核信息失败")
        }
      })
    },
  }
}
</script>

<style scoped>

.maincontext {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 10px;
  background-color: white;
  overflow-x: hidden;
}

table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}

table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}

pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 4px;
  background: #ebebeb;
}

.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
}

.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>