<template>
  <div style="width: 100vw; max-width: 100%;">
    <el-popconfirm
        @confirm="addType()"
        title="你确认要新增标签吗？"
    >
      <el-button type="primary" slot="reference">新增</el-button>
    </el-popconfirm>
    <div class="row" style="">
      <el-table
          style="height: fit-content"
          :data="typeList.slice((currentPage-1)*pageSize,pageSize*currentPage)"
      >
        <el-table-column
            prop="id"
            label="ID"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="名字"
            width="">
          <template slot-scope="scope">
            <div v-if="scope.row.state !== 1">{{ scope.row.name }}</div>
            <div v-else>
              <el-input v-model="scope.row.name"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="description"
            label="描述"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.state !== 1">{{ scope.row.description }}</div>
            <div v-else>
              <el-input type="textarea" v-model="scope.row.description"></el-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="状态"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.deleted === 0">有效</el-tag>
            <el-tag type="danger" v-else>已删除</el-tag>
          </template>
        </el-table-column>

        <el-table-column
            label="操作"
        >
          <template slot-scope="scope">

            <div>
              <el-button @click="commitEditType(scope.row)" v-if="scope.row.state === 1" type="text">确认</el-button>
              <el-button @click="toEditType(scope.row)" v-else type="text">编辑</el-button>
              <el-button @click="toDeleteType(scope.row)" v-if="scope.row.deleted === 0" type="text">删除</el-button>
              <el-button @click="recoverType(scope.row)" v-else type="text">恢复</el-button>
            </div>
          </template>
        </el-table-column>

        <el-empty description="描述文字"></el-empty>
      </el-table>
    </div>
    <el-pagination
        style="padding: 10px;"
        :hide-on-single-page="false"
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="[5,10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="typeList.length">
    </el-pagination>
  </div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "TopicTypeManage",
  mounted() {
    this.getAllTypeByAdmin()
  },
  data() {
    return {
      //分页
      pageSize: 10,
      currentPage: 1,
      typeList: [{id: 1, name: '计算机组成原理', description: '计算机组成原理'}],
    }
  },
  methods: {
    addType() {
      request("post", "/checker/addType").then(res=> {
        if (res && res.code == 200) {
          let last = this.typeList[this.typeList.length - 1];
          this.typeList = [...this.typeList, {id: last.id + 1, name:'未定义', description: '未定义', deleted: 0}]
        }
      })
    },
    async deleteTypeById(id) {
      return new Promise(function (resolve) {

        request("post", "/checker/deleteTypeById", {id: id}).then(res => {
          if (res && res.code == 200) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },

    async updateTypeByID(id, name, description) {
      return new Promise(function (resolve) {

        request("post", "/checker/updateTypeByID", {id: id, name: name, description: description}).then(res => {
          if (res && res.code == 200) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },

    async recoverTypeById(id) {
      return new Promise(function (resolve) {

        request("post", "/checker/recoverTypeById", {id: id}).then(res => {
          if (res && res.code == 200) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    getAllTypeByAdmin() {
      request("get", "/checker/type/getAllType").then(res => {
        if (res && res.code == 200) {
          this.typeList = res.object;
        }
      })
    },
    toEditType(e) {
      e.state = 1;
      this.$forceUpdate()

    },
    async commitEditType(e) {
      let flag = await this.updateTypeByID(e.id, e.name, e.description.replaceAll("\n", ""))
      if (flag) {
        e.state = 0;
        this.$forceUpdate()
      }
    },

    async toDeleteType(e) {
      e.deleted = 1;
      let flag = await this.deleteTypeById(e.id);
      if (flag) {
        this.$forceUpdate()
      }

    },
    async recoverType(e) {
      e.deleted = 0;
      let flag = await this.recoverTypeById(e.id);
      if (flag) {
        this.$forceUpdate()
      }
    },

    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>