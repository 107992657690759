<template>
  <div>

    <div v-for="(momentId, index)  in momentIds" :key="index">
      <moment-single v-bind:momentId="momentId"></moment-single>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";
import MomentSingle from "@/components/single/MomentSingle";
import Utils from "@/util/utils";

export default {
  name: "Moments",
  components: {MomentSingle},
  props: [],
  data() {
    return {
      momentIds: [],
      userId: 0,
      loading: false,
    }
  },
  created() {
    let _this = this;

    Utils.$on("DeleteMoment", function (id) {
      for (let i = 0; i < _this.momentIds.length; i++) {
        if (_this.momentIds[i] == id) {
          _this.momentIds.splice(i, 1);
          break
        }
      }
    });
    Utils.$on("FlushMoment", function () {
      // _this.momentIds = []
      _this.init();
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.userId = window.localStorage.getItem("userId")
      if (typeof this.userId === typeof undefined) {
        return
      }
      this.getMomentIds()
    },
    getMomentIds() {
      this.loading = true;
      this.$parent.loading = true
      request("get", "/moment/getFriendMoment").then(res => {
        if (res && res.code) {
          this.momentIds = res.object;
        }
        this.$parent.loading = false
        this.loading = false
      })
    }
  },
}
</script>

<style scoped>

</style>