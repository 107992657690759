<template>
  <div class="col" style="padding: 0px; height: 100%;">
    <div style="width: 100%; height: 100%; margin: 0px auto; background-color: rgb(245, 245, 245);
                    border-radius: 5px; text-align: center">
      <div style="flex: 1; " id="chatShow">
        <ChatList style="min-width: 250px;"

                  v-bind:to-user-id="toUserId" v-bind:chat-list="chatList"></ChatList>
      </div>
      <div style=" margin-left: 1vw; margin-top: 2px;background-color: rgb(245,245,245);border-top: 1px solid #ccc;">
        <div class="row" style="height: 35px;line-height:35px;width: 99%; ">
          <div class="row" style="width: 50%;">
            <div style="justify-content: flex-start">
              <el-button style="margin:auto 0" type="text">
                <img style="width: 20px;height: 20px;" src="xiao.png">
              </el-button>
              <el-button style="margin:0 15px;" type="text">
                <el-upload
                    :auto-upload="true"
                    :data={toId:toUserId}
                    :headers="headers"
                    action="/api/chat/sendImage/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                  <img style="width: 20px;height: 20px;" src="file.png">


                </el-upload>

              </el-button>
              <el-button style="margin:auto 0" type="text">
                <img style="width: 20px;height: 20px;" src="paste.png">
              </el-button>
              <el-button style="margin:0 15px;" type="text">
                <img style="width: 20px;height: 20px;" src="chat.png">
              </el-button>

            </div>
          </div>
          <div class="row" style="width: 50%; justify-content: flex-end">
            <el-button style="margin:0 0px;" type="text">
              <img style="width: 20px;height: 20px;" src="phone.png">
            </el-button>
            <el-button style="margin:0 10px;" type="text">
              <img style="width: 20px;height: 20px;" src="video.png">
            </el-button>
          </div>
        </div>

        <textarea v-if="!isMobile" @keydown="handleKeyCode"
                  placeholder=""
                  :disabled="disable"
                  v-model="text"
                  style="height: 160px;
                      background-color: rgb(245,245,245);
                      font-size: 16px;
                      margin-top: 4px;
                      resize:none;
                      width: 99%;
                      font-family: inherit;
                      border: none;
                      border-top: 0;
                      border-left: 0;
                      border-right: 0;
                      border-bottom: 0px;
                      outline: none"></textarea>
        <textarea v-else @keydown="handleKeyCode"
                  placeholder=""
                  :disabled="disable"
                  v-model="text"
                  style="height: 10vh;
                      background-color: rgb(245,245,245);
                      font-size: 16px;
                      margin-top: 4px;
                      resize:none;
                      width: 99%;
                      font-family: inherit;
                      border: none;
                      border-top: 0;
                      border-left: 0;
                      border-right: 0;
                      border-bottom: 0px;
                      outline: none"></textarea>

        <div style="text-align: right;  padding-right: 10px;margin-bottom: 10px;">
          <el-button type="info" style="color:rgb(6, 174, 87); height: 30px; line-height: 0px;" plain @click="send">
            发送(S)
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Utils from '@/util/utils.js';
import {request} from "@/util/api";
import ChatList from "@/components/im/ChatList";

export default {
  name: "Im",
  components: {ChatList},
  props: ['toUserId'],
  data() {
    return {
      user: {},
      userId:0,
      isCollapse: false,
      users: [],
      chatUser: '',
      chatUserId: null,
      text: "",
      content: '',
      token: '',
      disable: false,
      chatList: [],
      isMobile: false,
    }
  },
  watch: {
    toUserId: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
        this.chatList =  (await this.getHistory())
      }
    }
  },
  async created() {
    if ( /mobile/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    let _this = this
    Utils.$on('WebSocket_AddMessageFromWeb_ChangeChatList', function (data) {
      if (data ) {
        // _this.chatList.push(data)
        Utils.$emit("chatList_AddChat", data)
        _this.chatList = [data, ..._this.chatList]
      }
    })
    Utils.$on('WebSocket_AddMessageFromLocal_ChangeChatList', function (data) {
      if (data && data.toId == _this.toUserId) {
        // _this.chatList.push(data)
        _this.chatList = [data, ..._this.chatList]
        Utils.$emit("chatList_AddChat", data)
      }
    })
    Utils.$on("chatTextClear", function () {
      _this.text = ''
    })
    this.init()
    this.chatList = await this.getHistory()

  },
  async mounted() {
    this.userId = window.localStorage.getItem("userId")
    if (typeof this.userId === typeof undefined || this.userId === undefined) {
      return
    }
  },
  methods: {
    updateUnreadNums() {
      let _this = this;
      console.log(_this.userId)
      Utils.$emit('AppVue_ChangeUnreadNums', _this.userId, 0, 0);
    },
    async getHistory() {
      // let _this = this;
      // return  await Utils.$on("AppVue_GetHistoryFromWeb", _this.user.id, _this.toUserId)
      let _this = this
      let min = Math.min(this.userId, this.toUserId)
      let max = Math.max(this.userId, this.toUserId)
      let list = []

      Utils.$emit("AppVue_GetChatListFromLocalStorage", _this.userId, _this.toUserId, res=> {
        list = res;
      })
      let startTime = list.length > 0 ? list[list.length - 1] : '1990-02-16 14:36:57'

      let res = await this.getChatsByStartTime(startTime)
      if (res) {
        list = list.concat(res)
      }
      Utils.$emit("AppVue_UpdateChatList", min, max, list)
      return list.reverse()
    },
    async getAllFromSQL() {
      return new Promise(resolve => {
        request("get", "/chat/getAll/" + this.toUserId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            return null
          }
        })
      })
    },
    async getChatsByStartTime(startTime) {
      return new Promise(resolve => {
        request("get", "/chat/getAllByStartTime/" + this.toUserId + "?startTime=" + startTime).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            return null
          }
        })
      })
    },

    genChatKey(min, max) {
      return min + ":" + max + ":chats"
    },
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          this.send();
        } else {
          this.text = this.text + '\n';
        }
      }
    },

    send() {
      if (!this.chatUserId) {
        this.$message({type: 'warning', message: "请选择聊天对象"})
        return;
      }
      if (!this.text || this.text.replaceAll(" ", "").length == 0) {
        this.$message({type: 'warning', message: "请输入内容"})
      } else {
        if (this.text.length > 2000) {
          this.$message.warning("文字长度不能超过2000")
        }
        if (typeof (WebSocket) == "undefined") {

        } else {


          // 组装待发送的消息 json
          // {"from": "zhang", "to": "admin", "text": "聊天文本"}
          let message = {fromId: this.userId, toId: this.chatUserId, text: this.text, gmtCreate:this.getNowDateString(), type: 0}

          Utils.$emit('WebSocket_SendMessage', message)
        }
      }
    },
    insertHistroyToCache(fromId, toId, text) {
      let datetime = this.getNowDateString()
      let min = Math.min(fromId, toId)
      let max = Math.max(fromId, toId)
      let list = []
      let listStr = window.localStorage.getItem(this.genChatKey(min, max))
      if (listStr) {
        list = JSON.parse(listStr)
      }
      list.push({fromId:fromId, toId: toId, text: text, gmtCreate: datetime, gmtUpdate: datetime})
      window.localStorage.setItem(this.genChatKey(min, max), JSON.stringify(list))
    },
    getNowDateString() {
      let res = ""
      let date = new Date()
      let time = date.toTimeString()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      res += year + '-';
      if (month < 10) {
        res += '0'
      }
      res += month + "-"
      if (day < 10)  {
        res += '0'
      }
      res += day + " "
      res += time.substring(0, 8)
      return res

    },
    init() {

      this.initChatUser()
      this.initThisUser()
    },
    initThisUser() {
      let userId = window.localStorage.getItem("userId")
      if (!userId) {
        return;
      }
      this.userId = userId;
      let user = window.sessionStorage.getItem("user_basic:" + userId)
      if (!user) {
        return;
      }
      this.user = JSON.parse(user);
    },
    initChatUser() {
      if (this.toUserId) {
        let user = window.sessionStorage.getItem("user_basic:" + this.toUserId)
        if (user) {
          this.chatUser = JSON.parse(user);
          this.chatUserId = this.chatUser.id;
        }
      }
    }
  },

}
</script>
<style lang="scss" scoped>
.el-button--info.is-plain:focus, .el-button--info.is-plain:hover {
  background: rgb(210, 210, 210);
  border-color: rgb(210, 210, 210);
  color: #FFF;
}
.el-button--info.is-plain {
  background-color: #e9e9e9;
  border-style: none;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
