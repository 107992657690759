<template>
  <div>
    <div style="min-height: 100vh;">
      <div style="width: 100vw; max-width: 1000px;margin: 0px auto;background-color: white;min-height: 100vh">
        <div class="mainEditor">
          <p>写上你的问题吧！</p>
          <el-form>
            <el-form-item label="问题">
              <el-input v-model="title" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <el-form>
            <el-form-item label="标签">
              <div class="row">
                <div  style="">
                  <el-checkbox-group  v-model="choiceTypeNameList" size="medium">
                    <el-checkbox-button style="" v-for="type in typeNameList" :label="type.name" :key="type.id">
                      {{ type.name }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                </div>

              </div>
            </el-form-item>
          </el-form>

          <div class="row" style="width: 100%">
            <div class="row" style="width: 50%;text-align: left;margin-left: 20px;">
              <el-switch
                  v-model="isUrgent"
                  inactive-color="#f6f6f6"
              >
              </el-switch>
              <div style="margin-left: 10px;">
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    content="开启后，有用户回答会通过邮件提醒你">
                  <el-button type="text" style="color: black;margin-top: -10px;" slot="reference">特别提问 <i
                      class="el-icon-warning-outline"></i></el-button>
                </el-popover>

              </div>
            </div>
            <div style="width:50%;text-align: right">
              <el-button @click="addTopic" type="primary" plain>发布<i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </div>

          </div>
          <div id="toolbar-container" class="toolbar"></div>
          <div style="text-align: right">
            <i @click="deleteText" class="el-icon-delete"></i>
          </div>
          <div style="margin-top:5px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);min-height: 400px;text-align: left;"
               id="text-container" class="text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor';
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import {request} from "../util/api";

export default {
  name: "WriteTopic",
  components: {},
  data() {
    return {
      isUrgent: false,
      editor: null,
      title: "",
      typeNameList: [{name: "os"}],
      choiceTypeNameList: [],
    };
  },
  methods: {
    getTopicTypeList() {
      var types = window.localStorage.getItem("topic_types");
      if (types && types != null) {
        this.typeNameList = JSON.parse(types)
        return
      }
      request("get", "/type/getAllTypes").then(res => {
        if (res && res.code == 200) {
          this.typeNameList = res.object;
          window.localStorage.setItem("topic_types", JSON.stringify(this.typeNameList))
        }
      })
    },
    addTopic() {
      if (this.title.length == 0) {
        this.$message('标题不能为空！')
        return;
      }
      // if (this.title.indexOf("?") == -1 && this.title.indexOf("？") == -1) {
      //   this.$message('标题应该带有问号"?"')
      //   return;
      // }
      let html = this.getHtml();
      // if (html.length==0){
      //     this.$message('文章不能为空！');
      //     return;
      // }
      request("post", "/topic/add", {
        context: html,
        title: this.title,
        isUrgent: this.isUrgent,
        typeNameList: this.choiceTypeNameList
      }).then(res => {
        if (res && res.code == 200) {
          this.$router.replace("/index")
        } else {
          this.$message.error("发布失败");
        }
      })

    },
    getHtml() {
      return this.editor.txt.html();
    },
    deleteText() {
      this.editor.txt.clear();
    },
    initEditor() {

      this.editor = new E('#toolbar-container', '#text-container') // 传入两个元素
      // this.editor.height=200;
      this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        resultFiles.forEach((item) => {
          let fd = new FormData();

          fd.append('imgFile', item);

          request("post", "/uploadImg", fd).then(res => {
            let imgUrl = res.object.fileUrl;
            //插入图片到前端
            insertImgFn("/" + imgUrl)
          })
        })

      }
      this.editor.config.uploadImgMaxLength = 2;
      this.editor.config.pasteIgnoreImg = true
      this.editor.zIndex.baseZIndex = 0 //方法
      this.editor.config.menus = [
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        'quote',  // 引用
        'image',  // 插入图片
        'video',  // 插入视频
        'code',  // 插入代码
        'undo',  // 撤销
        'redo'  // 重复
      ]


      this.editor.config.languageType = [
        'Bash',
        'C',
        'C#',
        'C++',
        'CSS',
        'Java',
        'JavaScript',
        'JSON',
        'TypeScript',
        'Plain text',
        'Html',
        'XML',
        'SQL',
        'Go',
        'Kotlin',
        'Lua',
        'Markdown',
        'PHP',
        'Python',
        'Shell Session',
        'Ruby',
      ]
      this.editor.config.languageTab = '    '
      this.editor.config.uploadFileName = 'imgFile'
      // this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
      //     // resultFiles 是 input 中选中的文件列表
      //     // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
      //
      //     // 上传视频，返回结果，将视频地址插入到编辑器中
      //     insertVideoFn(videoUrl)
      // }
      // this.editor.width=200;
      this.editor.config.height = 500
      this.editor.highlight = hljs
      this.editor.config.justify = 'left'
      this.editor.create()
    }
  },
  mounted() {
    if (window.localStorage.getItem('tokenStr')) {

      this.initEditor()

      this.getTopicTypeList()
      this.timeBtn = setInterval(() => {
        if (this.editor.txt.text().length > 3000) {
          this.deleteText();
          this.$message("文本过长！")
        }
      }, 1000)
    } else {
      this.$router.replace("login")
      this.$message("请先登录！")
    }
  },
  beforeDestroy() {
    clearInterval(this.timeBtn)
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }
}

</script>

<style lang="scss" scoped>
.mainEditor {
  padding: 20px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);*/
  border-radius: 10px;
}

#toolbar-container{
  z-index: 0 ;
}
::v-deep.el-checkbox-button {
  .el-checkbox-button__inner {
    border-left: 1px solid #DCDFE6 !important;
    border-radius: 5px;
    margin-left: 5px;
  }
}

::v-deep.el-checkbox-button.is-checked .el-checkbox-button__inner {
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
  box-shadow: 0px 0 0 0 #8cc5ff;
}
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
