<template>
    <div>
        <div style="text-align: left">关注的话题({{foucusList.length}})</div>
<!--        <el-divider></el-divider>-->
        <div v-for="(item,index) in foucusList">

            <div style="flex-direction: column;display:flex;padding: 10px;">
                <div  style="text-align:left;padding: 0px;max-width: 700px;color: black;font-weight: 800;font-size: 18px;" type="text" >
                    <p @click="toAnswer(item.topicId,false)" style="cursor: pointer">
                        {{item.title}}
                    </p>
                </div>

                <div style="display: flex;flex-direction: row;color: #8590a6;line-height: 10px;">
                    <div style="">
                        {{item.gmtCreate}}
                    </div>
                    <div style="margin: 0 5px;">·</div>
                    <div style="">
                        {{item.answerLength}}个回答
                    </div>
                    <div style="margin: 0 5px;">·</div>
                    <div style="">
                        {{item.topicFocusLength}}个关注
                    </div>
                </div>
                <!--                <div style="text-align: right;right: 0px">-->
                <!--                    <div style="padding: 10px">-->
                <!--                        <el-button v-if="item.focusStatus===false" @click="addFocus" type="primary" size="small" >关注+</el-button>-->
                <!--                        <el-button v-if="item.focusStatus===true" @click="addFocus" type="primary" size="small" >取消关注</el-button>-->
                <!--                    </div>-->

                <!--                </div>-->
            </div>
            <el-divider></el-divider>
        </div>
        <div v-if="foucusList.length==0">
            <el-empty :image-size="200"> o(╥﹏╥)o还没有关注的话题</el-empty>

        </div>
    </div>
</template>

<script>
    import {request} from "../../util/api";

    export default {
        name: "PeopleFocusTopic",
        data() {
            return {
                userId: 0,
                foucusList: [],

            }
        },
        methods: {
            toAnswer(topicId, isAnswer) {
                this.$router.push({
                    path: '/answer',
                    query: {
                        isAnswer: isAnswer,
                        topicId: topicId,
                    }
                });

            },
        },
        created() {
            if (window.localStorage.getItem('tokenStr')) {
                this.userId = window.sessionStorage.getItem("peopleUserId")
                request("post", "/focusTopic/getByUserId", {"userId": this.userId}).then(res => {
                    if (res && res.object)
                    this.foucusList = res.object.reverse();
                })
            }
        }
    }
</script>

<style scoped>

</style>
