<template>
  <div class="col" style="background-color: white; width:100%;height: fit-content; ">
    <div
        style="text-align: left; margin-left: 10px; color: #606266;font-weight: bolder; height:40px;line-height:40px;background-color: white">
      {{ commentList.length }}条评论
    </div>
    <div style="display: block;height: 1px;width: 100%;margin: 5px 0;background-color: #DCDFE6;"></div>
    <!--    <el-divider></el-divider>-->
    <div class="topnav_box" style="width: 100%;  flex:1;background-color: white; overflow-y: auto; overflow-x: hidden ">

      <div style="" v-if="commentList.length===0">
        <div style="padding: 10px; text-align: center; width: 100%; height: 50px;line-height: 50px;">
          ┭┮﹏┭┮没人说啥，快来评论吧！
        </div>
      </div>

      <div style="text-align: left;position:relative; right: 20px; margin-left: 20px;padding: 10px; width: 100%;"
           v-for="(comment,index1) in commentList">
        <div style="text-align: left">
          <div class="col">
            <div style="min-height: 80px; position: relative; right: 15px; margin-left: 15px; " class="row">
              <UserAvatarV1 style="display:flex;float: top; width: 30px;height: 30px;"
                            v-bind:userId="comment.fromId" v-bind:size="30"
                            v-bind:shape="square"></UserAvatarV1>
              <div
                  style="display: flex;flex-direction: column;margin-left: 10px;flex: 1;line-height: 30px;word-break: break-word;">

                <div style="height: 100%;" class="row">
                  <UserNameV2 style="width: fit-content;" v-bind:user-id="comment.fromId"></UserNameV2>

                  <div style="justify-content: flex-start;">
                    <div class="row" style="line-height: 30px;margin-left: 4px;" v-if="comment.toId!=null">

                      <el-icon style="line-height: 30px;" class="el-icon-caret-right"></el-icon>
                      <UserAvatarV1 style="margin-left: 2px;" v-bind:size="30"
                                    v-bind:userId="comment.toId"></UserAvatarV1>
                      <UserName style="line-height: 30px;height: 30px;" v-bind:userId="comment.toId"></UserName>
                    </div>
                  </div>

                </div>
                <div style="margin-left: 5px;word-wrap: break-word;">
                  {{ comment.context }}
                </div>
                <div class="row" style="height: 20px;padding: 2px; width: 100%; ">
                  <div style="line-height: 20px; width:70%;color: #8c939d">
                    <div style="text-align: left;">
                      {{ func(comment.gmtCreate) }}
                    </div>
                  </div>
                  <div class="row" style="width: 30%; margin-right: 5px; justify-content: flex-end; ">
                    <!--                    <div style="width: 80% ;"></div>-->
                    <div class="row" style="width: 100%;justify-content: flex-end;">
                      <Zan v-bind:user-id="userId" v-bind:type="2" v-bind:type-id="comment.id"></Zan>

                      <div v-if="comment.fromId != userId">
                        <!--                                                评论模块-->
                        <el-button
                            @click="toReply(index1)" type="text"
                            style="width: 20px;height: 20px;margin-left: 5px;">
                          <el-image style="bottom: 8px;" src="comment.png"></el-image>
                        </el-button>
                      </div>
                      <div v-else>
                        <!--删除模块-->
                        <el-button @click="toDeleteComment(index1)"
                                   type="text"
                                   style="width: 20px;height: 20px;margin-left: 5px;">

                          <el-image style="bottom: 8px;" src="delete.png"></el-image>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div style="display: block;height: 1px;width: 100%;margin: 5px 0;background-color: #DCDFE6;"></div>
    <div style="height: 50px;padding:10px 10px 10px 10px;position:relative; background-color: white; flex: 1;">
      <div class="row" style="width: 100%;">
        <UserAvatarV1 v-bind:userId="userId"></UserAvatarV1>
        <div style="flex:1;margin-left:5px;background-color: white">
          <el-input @keyup.enter.native="addComment()" v-model="commentText"
                    :placeholder="commentTips"></el-input>
        </div>
        <div v-if="commentPattern==1" style="margin-left: 5px;">
          <el-button @click="toComment" style="color: #8c939d" type="text"><i
              class="el-icon-error"></i></el-button>
        </div>
        <el-button @click="addComment()" style="margin-left: 10px; height: 38px;" size="" type="primary" plain>发布</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";
import func from "@/util/getDateTime";
import UserName from "@/components/UserInfo/UserName";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";
import UserNameV2 from "@/components/UserInfo/userNameV2";
import Zan from "@/components/Icon/Zan";

export default {
  name: "Comment",
  components: {Zan, UserNameV2, UserName, UserAvatarV1},
  props: ['type', 'typeId', 'userId'],
  data() {
    return {
      commentList: [],
      zanList: [],
      commentPattern: 0,
      commentText: "",
      commentTips: "请输入评论内容",
      commentIndex: 0,
      selectAnswerId: 0,

    }
  },
  watch: {
    typeId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.typeId = newVal;
        this.getCommentByAnswerId(newVal)
      }
    }
  },
  mounted() {
    if (this.typeId) {
      this.getUserId()
      this.getComment(this.typeId, this.type)
    }
  },
  methods: {
    func(time) {
      return func(time)
    },
    getUserId() {
      this.userId = window.localStorage.getItem("userId")
      if (this.userId == null || typeof this.userId === undefined) {
        this.userId = 0
      }
    },
    toDeleteComment(index) {
      this.$confirm('确认删除该评论吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id = this.commentList[index].id;
        request("post", "/comment/delete", {id: id}).then(res => {
          if (res.code == 200) {
            this.commentList.splice(index, 1);
            this.$parent.comment.length--;
          }
        })

      }).catch(() => {

      });


    },
    toComment() {
      this.commentPattern = 0;
      this.comment = "";
      this.commentTips = "请输入评论内容";
    },
    getUser(userId) {
      let user = window.sessionStorage.getItem("user_basic:" + userId);
      return JSON.parse(user);
    },
    toReply(index) {
      if (this.commentList[index].fromId != window.localStorage.getItem("userId")) {
        this.commentPattern = 1;
        // TODO
        this.commentTips = "回复 " + this.getUser(this.commentList[index].fromId).nickname;
        this.commentIndex = index;
      }

    },

    addCommentZan(index) {
      request("post", "/zan/add", {typeId: this.commentList[index].id, type: this.type}).then(res => {
        if (res && res.code == 200) {
          this.zanList[index].isFromUser = !this.zanList[index].isFromUser;

          let count = this.zanList[index].isFromUser == 1 ? 1 : -1
          this.zanList[index].length += count;
        }
      })

      // this.openCommentDialog(index,this.selectAnswerId)
    },
    getComment(typeId, type) {
      let _this = this
      request("get", "/comment/getByTypeId?typeId=" + typeId + "&type=" + type).then(res => {
        if (res && res.code == 200) {
          this.commentList = res.object;
          _this.$parent.comment.length = this.commentList.length
        }
      })
    },

    addComment() {
      if (this.commentText.length <= 0) {
        this.$message.error("评论不能为空")
        return;
      }

      let toId = null;
      if (this.commentPattern == 1) {
        toId = this.commentList[this.commentIndex].fromId;
      }
      request("post", "/comment/add", {
        context: this.commentText,
        toId: toId,
        typeId: this.typeId,
        type: this.type
      }).then(res => {
        if (res.code == 200) {
          this.commentText = "";
          this.$message.success("评论成功");
          this.getComment(this.typeId, this.type)
        }
      })


    },
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.topnav_box::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  /*:overflow-y:;*/
  background-color: rgb(245, 245, 245);

}

.topnav_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  border-radius: 10px;
  background-color: rgb(245, 245, 245);

}


.topnav_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  background-color: #d2d2d2;
}
</style>