<template>
  <div style="height: 100vh;overflow-x: hidden; overflow-y: hidden; background-color: rgb(237, 237, 237);">
    <div class="col"
         style="height: 100%;  width: 100%;display: flex;flex-direction: column;justify-content: space-between">
      <div class="row" style="width: 100%; background-color: #ededed;">
        <div style="width: 20%;"></div>
        <div class="col"
             style="width: 60%; height: 50px; text-align: center; justify-content: center; border-bottom: 1px solid #ccc; ">
          {{ mode === 0 || mode === 2 ? '微信' : '通讯录' }}
        </div>
        <div class="col" style="width: 20%; text-align: center; justify-content: center;">
          <div class="row" style="justify-content: flex-end; margin-right: 10px;">
            <img @click="closeWX" class="" src="moment-error.png" style="width: 20px;height: 20px;cursor: pointer;">
          </div>
        </div>
      </div>

      <div class="border-radius" style="flex: 1;overflow-y: auto;border-radius: 10px">
        <div class="" style="border-radius: 10px">
          <div v-if="mode === 0" class="topnav_box auto_scroll">
            <div
                style="height: fit-content; text-align: center; width: 100%;background-color: #cc5353; color: white; padding: 4px; font-size: 14px;"
                v-if="socketDisable">{{ tips }}
            </div>
            <div v-for="(box) in chatUserBoxs" style="width: 100%; ">
              <div @click="changeChatUserId(box.userId)" class="row box"
                   style="width: 100%;">
                <div>
                  <el-badge :hidden="box.unreadNums == 0" :value="box.unreadNums ? box.unreadNums : 0">
                    <UserAvatarV2 v-bind:user-id="box.userId"></UserAvatarV2>
                  </el-badge>
                </div>
                <div class="col" style="margin-left: 3vw;flex: 1; height: 100%; overflow: hidden">
                  <user-name-v2 v-bind:user-id="box.userId"
                                style="height: 50%; overflow: hidden; font-size: 14px;"></user-name-v2>
                  <div
                      style="height: 50%; font-size: 11px;color: #999999;line-height: 30px;">{{
                      box.type == 0 ? box.text : box.type == '1' ? '[问题]' : box.type == 2 ? '回答' : box.type == 3 ? '[图片]' : ''
                    }}
                  </div>
                </div>
                <div
                    style=" justify-content: flex-end;  overflow: hidden; height: 16px; width: fit-content; font-size: 10px;color: #999999; ">
                  {{ lastChatTimeFunc(box.gmtCreate) }}
                </div>
                <div style="width: 20px;"></div>
              </div>
            </div>

          </div>

          <div v-else-if="mode === 1">
            <div class="col"
                 style=" border-left: 1px solid #ccc;">

              <div class="topnav_box auto_scroll"
                   style=" text-align: left; width: 100%; background-color: #eae8e7; overflow: auto; overflow-x: hidden;  ">

                <div v-for="(friendId) in friends" style="">
                  <div @click="changeChatUserId(friendId)" class="row friend_box"
                       style="">
                    <div>
                      <UserAvatarV2 v-bind:user-id="friendId"></UserAvatarV2>
                    </div>
                    <div class="col" style="margin: auto 10px; flex: 1; height: 100%;line-height: 100%; ">

                      <user-name-v2 v-bind:user-id="friendId"
                                    style="height: 100%; line-height: 40px;  overflow: hidden; font-size: 14px;"></user-name-v2>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div v-if="isShowChatWindow">
            <transition name="slide">
              <keep-alive>

                <WXChatWindowPhone style="z-index: 21; overflow-x: hidden;" v-bind:to-user-id="chatUserId"
                                   :key="chatUserId"></WXChatWindowPhone>
              </keep-alive>
            </transition>
          </div>

        </div>
      </div>

      <div class="col" style=" height: 50px; width: 100%; background-color: #f6f6f6;">
        <div class="row" style="height: 100%;">
          <div class="row" style="width: 33%; justify-content: center;height: 100%;">
            <div class="col" style="justify-content: center; ">
              <!--                   消息按钮-->
              <el-button @click="mode = 0" style=" " type="text">
                <el-badge :hidden="totalUnread() == 0" :value="totalUnread()">
                  <div v-if="mode===0">
                    <div style="width: 25px; height: 25px;">

                      <img style="width: 20px;height: 20px;" src="chatGreen.png">
                    </div>
                    <div style="color: #07c160; font-size: 6px;">微信</div>
                  </div>
                  <div v-else>
                    <img style="width: 20px;height: 20px;" src="chat0.png">
                    <div style="color: #929292; font-size: 6px;">微信</div>
                  </div>
                </el-badge>
              </el-button>
            </div>
          </div>
          <div class="row" style="width: 34%; height: 100%; justify-content: center;">
            <div class="col" style="justify-content: center; ">
              <el-button @click="mode = 1" style="margin-left: 0px;" type="text">
                <div v-if="mode !== 1">

                  <img style="width: 25px;height: 25px;" src="contact0.png">
                  <div style="color: #929292; font-size: 6px;">通讯录</div>

                </div>
                <div v-else>
                  <img style="width: 25px;height: 25px;" src="contact1.png">
                  <div style="color: #07c160; font-size: 6px;">通讯录</div>
                </div>
              </el-button>
            </div>
          </div>
          <div class="row" style="width: 33%; justify-content: center;">
            <div class="col" style="justify-content: center;">

              <el-button @click="openMoment" style="margin-left: 0px;" type="text">
                <img style="width: 25px;height: 25px;" src="moment.png">
                <div style="color: #929292; font-size: 6px;">圈子</div>
              </el-button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Utils from "@/util/utils";
import {request} from "@/util/api";
import UserNameV2 from "@/components/UserInfo/userNameV2";
import UserAvatarV2 from "@/components/UserInfo/UserAvatarV2";
import WXChatWindowPhone from "@/views/WX-Chat-Window-Phone";

export default {
  name: "WX-Phone",
  components: {WXChatWindowPhone, UserAvatarV2, UserNameV2,},
  computed: {},
  data() {
    return {
      unReadMessageNumber: 0,
      chatUserList: [],
      chatUserId: null,
      chatLastMessage: [],
      choiceId: 0,
      userId: 0,
      user: {},
      chatUserBoxs: [],
      socketDisable: false,
      tips: '网络错误',
      friends: [],
      choiceFriendId: 0,
      mode: 0,
      isShowChatWindow: false,
    }
  },
  created() {
    if (/mobile/i.test(navigator.userAgent)) {

    } else {
      this.$router.push("webChat")
      return;
    }
    let _this = this
    this.userId = localStorage.getItem("userId")
    if (!this.userId) {
      this.$router.push("/index")
      this.$message.error("请先登录")
      return;
    }
    this.user = window.sessionStorage.getItem("user_basic:" + this.userId)

    if (!this.user) {
      return
    }
    this.choiceFriendId = this.userId
    Utils.$on('On_Chats_AddMessage', function (number) {
      _this.unReadedMessageNumber += number
    })
    Utils.$on("App_Websocket_Close", function () {
      _this.socketDisable = true
      _this.tips = "请检查网络"
    })
    Utils.$on("App_Websocket_Error", function () {
      _this.socketDisable = true
      _this.tips = "请刷新重试"
    })
    Utils.$on("App_Websocket_Open", function () {
      _this.socketDisable = false
      _this.tips = ""
      _this.$forceUpdate()
    })
    Utils.$on("AppVue_ChangeUnreadNums", function (toId, type, nums) {

      for (let i = 0; i < _this.chatUserBoxs.length; i++) {
        if (_this.chatUserBoxs[i].userId == toId) {
          if (type == 0) {
            _this.chatUserBoxs[i].unreadNums = nums;
          } else {
            _this.chatUserBoxs[i].unreadNums += nums;
          }
          break;
        }
      }
      _this.$forceUpdate()
    })


    Utils.$on('On_Chats_AddMessage_ChangeLastMessage', function (data) {
      let i;
      console.log(123123, data);
      let t = null;
      for (i = 0; i < _this.chatUserBoxs.length; i++) {
        if (_this.chatUserBoxs[i].fromId == data.fromId && _this.chatUserBoxs[i].toId == data.toId ||
            _this.chatUserBoxs[i].fromId == data.toId && _this.chatUserBoxs[i].toId == data.fromId
        ) {
          let nums = 0;
          if (_this.chatUserBoxs[i].unreadNums) {
            nums = _this.chatUserBoxs[i].unreadNums
          }

          _this.chatUserBoxs[i] = {
            fromId: data.fromId,
            toId: data.toId,
            text: data.text,
            unreadNums: nums,
            userId: data.userId,
            gmtCreate: data.gmtCreate,
            type: data.type
          }
          window.localStorage.setItem(_this.genLastChatKey(data.fromId, data.toId), JSON.stringify(data))
          t = _this.chatUserBoxs[i]
          break;
        }
      }
      if (t === null) {
        t = {
          fromId: data.fromId,
          toId: data.toId,
          text: data.text,
          unreadNums: 1,
          userId: data.userId,
          gmtCreate: data.gmtCreate,
          type: data.type
        }
        _this.chatUserBoxs = [t, ..._this.chatUserBoxs]
      } else {

        for (let j = i; j >= 1; j--) {
          _this.chatUserBoxs[j] = _this.chatUserBoxs[j - 1]
        }

        _this.chatUserBoxs[0] = t
      }

      _this.$forceUpdate()
      return;
    })
  },
  async mounted() {
    if (window.localStorage.getItem("userId") && window.localStorage.getItem("tokenStr")) {
      this.getChatBoxs()
      this.getFriends()
    }

  },
  methods: {
    getChatBoxs() {
      request("get", "/chat/getChatUserBoxs").then(res => {
        if (res && res.code == 200) {
          this.chatUserBoxs = res.object;
          this.totalUnread()
        }
      })
    },
    closeWX() {
      this.$router.go(-1)
    },
    toSendMessage() {
      this.chatUserId = this.choiceFriendId;
      this.mode = 0;
    },
    getFriends() {
      request("get", "/focus/getFriends").then(res => {
        if (res && res.code == 200) {
          this.friends = res.object;
        }
      })
    },
    openMoment() {
      Utils.$emit("AppVue_ShowMoment")
      // this.$parent.$parent.showMoment = true;
    },

    arrowMove(e) {
      // 元素大小
      let elW = e.currentTarget.offsetWidth
      let elH = e.currentTarget.offsetHeight
      // 元素位置
      let elL = e.currentTarget.offsetLeft
      let elT = e.currentTarget.offsetTop
      // 鼠标位置
      let x = e.clientX
      let y = e.clientY
      // 窗口大小
      let w = window.innerWidth
      let h = window.innerHeight
      // 鼠标到元素左边距离
      let moveX = x - elL
      let moveY = y - elT
      let el = e.currentTarget
      document.onmousemove = function (e) {
        el.style.right = w - (e.clientX - moveX) - elW + 'px'
        el.style.bottom = h - (e.clientY - moveY) - elH + 'px'
      }
      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    totalUnread() {
      let res = 0;
      for (let i = 0; i < this.chatUserBoxs.length; i++) {
        res += this.chatUserBoxs[i].unreadNums;
      }
      this.$parent.$parent.unReadMessageNumber = res
      window.localStorage.setItem(this.userId + ":unreadMessageNumber", res)
      return res;
    },

    lastChatTimeFunc(timeStr) {
      if (!timeStr) {
        return ''
      }
      let p = Date.parse(timeStr)
      let pd = new Date(p)
      let nd = new Date()
      let n = nd.valueOf()

      if (nd.getFullYear() !== pd.getFullYear()) {

        return pd.getFullYear() % 100 + '/' + (pd.getMonth() + 1) + '/' + pd.getDate();
      }

      if ((n - p) / 1000 < 60) {
        return '刚刚'
      }

      if ((n - p) / 1000 / 60 < 60) {
        return Math.ceil((n - p) / 1000 / 60) + '分钟前'
      }


      let pYear = pd.getFullYear()
      let nYear = nd.getFullYear()
      let ptowMonthDay = ((pYear % 4 === 0) && (pYear % 100 !== 0) || pYear % 400 === 0) ? 29 : 30
      let ntowMonthDay = ((nYear % 4 === 0) && (nYear % 100 !== 0) || nYear % 400 === 0) ? 29 : 30
      let pMonthDays = [0, 31, ptowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      let nMonthDays = [0, 31, ntowMonthDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      for (let i = 1; i < 12; i++) {
        pMonthDays[i] += pMonthDays[i - 1]
        nMonthDays[i] += nMonthDays[i - 1]
      }

      let pMonth = pd.getMonth() + 1;
      let nMonth = nd.getMonth() + 1;
      // let startTime = '2023-02-16 14:36:57'
      let pDays = pd.getDate() + pMonthDays[pMonth]
      let nDays = nd.getDate() + nMonthDays[nMonth]

      if (pDays + 1 === nDays) {
        return "昨天 "
      }
      if (pDays + 2 === nDays) {
        return "前天 "
      }

      if ((n - p) / 1000 / 60 / 60 < 24) {
        return Math.ceil((n - p) / 1000 / 60 / 60) + '小时前'
      }

      return pd.getFullYear() % 100 + '/' + (pd.getMonth() + 1) + '/' + pd.getDate();
    },
    async getAllLastChat() {
      let _this = this;
      for (let i = 0; i < this.chatUserList.length; i++) {

        let last = await this.getLastUnreadAndLastChatText(this.chatUserList[i])
        let obj = {
          fromId: last.fromId,
          toId: last.toId,
          text: last.text,
          userId: last.userId,
          unreadNums: last.nums,
          gmtCreate: last.gmtCreate
        }
        this.chatUserBoxs.push(obj)

      }

      this.chatUserBoxs.sort((a, b) => {
        if (a === '' || b === '') {
          return -1;
        }
        if (a.gmtCreate > b.gmtCreate) {
          return -1;
        } else {
          return 1;
        }
      })
      this.chatUserId = this.chatUserBoxs[0].userId
    },
    async getLastUnreadAndLastChatText(userId) {
      return new Promise(resolve => {
        request("get", "/getLastUnreadAndLastChatText?toId=" + userId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          } else {
            resolve({})
          }
        })
      })
    },

    genLastChatKey(left, right) {
      let min = Math.min(left, right)
      let max = Math.max(left, right)
      return "last_chat:" + min + ":" + max
    },
    changeChatUserId(userId) {

      for (let i = 0; i < this.chatUserBoxs.length; i++) {
        if (this.chatUserBoxs[i].userId === userId) {
          this.$parent.unReadMessageNumber -= this.chatUserBoxs[i].unreadNums;
          this.chatUserBoxs[i].unreadNums = 0;
          break
        }
      }
      this.chatUserId = userId;
      Utils.$emit("AppVue_updateLastReadTime", userId)
      this.isShowChatWindow = true;

    },
    async getChatList() {
      return new Promise(function (resolve) {
        request("get", "/chat/getChatUserIds").then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          }
        })
      })

    },

  }
}
</script>


<style lang="scss" scoped>

.auto_scroll {
  //height: calc(100vh - 100px);
  height: 100%;
  width: 100%;
  background-color: #eae8e7;
  overflow-x: hidden;
  border-radius: 10px;
}

.el-button--info.is-plain:focus, .el-button--info.is-plain:hover {
  background: #38cd7f;
  border-color: #38cd7f;
  color: #FFF;
}

.el-button--info.is-plain {
  background-color: #07c160;
  border-style: none;
}

.box {
  height: 40px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  text-align: left;
}

.box:active {
  background-color: #cececf;
}

.moment-error:hover {
  background-color: #fb7373;
}

.moment-flush:hover {
  background-color: #d5d5d5;
}


.friend_box {
  height: 40px;
  border-bottom: 1px solid #ccc;
  background-color: #FFFFFF;
  padding: 12px;
  text-align: left;
}

.friend_box:active {
  background-color: #cececf;
}


.topnav_box::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  /*:overflow-y:;*/
  background-color: rgb(245, 245, 245);

}

.topnav_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  border-radius: 10px;
  background-color: rgb(245, 245, 245);

}


.topnav_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(245, 245, 245);
  background-color: #d2d2d2;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.slide-enter-active,
.slide-leave-active,
.slide-leave-to-active {
  transition: all 0.3s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-from {
  transform: translateX(100%);
}

.slide-leave {
  transform: translateX(-100%);
}
</style>