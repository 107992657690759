<template>
  <div style="min-height: 1500px; width: 100vw; max-width:100%; overflow-x: auto; background-color: white">
    <div style="margin: 0px auto;background-color: white; ">


      <div style="border-radius: 0px;min-height: 1000px; ">
        <div class="row" style="padding: 10px;">

          <el-radio-group @input="changeType" v-model="checkType">
            <el-radio-button label="审核模式"></el-radio-button>
            <el-radio-button label="管理模式"></el-radio-button>
          </el-radio-group>
        </div>
        <div style="  margin: 0 auto">
          <el-table
              :data="authList.slice((currentPage-1)*pageSize,pageSize*currentPage)"
          >
            <el-table-column
                fixed
                prop="id"
                label="ID"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="uid"
                label="用户ID"
                width="">
              <template slot-scope="scope">
                <user-avatar v-bind:userId="scope.row.uid"></user-avatar>
              </template>
            </el-table-column>
            <el-table-column
                fixed
                prop="uid"
                label="用户"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="first"
                label="学校"
                width="">
            </el-table-column>

            <el-table-column
                prop="second"
                label="专业/班级"
                width="">
            </el-table-column>
            <el-table-column
                prop="notes"
                label="备注"
                width="">
            </el-table-column>
            <el-table-column
                prop="imgs"
                label="审核图片"
                width=""
            >
              <template slot-scope="scope">
                <div style="width: 180px; flex-direction: row; display: flex; flex-wrap: wrap;">
                  <div v-for="url in scope.row.imgs">
                    <div class="col" style="height: 50px;">
                      <el-image
                          style="width: 50px; height: 50px;border-radius: 10px; margin-right: 10px;"
                          :preview-src-list="scope.row.imgs"
                          class="el-upload-list__item-thumbnail"
                          :src="url" alt=""
                          fit="cover"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
                prop="valid"
                label="审核状态"
                width="">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 1">
                  <el-tag>
                    审核通过
                  </el-tag>
                </div>
                <div v-else-if="scope.row.status == 0">
                  <el-tag type="warning">
                    审核中
                  </el-tag>
                </div>
                <div v-else>
                  <el-tag type="danger">
                    不通过
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="valid"
                label="操作"
                fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                    @click="deleteVerifyById(scope.$index, scope.row.id)"
                    type="text"
                    size="small">
                  删除
                </el-button>
                <el-button
                    v-if="scope.row.status != 1"
                    @click="passVerifyById(scope.$index, scope.row.id)"
                    type="text"
                    size="small">
                  通过
                </el-button>

                <el-button
                    v-if="scope.row.status != 0"
                    @click="resetVerifyById(scope.$index, scope.row.id)"
                    type="text"
                    size="small">
                  待定
                </el-button>
                <el-button
                    v-if="scope.row.status != -1"
                    @click="unPassVerifyById(scope.$index, scope.row.id)"
                    type="text"
                    size="small">
                  不通过
                </el-button>

              </template>
            </el-table-column>

            <el-empty description="描述文字"></el-empty>

          </el-table>
          <el-pagination
              style="padding: 10px;"
              :hide-on-single-page="false"
              @size-change="sizeChange"
              @current-change="currentChange"
              :current-page="currentPage"
              :page-sizes="[5,10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="authList.length">
          </el-pagination>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import {request} from "@/util/api";
import UserAvatar from "@/components/UserInfo/UserAvatar";

export default {
  name: "AdminVerify",
  components: {UserAvatar},
  data() {
    return {
      authList: [],
      //分页
      pageSize: 10,
      currentPage: 1,
      canSendMessage: true,
      checkType: '审核模式'
    }
  },
  mounted() {
    this.getVerify()
  },
  methods: {
    changeType(type) {
      if (type == '审核模式') {
        this.getVerify()
      } else {
        this.getAllVerify()
      }
    },
    currentChange(e) {
      this.currentPage = e;
    },
    sizeChange(e) {
      this.pageSize = e;
    },
    recoveryPage(e) {
      return (this.currentPage - 1) * this.pageSize + e
    },
    passVerifyById(index, id) {
      request("post", "/checker/verify/pass", {id: id}).then(res => {
        if (res && res.code == 200) {
          this.authList[this.recoveryPage(index)].status = 1
        }
      })
    },
    resetVerifyById(index, id) {
      request("post", "/checker/verify/reset", {id: id}).then(res => {
        if (res && res.code == 200) {
          this.authList[this.recoveryPage(index)].status = 0
        }
      })
    },
    unPassVerifyById(index, id) {
      request("post", "/checker/verify/unPass", {id: id}).then(res => {
        if (res && res.code == 200) {
          this.authList[this.recoveryPage(index)].status = -1
        }
      })
    },
    deleteVerifyById(index, id) {
      this.$confirm('确认删除该审核吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/checker/verify/delete", {id: id}).then(res => {
          if (res && res.code == 200) {
            this.authList.splice(this.recoveryPage(index), 1);
          }
        })

      }).catch(() => {

      });

    },
    getVerify() {
      request("get", "/checker/verify/get"
      ).then(res => {
        if (res && res.code == 200) {
          this.authList = res.object
        } else {
          this.$message.warning("获取审核信息失败")
        }
      })
    },
    getAllVerify() {
      request("get", "/checker/verify/getAll").then(res => {
        if (res && res.code == 200 && res.object) {
          this.authList = res.object
        } else {
          this.$message.warning("获取审核信息失败")
        }
      })
    }
  },

}
</script>

<style scoped>

.maincontext {
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
}

table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}

table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}

pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 4px;
  background: #ebebeb;
}

.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
}

.my_devider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  background: #ebebeb;
}
</style>