<template>
  <div @click="isShowShareBox = true" style="width: 100%; height: 100%;line-height: 100%;">
    <!--    <el-button style="width: 100%; height: 100%;" @click="isShowShareBox = true;" type="text">-->
    <!--      <div style="width: 100%; height: 100%;">-->
    <el-image
        style="width: 20px; height:20px; cursor: pointer;" src="share.png">
    </el-image>
    <!--      </div>-->
    <!--    </el-button>-->

    <el-dialog title="分享给朋友"
               style="width: 100vw;
                overflow: hidden;
                z-index: 20000;
               margin: 0 auto; max-width: 600px;  "
               append-to-body
               width="100%"
               :visible.sync="isShowShareBox">
      <div style="overflow-y: auto;height: 50vh; max-height: 600px; ">
        <div style="margin-top: 10px;" class="col" v-for="(id,i) in friendIds">

          <div style="" class="row">
            <UserAvatarV1 v-bind:size="45" v-bind:user-id="id"></UserAvatarV1>
            <UserNameV2 style="margin-left: 10px; flex: 1;line-height: 45px;" v-bind:user-id="id"></UserNameV2>
            <div style="margin-left: 10px; width: 50px; height: 45px; line-height: 45px;">

              <el-button @click="share(id)" v-if="isShare[i] === false" type="text"><img
                  style="width: 25px; height: 25px;" src="share.png"/></el-button>
              <div v-else>已分享</div>
            </div>
          </div>
        </div>
        <div class="col" style="width: 100%; height: 100%;justify-content: center;text-align: center;font-size: 20px;"
             v-if="friendIds.length === 0">

          要互相关注的朋友才可以分享喔~~
        </div>
      </div>


    </el-dialog>


  </div>
</template>

<script>
import Utils from "@/util/utils";
import UserNameV2 from "@/components/UserInfo/userNameV2";
import {request} from "@/util/api";
import UserAvatarV1 from "@/components/UserInfo/UserAvatarV1";

export default {
  name: "Share",
  components: {UserAvatarV1, UserNameV2},
  props: ['type', 'typeId'],
  watch: {
    type: function (newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }
      this.type = newVal;

    },
    typeId: function (newVal, oldVal) {
      if (newVal === oldVal && typeof newVal !== undefined) {
        return
      }
      this.typeId = newVal;
      this.init();
    }
  },
  data() {
    return {
      friendIds: [],
      isShare: [],
      choiceFriendId: null,
      isShowShareBox: false,
    }
  },
  async mounted() {
    if (window.localStorage.getItem("userId") == null) {
      return
    }
    if (window.localStorage.getItem("tokenStr") == null) {
      return
    }
    if (window.localStorage.getItem("userId") && window.localStorage.getItem("tokenStr")) {
      this.init()
    }

  },
  methods: {
    async init() {
      if (window.localStorage.getItem("userId") == null) {
        return
      }
      if (window.localStorage.getItem("tokenStr") == null) {
        return
      }

      this.friendIds = await this.getFriends();
      this.isShare = []
      for (let i = 0; i < this.friendIds.length; i++) {
        this.isShare.push(false)
      }
    },
    share(friendId) {

      let fromId = window.localStorage.getItem("userId")
      if (!fromId) {
        return
      }

      for (let i = 0; i < this.friendIds.length; i++) {
        if (friendId === this.friendIds[i]) {
          this.isShare[i] = true;
          this.send(this.type, this.typeId, fromId, friendId)
          this.$forceUpdate();
          break;
        }
      }


    },
    async getFriends() {
      return new Promise(function (resolve) {
        request("get", "/focus/getFriends").then(res => {
          if (res && res.code == 200) {
            resolve(res.object);
          }
        })
      })
    },
    send(type, typeId, fromId, toId) {
      let message = {fromId: fromId, toId: toId, text: "https://chujuei.top/" + type + "/" + typeId, type: type == "topic" ? 1 : 2}
      Utils.$emit("WebSocket_SendMessage", message);

    }
  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>