<template>
    <div>
        <div style="text-align: left">它的粉丝({{foucusList.length}})</div>
        <el-divider></el-divider>
        <div v-for="(item) in foucusList">

            <div style="flex-direction: row;display:flex;padding: 10px;">
              <user-basic v-bind:userId="item.from_id"></user-basic>
            </div>
            <el-divider></el-divider>
        </div>
        <div v-if="foucusList.length==0">
            o(╥﹏╥)o还没有粉丝
        </div>
    </div>
</template>

<script>
    import {request} from "../../util/api";
    import UserBasic from "@/components/UserInfo/UserBasic";
    import Utils from "@/util/utils";

    export default {
        name: "PeopleFans",
      components: {UserBasic},
        data(){
            return{
                foucusList:null,
            }
        },
        methods:{
            showPeopleInfo(item){
                window.sessionStorage.setItem("peopleUserId",item.id);
              Utils.$emit("init_People")
            },
            getFans(){
                request("post","/focus/getFans",{id:this.peopleUserId}).then(res=>{
                    if (res.code ==200){
                        this.foucusList = res.object;
                    }
                })
            }
        },
        created() {
            if (window.localStorage.getItem('tokenStr')){
                this.peopleUserId =  window.sessionStorage.getItem("peopleUserId");
                this.getFans();
            }

        }
    }
</script>

<style scoped>

</style>
