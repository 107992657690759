<template>
  <div
      style="min-height: 100vh; width: 100vw; max-width: 1000px; margin: 0 auto;overflow-x: hidden; background-color: #ffffff;">
    <el-dialog title="绑定邮箱" width="" :visible.sync="isShowBindEmail" append-to-body>
      <div class="row" style="width: 100vw; max-width:1000px;justify-content: center;">

        <BindEmail style="max-width: 500px; justify-content: center;"></BindEmail>
      </div>
    </el-dialog>

    <el-dialog title=""
               style=" width: 100vw; max-width: 500px; margin: 0 auto;text-align: center;"
               width="100%"
               :visible.sync="isShowBindWx"
               center>
      <div class="row" style="height: 400px; width: 100%; justify-content: center;">
        <myheader></myheader>
      </div>
    </el-dialog>
    <div v-if="!isPhone" style="width: 1000px;margin: 0px auto;" class="row">


      <div style=" margin-left: 10px; margin-right: 10px; margin-top: 10px; width: 180px;background-color: #ffffff; ">
        <div style="background-color: #F6F6F6;width: 180px;">
          <el-upload
              :auto-upload="true"
              :data={}
              action="/api/user/updateAvatar/0"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-tooltip effect="dark" content="点击更换头像" placement="top">
              <el-avatar shape="square" style="width: 20vw;height: 20vw; max-width: 200px; max-height: 200px; " alt=""
                         :src="user.avatar"></el-avatar>
            </el-tooltip>
          </el-upload>

        </div>
        <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
          <div class="cropper-content">
            <div class="cropper" style="text-align:center">
              <VueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.outputSize"
                  :outputType="option.outputType"
                  :info="option.info"
                  :canScale="option.canScale"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixed="option.fixed"
                  :fixedBox="option.fixedBox"
                  :fixedNumber="option.fixedNumber"
              ></VueCropper>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" plain @click="finish">确认</el-button>
          </div>
        </el-dialog>
      </div>
      <div style=" margin-left: 20px; width: 800px; margin-top: 10px;">
        <div class="userInfo">
          <div style="text-align: left">
            <div @mouseenter="enter(0)" @mouseleave="leave(0)" style="min-height: 50px;margin-bottom: 20px;"
                 class="row">
              <div class="row" style="font-weight:800;line-height: 50px;width: 80vw; max-width: 800px;">
                <div class="row" style="width: 100%; max-width: 900px;">
                  <div v-if="isUpdateNickName ===true" style="font-weight:650;width: 25vw; max-width: 150px;">
                    用户名
                  </div>
                  <div style="font-size: 26px;" v-if="isUpdateNickName ===false">
                    {{ user.nickname }}
                  </div>
                  <img v-if="user.authIconUrl.length > 0" style="width: 20px;height: 20px;"
                       :src="user.authIconUrl"/>
                  <el-button v-if="isUpdateNickName === false" @click="updateNickname" style="margin-left: 2vw;"
                             size="40px;" type="text">
                    <div style="color: #144583" class="color:black;">
                      修改<i class="el-icon-s-tools"></i>
                    </div>
                  </el-button>
                  <div v-if="isUpdateNickName ===true">
                    <el-input show-word-limit maxlength="10" v-model="userInfoForm.nickname"
                              placeholder="请输入用户名"></el-input>
                    <el-button @click="submitNickname(1)" size="small" type="primary" plain>保存</el-button>
                    <el-button @click="submitNickname(0)" size="small" type="info">取消</el-button>
                  </div>
                </div>
                <div style=" justify-content: flex-end; margin-right: 2vw;">
                  <div v-if="isUpdateNickName ===false">
                    <el-button @click="toPeople" type="text" style="color: #8c939d;font-size: 18px;">
                      主页 >
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!--                            <el-divider></el-divider>-->
            <div @mouseenter="enter(1)" @mouseleave="leave(1)" class="row" style="height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 25vw; max-width: 150px;">
                性别
              </div>
              <div v-if="isUpdateSex === false">
                <div v-if="user.sex==='男'">
                  <i class="el-icon-male"></i>
                </div>
                <div v-else-if="user.sex==='女'">
                  <i class="el-icon-female"></i>
                </div>
                <div v-else>
                  未知
                </div>
              </div>
              <el-button @click="updateSex" style="margin-left: 20px;" size="30px;" type="text">
                <div v-if="isUpdateSex==false&&isShowUpdateSex===true" style="font-size: 15px;color:#144583">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
              <div v-if="isUpdateSex === true">
                <el-radio v-model="userInfoForm.sex" label="男">男</el-radio>
                <el-radio v-model="userInfoForm.sex" label="女">女</el-radio>
                <el-button @click="submitSex(1)" size="small" type="primary" plain>保存</el-button>
                <el-button @click="submitSex(0)" size="small" type="info">取消</el-button>
              </div>
            </div>
            <el-divider></el-divider>
            <div @mouseenter="enter(2)" @mouseleave="leave(2)" class="row"
                 style="min-height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 25vw; max-width: 150px;">
                真实姓名
              </div>
              <div v-if="isUpdateUsername ===false">
                {{ user.username }}
              </div>
              <el-button @click="updateUsername" v-if="isUpdateUsername ===false" style="margin-left: 20px;"
                         size="30px;" type="text">
                <div v-if="isUpdateUsername ===false&&isShowUpdateUsername===true" style="color: #144583"
                     class="color:black;">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
              <div v-if="isUpdateUsername ===true">
                <el-input show-word-limit maxlength="10" v-model="userInfoForm.username"
                          placeholder="请输入真实姓名"></el-input>
                <el-button @click="submitUsername(1)" size="small" type="primary" plain>保存</el-button>
                <el-button @click="submitUsername(0)" size="small" type="info">取消</el-button>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="row" style="height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 25vw; max-width: 150px;">
                邮箱
              </div>
              {{ user.email }}
              <div class="row" v-if="!user.email.length > 0">
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    content="注意：已注册过的邮箱账号不可绑定">
                  <el-button slot="reference" @click="toBind()" style="margin-left: 0px;"
                             size="40px;" type="text">
                    <div style="color: #144583" class="color:black;">
                      绑定<i class="el-icon-s-tools"></i>
                    </div>
                  </el-button>
                </el-popover>


              </div>

            </div>
            <el-divider></el-divider>
            <div class="row" style="height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 25vw; max-width: 150px;">
                微信 <img style="width: 30px; height: 30px;" src="wx.png">
              </div>
              <img style="border-radius: 100px; margin-right: 10px;" :src="user.wxAvatar">
              {{ user.wxNickname }}


              <div class="row" v-if="!user.wxNickname.length > 0">
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    content="注意：已注册过的邮箱账号不可绑定">
                  <el-button slot="reference" @click="toBindWx()" style="margin-left: 0px;"
                             size="40px;" type="text">
                    <div style="color: #144583" class="color:black;">
                      绑定<i class="el-icon-s-tools"></i>
                    </div>
                  </el-button>
                </el-popover>


              </div>

            </div>
            <el-divider></el-divider>
            <div @mouseenter="enter(4)" @mouseleave="leave(4)" class="row" style="height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 25vw; max-width: 150px;">
                认证
              </div>
              <div v-if="user.authName.length > 0" style="line-height: 50px;">
                {{ user.authName }}
                <div class="ProfileHeader-divider"></div>
                {{ user.authDetail }}
                <el-tooltip content="官方认证" placement="top-start" style="cursor:pointer">
                  <img style="width: 20px;height: 20px;"
                       :src="user.authIconUrl"/>
                </el-tooltip>
              </div>
              <div v-else>暂无</div>
              <el-button v-if="isUpdateVerify === true" @click="toVerify()" style="margin-left: 20px;"
                         size="40px;" type="text">
                <div style="color: #144583" class="color:black;">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
            </div>

            <el-divider></el-divider>
            <div @mouseenter="enter(3)" @mouseleave="leave(3)" class="row"
                 style="min-height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 150px;">
                个性签名
              </div>
              <div v-if="isUpdateSign ===false">
                {{ user.sign }}
              </div>
              <el-button @click="updateSign" v-if="isUpdateSign ===false&&isShowUpdateSign===true"
                         style="color: #144583" size="30px;" type="text">
                <div v-if="isUpdateSign ===false" style="margin-left:20px;font-size: 15px;" class="color:black;">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
              <div v-if="isUpdateSign===true">
                <el-input show-word-limit maxlength="20" v-model="userInfoForm.sign" placeholder="请输入个性签名"></el-input>
                <el-button @click="submitSign(1)" size="small" type="primary" plain>保存</el-button>
                <el-button @click="submitSign(0)" size="small" type="info">取消</el-button>
              </div>
            </div>
            <el-divider></el-divider>

          </div>

        </div>
      </div>


    </div>
    <div v-else style="width: 98vw; margin: 0px auto; padding: 1vw; background-color: #ffffff" class="col">
      <div style="background-color: #ffffff; margin-top: 0px; margin-left: 10px;border-bottom: 1px solid #dcdfe6; ">
        <div class="row" style="width: 100%;justify-content: center;">
          <div style="margin-right:10px;background-color: #ffffff; ">
            <el-upload
                :auto-upload="true"
                :data={}
                action="/api/user/updateAvatar/0"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <el-tooltip effect="dark" content="点击更换头像" placement="top">
                <el-avatar shape="square" style="width: 75px;height: 75px; " alt=""
                           :src="user.avatar"></el-avatar>
              </el-tooltip>
            </el-upload>
          </div>
          <div class="row phone_nickname"  @mouseenter="enter(0)" @mouseleave="leave(0)" style="min-height: 75px;margin-bottom: 20px;">
            <div class="row" style="font-weight:800;line-height: 75px; ">
              <div class="row" style="">
                <div v-if="isUpdateNickName ===true" style="font-weight:650; font-size: 15px; width: 150px; ;">
                  用户名
                </div>
                <div class="col" style="justify-content: center;" v-if="isUpdateNickName ===false">
                  {{ user.nickname }}
                </div>

                <el-button v-if="isUpdateNickName === false" @click="updateNickname"
                           style="justify-content: flex-end; margin-left: 10px;flex: 1;"
                           size="40px;" type="text">
                  <div style="color: #144583" class="color:black;">
                    修改<i class="el-icon-s-tools"></i>
                  </div>
                </el-button>

                <div v-if="isUpdateNickName ===true">
                  <el-input style="" show-word-limit maxlength="10" v-model="userInfoForm.nickname"
                            placeholder="请输入用户名"></el-input>
                  <el-button @click="submitNickname(1)" size="small" type="primary" plain>保存</el-button>
                  <el-button @click="submitNickname(0)" size="small" type="info">取消</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style=" justify-content: flex-end; flex: 1; margin-right: 2vw; width: 100px;">
            <div class="col" style="justify-content: center; height: 75px; margin-left: 6px;" v-if="isUpdateNickName ===false">
              <el-button @click="toPeople" type="text" style="color: #8c939d;font-size: 15px;">
                主页 >
              </el-button>
            </div>
          </div>
        </div>
        <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
          <div class="cropper-content">
            <div class="cropper" style="text-align:center">
              <VueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.outputSize"
                  :outputType="option.outputType"
                  :info="option.info"
                  :canScale="option.canScale"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixed="option.fixed"
                  :fixedBox="option.fixedBox"
                  :fixedNumber="option.fixedNumber"
              ></VueCropper>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" plain @click="finish">确认</el-button>
          </div>
        </el-dialog>
      </div>
      <div class="phone" style="padding: 20px;">
        <div class="userInfo">
          <div style="text-align: left">
            <!--                            <el-divider></el-divider>-->
            <div @mouseenter="enter(1)" @mouseleave="leave(1)" class="row" style="min-height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 90px;">
                性别
              </div>
              <div v-if="isUpdateSex === false">
                <div v-if="user.sex==='男'">
                  <i class="el-icon-male"></i>
                </div>
                <div v-else-if="user.sex==='女'">
                  <i class="el-icon-female"></i>
                </div>
                <div v-else>
                  未知
                </div>
              </div>
              <el-button @click="updateSex" style="margin-left: 10px;" size="30px;" type="text">
                <div v-if="isUpdateSex==false&&true===true" style="font-size: 15px;color:#144583">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
              <div v-if="isUpdateSex === true">
                <div style="height: 60px;" class="row">
                  <div class="col" style="justify-content: center;">
                    <div class="row">
                      <el-radio v-model="userInfoForm.sex" label="男">男</el-radio>
                      <el-radio v-model="userInfoForm.sex" label="女">女</el-radio>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <el-button @click="submitSex(1)" size="small" type="primary" plain>保存</el-button>
                  <el-button @click="submitSex(0)" size="small" type="info">取消</el-button>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <div @mouseenter="enter(2)" @mouseleave="leave(2)" class="row" style="min-height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 90px;">
                真实姓名
              </div>
              <div v-if="isUpdateUsername ===false">
                {{ user.username }}
              </div>
              <el-button @click="updateUsername" v-if="isUpdateUsername ===false" style="margin-left: 10px;"
                         size="30px;" type="text">
                <div v-if="isUpdateUsername ===false&&true===true" style="color: #144583"
                     class="color:black;">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
              <div v-if="isUpdateUsername ===true">
                <el-input show-word-limit maxlength="10" v-model="userInfoForm.username"
                          placeholder="请输入真实姓名"></el-input>
                <el-button @click="submitUsername(1)" size="small" type="primary" plain>保存</el-button>
                <el-button @click="submitUsername(0)" size="small" type="info">取消</el-button>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="row" style="height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 90px;">
                邮箱
              </div>
              {{ user.email }}
              <div class="row" v-if="!user.email.length > 0">
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    content="注意：已注册过的邮箱账号不可绑定">
                  <el-button slot="reference" @click="toBind()" style="margin-left: 0px;"
                             size="40px;" type="text">
                    <div style="color: #144583" class="color:black;">
                      绑定<i class="el-icon-s-tools"></i>
                    </div>
                  </el-button>
                </el-popover>


              </div>

            </div>
            <el-divider></el-divider>
            <div class="row" style="height: 50px;line-height: 50px;">
              <div style="font-weight:650;width: 90px;">
                微信 <img style="width: 30px; height: 30px;" src="wx.png">
              </div>
              <img style="border-radius: 100px; margin-right: 10px;" :src="user.wxAvatar">
              {{ user.wxNickname }}


              <div class="row" v-if="!user.wxNickname.length > 0">
                <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    content="注意：已注册过的邮箱账号不可绑定">
                  <el-button slot="reference" @click="toBindWx()" style="margin-left: 0px;"
                             size="40px;" type="text">
                    <div style="color: #144583" class="color:black;">
                      绑定<i class="el-icon-s-tools"></i>
                    </div>
                  </el-button>
                </el-popover>


              </div>

            </div>
            <el-divider></el-divider>
            <div @mouseenter="enter(4)" @mouseleave="leave(4)" class="row" style="line-height: 50px;">
              <div style="font-weight:650;width: 90px; max-width: 150px;">
                认证
                <el-tooltip content="官方认证" placement="top-start" style="cursor:pointer">
                  <img style="width: 20px;height: 20px;"
                       :src="user.authIconUrl"/>
                </el-tooltip>
              </div>
              <div v-if="user.authName.length > 0" style="line-height: 50px;">
                {{ user.authName }}
                <div class="ProfileHeader-divider"></div>
                {{ user.authDetail }}

              </div>
              <div v-else>暂无</div>
              <el-button v-if="true === true" @click="toVerify()" style="margin-left: 10px;"
                         size="40px;" type="text">
                <div style="color: #144583" class="color:black;">
                  修改<i class="el-icon-s-tools"></i>
                </div>
              </el-button>
            </div>

            <el-divider></el-divider>
            <div @mouseenter="enter(3)" @mouseleave="leave(3)" class="row" style="line-height: 50px;">
              <div style="font-weight:650;width: 90px;">
                个性签名
              </div>
              <div class="row" style="flex: 1;">

                <div v-if="isUpdateSign ===false">
                  {{ user.sign }}
                </div>
                <el-button @click="updateSign" v-if="isUpdateSign ===false&&true===true"
                           style="color: #144583" size="30px;" type="text">
                  <div v-if="isUpdateSign ===false" style="margin-left:10px;font-size: 15px;" class="color:black;">
                    修改<i class="el-icon-s-tools"></i>
                  </div>
                </el-button>
                <div v-if="isUpdateSign===true">
                  <el-input show-word-limit maxlength="20" v-model="userInfoForm.sign" placeholder="请输入个性签名"></el-input>
                  <el-button @click="submitSign(1)" size="small" type="primary" plain>保存</el-button>
                  <el-button @click="submitSign(0)" size="small" type="info">取消</el-button>
                </div>
              </div>

            </div>
            <el-divider></el-divider>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "../util/api";

import {VueCropper} from 'vue-cropper'

import Utils from "@/util/utils";
import BindEmail from "@/components/bind/BindEmail";
import Myheader from "@/components/wx/myheader";
import Share from "@/components/Icon/Share";

export default {
  name: "UserInfo",
  components: {
    Myheader,
    BindEmail,
    VueCropper
  },
  data() {
    return {
      dialogVisible: false,

      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
      },
      sex: "男",
      isUpdateNickName: false,
      isUpdateUsername: false,
      nicknameBtn: false,
      isUpdateSign: false,
      isUpdateSex: false,
      isShowUpdateNickname: false,
      isShowUpdateUsername: false,
      isShowUpdateSex: false,
      isShowUpdateSign: false,
      isUpdateVerify: false,

      user: {nickname: "", sex: "", sign: "", username: "", avatar: ""},
      userInfoForm: {nickname: "", sex: "", sign: "", username: "", avatar: ""},

      rules: {
        nickname: [
          {required: true, message: "请输入用户名", trigger: 'blur'},
          {min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur'}
        ],
      },
      isShowBindEmail: false,
      isShowBindWx: false,
      isPhone: false,
    }
  },

  mounted() {

    if (/mobile/i.test(navigator.userAgent)) {
      this.isPhone = true;
    }

    if (window.localStorage.getItem('tokenStr')) {
      this.getUserInfo()
    } else {
      this.$router.replace("login")
      this.$message("请先登录！")
    }
  },
  methods: {
    toBindWx() {
      this.isShowBindWx = true;
    },
    toBind() {
      this.isShowBindEmail = true;
    },
    cancelBindWx() {
      this.isShowBindWx = false;
    },
    toVerify() {
      this.$router.push("verify")
    },
    toPeople() {
      window.sessionStorage.setItem("peopleUserId", window.localStorage.getItem("userId"));
      this.$router.push("people")
      Utils.$emit("init_People")
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file, fileList) {
      //上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = URL.createObjectURL(file.raw);
        this.dialogVisible = true
      })
    },
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        var formData = new FormData();
        formData.append("file", data);
        request("post", "/user/updateAvatar/1", formData).then(res => {
          this.user.avatar = res.object;
          this.dialogVisible = false
          this.$message.success("更换成功")
          window.location.reload()
        })
      })
    },
    enter(index) {
      if (index === 0) {
        this.isShowUpdateNickname = true;
      }
      if (index === 1) {
        this.isShowUpdateSex = true;
      }
      if (index === 2) {
        this.isShowUpdateUsername = true;
      }
      if (index === 3) {
        this.isShowUpdateSign = true;
      }
      if (index === 4) {
        this.isUpdateVerify = true;
      }

    },
    leave(index) {
      if (index === 0) {
        this.isShowUpdateNickname = false;
      }
      if (index === 1) {
        this.isShowUpdateSex = false;
      }
      if (index === 2) {
        this.isShowUpdateUsername = false;
      }
      if (index === 3) {
        this.isShowUpdateSign = false;
      }
      if (index === 4) {
        this.isUpdateVerify = false;
      }
    },
    getUserInfo() {
      let that = this
      request("get", "/user/getInfo").then(res => {

        if (res.code == 200) {
          that.user = res.object
          that.userInfoForm = {...res.object}
        } else {
          this.$router.replace("/login")
        }
      })
    },

    submitForm(key, value) {
      request("post", "/user/updateInfo", {key: key, value: value}).then(res => {

      })
    },
    submitNickname(isSubmit) {
      this.isUpdateNickName = false;
      if (isSubmit == 1) {
        if (this.userInfoForm.nickname.length == 0) {
          this.$message.error("不能为空！")
          return;
        }
        if (this.userInfoForm.nickname == this.user.nickname)
          return;
        this.user.nickname = this.userInfoForm.nickname;
        this.submitForm("nickname", this.user.nickname)
      } else {
        this.userInfoForm.nickname = this.user.nickname;
        this.getUserInfo()
      }
    },
    updateNickname() {
      this.isUpdateNickName = true;
    },
    submitUsername(isSubmit) {
      this.isUpdateUsername = false;
      if (isSubmit === 1) {
        if (this.userInfoForm.username.length == 0) {
          this.$message.error("不能为空！")
          return;
        }
        if (this.userInfoForm.username == this.user.username)
          return;

        this.user.username = this.userInfoForm.username;
        this.submitForm("username", this.user.username)
      } else {
        this.userInfoForm.username = this.user.username;
        this.getUserInfo()
      }
    },
    updateUsername() {
      this.isUpdateUsername = true;
    },
    submitSign(isSubmit) {
      this.isUpdateSign = false;
      if (isSubmit === 1) {
        if (this.userInfoForm.sign == this.user.sign)
          return;

        this.user.sign = this.userInfoForm.sign;

        this.submitForm("sign", this.user.sign)
      } else {
        this.userInfoForm.sign = this.user.sign;
        this.getUserInfo()
      }
    },
    updateSign() {
      this.isUpdateSign = true;

    },
    submitSex(isSubmit) {
      setTimeout(() => {
        this.isUpdateSex = false;
        if (isSubmit === 1) {
          if (this.userInfoForm.sex == this.user.sex)
            return;
          this.user.sex = this.userInfoForm.sex;
          this.submitForm("sex", this.user.sex)
        } else {
          this.user.sexForm = this.userInfoForm.sex;
          this.getUserInfo()
        }
      }, 100)
    },
    updateSex() {
      this.isUpdateSex = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.userInfo {
  min-height: 800px;
  background-color: white;
  margin-top: 0px;
}

.maincontext {
  padding: 20px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);*/
  min-height: 800px;
  background-color: white;
  margin-top: 20px;
}

.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}

.avatar-uploader {
  background: red !important;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
}

.el-icon-plus {
  width: 150px;
  height: 150px;
  font-size: 30px;
}

.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
}

.cropper {
  width: 500px;
  height: 500px;
  background: yellow;
}

.ProfileHeader-divider {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background: #ebebeb;
}

.phone {
  width: calc(100vw - 40px);
}
.phone_nickname {
  width: calc(100vw - 185px);
}
</style>
