<template>
  <div style="max-width: 1000px; min-height: 120px;" v-loading="loading">
    <div>
      <div class="row" style="text-align: left">
        <user-basic style="width: 60%;" v-bind:userId="answer.fromId"></user-basic>
        <div class="row" style="width: 40%;justify-content: flex-end;">
          <div class="col">
            <div class="row" style="justify-content: flex-end;">
              <el-dropdown v-if="answer.fromId==userId">
                <span style="cursor: pointer;" class="el-dropdown-link">
                  ···
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="deleteAnswerById(answer.id)">
                      删除
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

          </div>
        </div>
      </div>


      <div style="text-align: left;color: #909399;padding: 5px;font-size: small">
        &ensp;{{ zan.length ? zan.length : 0 }}&ensp;个人赞同了该文章
      </div>


      <div v-html="text"></div>

      <div style="text-align: left" v-if="fullText.length > 500">
        <el-button @click="showAll()" v-if="answer.isShowAll!=true" type="text">阅读全文<i
            class="el-icon-arrow-down"></i></el-button>
        <el-button @click="showAll()" v-if="answer.isShowAll==true" type="text">收起<i
            class="el-icon-arrow-up"></i></el-button>
      </div>
      <div style="text-align: left;color: #C0C4CC">发布于 &ensp; &ensp;{{ func(answer.gmtCreate) }}
      </div>

      <div style="text-align: right;">
        <div class="row" style="left0px;margin-top: 10px;">
          <!--                            回答的点赞-->
          <div style="height: 30px;padding: 0px;">
            <el-button v-model="zan.length" style="margin-left: 10px;height: 30px;" size="small" type="text">
              <div style="display: flex;flex-direction: row;"
                   @click="addZan()">
                <!--                图案-->
                <div style="" v-if="zan.isFromUser">
                  <el-image class="good" style="width: 20px;height: 20px;"
                            src="good2.png">
                  </el-image>
                </div>
                <div v-else>
                  <el-image class="good" style="width: 20px;height: 20px;"
                            src="good1.png">
                  </el-image>
                </div>
                <!--数字-->
                <div style="line-height: 20px;padding: 1px;"
                     v-if="zan.length>0">
                  <div style="color:#13227a;" v-if="zan.isFromUser">
                    {{ zan.length }}
                  </div>
                  <div style="color: #8c939d" v-else>
                    {{ zan.length }}
                  </div>
                </div>
                <div style="color: #8c939d;line-height: 20px;" v-else>点赞</div>
              </div>
            </el-button>
          </div>
          <el-button type="text"
                     @click="showComment"
                     style="color: #8c939d;font-size: 12px;margin-left: 5px;"
                     size="small">
            <div style="flex-direction: row;display: flex">
              <div style="line-height: 20px;">
                <el-image class="good" style="width: 20px;height: 20px;"
                          src="comment.png">
                </el-image>
              </div>
              <div style="line-height: 20px;margin-left: 2px;">
                {{ comment.length }}条评论
              </div>

            </div>
          </el-button>
          <!--收藏按钮-->
          <div style="height: 30px;padding: 0px;">
            <el-button style="margin-left: 5px;margin-top:-3px;height: 30px;"
                       size="small" type="text">
              <div style="display: flex;flex-direction: row;"
                   @click="toColletion(answer.id)">
                <div style="" v-if="collect.isFromUser">
                  <el-image class="good" style="width: 23px;height: 23px;"
                            src="collection2.png">
                  </el-image>
                </div>
                <div v-else>
                  <el-image class="good" style="width: 23px;height: 23px;"
                            src="collection1.png">
                  </el-image>
                </div>
                <div style="line-height: 20px;margin-top: 3px;padding: 1px;"
                     v-if="collect.length > 0">
                  <div style="color:#13227a;" v-if="collect.length > 0">
                    {{ collect.length }}
                  </div>
                  <div style="color: #8c939d" v-else>
                    {{ collect.length }}
                  </div>
                </div>
                <!--                                                    <div style="color: #8c939d;line-height: 20px;" v-else>收藏</div>-->
              </div>
            </el-button>
          </div>

          <div class="col"
               style="width: 20px; height: 45px;line-height: 30px;justify-content: center; margin-left: 10px;">

            <Share style="width: 20px; height: 20px; padding: 2px; margin-top: -5px; justify-content: center;"
                   v-bind:type="'answer'"
                   v-bind:type-id="answerId"></Share>
          </div>

          <!--                            </el-badge>-->
          <!--          <el-button type="text" style="margin-left: 10px;line-height: 20px;"-->
          <!--                     v-if="answer.fromId==userId"-->
          <!--                     @click="deleteAnswerById(answer.id)" size="small">-->
          <!--            <el-image class="good" style="width: 20px;height: 20px;" src="delete1.png">-->
          <!--            </el-image>-->
          <!--          </el-button>-->

        </div>
        <Comment v-if="isShowComment"
                 style="max-height: 700px;width: 100%;  border-style: solid;border-width: 1px; border-color: #DCDFE6;border-radius: 3px;"
                 v-bind:typeId="answerId"
                 v-bind:type="0"
                 v-bind:userId="userId"></Comment>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";
import func from "@/util/getDateTime";
import UserBasic from "@/components/UserInfo/UserBasic";
import Utils from "@/util/utils";
import Comment from "@/components/Comment";
import Share from "@/components/Icon/Share";

export default {
  name: "AnswerSingle",
  components: {Share, Comment, UserBasic},
  props: ['answerId'],
  watch: {
    // },
    answerId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.answerId = newVal
        this.init();
      }
    }


  },
  data() {
    return {
      zan: {
        isFromUser: false,
        length: 0,
      },
      collect: {
        isFromUser: false,
        length: 0,
      },
      comment: {
        length: 0,
      },
      fullText: "",
      text: "",
      isShowComment: false,
      loading: false,
      answer:{},
      userId:0,

    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // 暂不支持answer
      if (typeof this.answerId === typeof undefined || this.answerId === undefined) {
        return
      }
        this.userId = window.localStorage.getItem("userId")

      if (typeof this.userId === typeof undefined || this.userId === undefined) {
        this.userId = 0
      }

      this.getAnswerById(this.answerId)

      if (!this.answer) {
        return
      }




    },
    showComment() {
      this.isShowComment = true;
    },
    initAnswer() {
      this.answer.isShowAll = false
      this.fullText = this.answer.context;
      this.text = this.answer.context;
      if (this.answer.context.length > 500) {
        this.text = this.answer.context.slice(0, 500) + "..."
      }
    },
    getZanByAnswerId(answerId) {
      request("get", "/zan/getByAnswerId?answerId=" + answerId).then(res => {
        if (res && res.code == 200) {
          this.zan = res.object;
        }
      })
    },
    getCommentNumsByAnswerId(answerId) {
      request("get", "/comment/getNumsByAnswerId?answerId=" + answerId).then(res => {

        if (res && res.code == 200) {
          this.comment.length = res.object;
        }
      })
    },
    getcollectByAnswerId(answerId) {

      request("get", "/collect/getByAnswerId?answerId=" + answerId).then(res => {
        if (res && res.code == 200) {
          this.collect = res.object;
        }
      })
    },

    // TODO
    deleteAnswerById(answerId) {
      this.$confirm('确认删除该文章吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request("post", "/answer/deleteById", {id: answerId}).then(res => {
          if (res.code == 200) {
            Utils.$emit('On_AppVue_DeleteAnswer', answerId)
          }
        })
      });
    },
    getAnswerById(answerId) {

      this.loading = true

      request("get", "/answer/getAnswerById?answerId=" + answerId).then(res => {
        this.loading = false
        if (res && res.code == 200) {
          this.answer = res.object;
          this.getZanByAnswerId(this.answerId)
          this.getcollectByAnswerId(this.answerId)
          this.getCommentNumsByAnswerId(this.answerId)
          this.initAnswer()
        }
      })

    },
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 500) {
        this.answer.isShowAll = false;
        return value.slice(0, 500) + "...";

      }
      return value;
    },
    toColletion() {
      request("post", "/collect/add", {answerId: this.answer.id}).then(res => {
        if (res && res.code == 200) {

          if (res.object == -1) {
            this.collect.length--;
            this.collect.isFromUser = false;
          }
          if (res.object == 1) {
            this.collect.length++;
            this.collect.isFromUser = true;
          }
        }

      })
    },
    showAll() {
      if (this.text.length > 503) {
        this.text = this.text.slice(0, 500) + "..."
      } else {
        this.text = this.fullText
      }
      this.answer.isShowAll = !this.answer.isShowAll
    },
    func(time) {
      return func(time)
    },
    addZan() {
      let _this = this
      request("post", "/zan/add", {typeId: this.answer.id, type: 1}).then(res => {
        if (res && res.code == 200) {
          if (_this.zan.isFromUser) {
            _this.zan.length -= 1;
          } else {
            _this.zan.length += 1;
          }
          this.zan.isFromUser = !this.zan.isFromUser;
        } else {
          this.$message.warning(res.message);
        }
      })
    },
  }

}
</script>

<style scoped>
.el-dropdown-link {
  padding: 5px;
}

.el-dropdown-link:hover {
  background-color: #f6f6f6;
}

.el-dropdown-link:active {
  background-color: #f6f6f6;
}
</style>