<template>
  <div>
    <div @click="toTopic" class="col box" style="width: 100%; height: 100%;border-radius: 4px; cursor: pointer; ">
      <div class="box1" style="display:block; /*这里设置inline-block或者block；根据使用情况来定（行内元素需要加这个，块级元素和行内块级可以不用）*/
                              white-space:nowrap;
                              overflow:hidden;
                              text-overflow:ellipsis;
                              width: 100%;

                              height: 25px;
                              font-size: 14px;
                              text-align: left; ">
        <div style="padding: 0px;height: 100%; margin-left: 10px;margin-top: 4px;">
          {{ topic.title }}
        </div>
      </div>
      <div class="box2" style="flex: 1;">
        <div class="row" style=" margin-top: 10px;  margin-left: 15px; margin-right: 10px;">
          <div style="flex: 1; font-size: 10px;  text-align: left;   word-break: break-word;">
            {{answer.context.length > 10 ? (answer.context.slice(0, 8) + "...") : answer.context }}
<!--            <br/>-->
<!--            {{zan.length}}人点赞-->
<!--            <br/>-->
<!--            <br/>-->
<!--            {{focus.length}}人收藏-->

          </div>
          <div style=""><img style="height: 40px;" src="zhihu.png"></div>
        </div>
      </div>
      <div class="row box3"
           style="max-height: 50px; justify-content: flex-start; padding: 4px; font-size: 10px;">
        <img style="height: 20px; margin-left: 10px; border-radius: 100px; margin-right: 5px;" src="zhihu.png">
        <div style="line-height: 20px;">

        知乎
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "@/util/api";

export default {
  name: "AnswerBox",
  props: ['topic','answer','zan','focus'],
  watch: {
    answer: function (newVal, oldVal) {
      if (newVal.id !== oldVal) {
        this.answer = newVal
        this.$forceUpdate()
      }
    },
    topic: function (newVal, oldVal) {
      if (newVal.topicId !== oldVal) {
        this.topic = newVal
        this.$forceUpdate()
      }

    },
  },
  data() {
    return {
    }
  },
  async mounted() {
    // if (!this.topicId) {
    //   return
    // }
    // this.getTopic(this.topicId)
    // this.focus = await this.getFocusByTopicId(this.topicId)
    // this.answerNums = await this.getAnswerNumsByTopicId(this.topicId)

  },
  methods: {
    toTopic() {
      this.$router.push({
        path:"/answer",
        query: {
          topicId:this.topic.topicId,
          answerId:this.answer.id,
        }
      })
      // window.open("/#/answer?topicId="+this.topic.topicId+"&answerId=" + this.answer.id, "_blank");
    },
    getTopic(topicId) {
      let topicStr = window.localStorage.getItem("topic:" + topicId)
      if (topicStr) {
        this.topic = JSON.parse(topicStr)
      } else {
        request("get", "/topicGetById?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {

            this.topic = res.object;
            window.localStorage.setItem("topic:" + topicId, JSON.stringify(res.object))
          }
        })
      }
    },
    async getFocusByTopicId(topicId) {
      return new Promise(function (resolve) {
        request("get", "/focus/getByTopicId?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object);
          }
        })
      })
    },
    async getAnswerNumsByTopicId(topicId) {
      return new Promise(function (resolve) {

        request("get", "/topic/getAnswerNums?topicId=" + topicId).then(res => {
          if (res && res.code == 200) {
            resolve(res.object)
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.box {
  .box1 {
    background-color: white;
  }

  .box2 {
    background-color: white;
  }

  .box3 {
    background-color: #fdfdfd;
  }
}

.box:hover {
  .box1 {
    background-color: #ebebeb;
  }

  .box2 {
    background-color: #ebebeb;
  }

  .box3 {
    background-color: #f2f2f2;
  }
}

.box:hover {

}

</style>