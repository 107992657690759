<template>

  <div
      style="width: 100vw;background-image:url('bg.png');background-color: rgb(184,229,248);background-size: cover;background-repeat: no-repeat;overflow: auto;">
    <div style="min-height: 100vh;margin-bottom: 10px;text-align: center;">


      <img style="height: 100px;margin-top: 20px;"
           src="logo.png">

      <div style="width:90%;  max-width: 540px; padding:15px;margin: 0 auto;height: 500px;background-color: white;position: relative;top: 10px;">
        <el-tabs v-model="loginStyle">
          <el-tab-pane style="height: 200px;" label="免密码登录" name="first">
            <div style="margin: 30px auto; width: 90%;">
              <el-form :rules="emailRules" ref="emailForm" :model="emailForm">
                <el-form-item prop="email" label="">

                  <el-input placeholder="邮箱地址" style="" v-model="emailForm.email"
                            autocomplete="off"></el-input>

                </el-form-item>
              </el-form>
              <el-form :rules="forgetRules" ref="forgetPasswordFrom" :model="forgetPasswordFrom">

                <el-form-item prop="emailCode" label="">
                  <div class="row">
                    <el-input @keyup.enter.native="submitUpdateCode()" placeholder="输入6位验证码"
                              v-model="forgetPasswordFrom.emailCode"
                    ></el-input>
                    <div style="margin-left: 20px;">
                      <el-button :loading="!canPost" @click="postEmailCode(60)" type="primary" plain>
                        {{ forgetBtnTips }}
                      </el-button>
                    </div>
                  </div>

                </el-form-item>

              </el-form>
              <div style="text-align: right;height: 40px;">
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button style="width: 100%;" type="primary" plain @click="submitUpdateCode">登 录 / 注 册
                </el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane style="height: 200px;" label="邮箱登录" name="second">
            <div style="margin: 0 auto; width: 90%;padding: 0px;">
              <login></login>
            </div>
          </el-tab-pane>
          <el-divider>其他登录方式</el-divider>
          <div style="flex: 1;margin-top: 30px; text-align: center;">
            <img @click="showWxLogin = true" src="wx.png" style="width: 50px; height: 50px; cursor: pointer; ">
          </div>
          <div style="text-align: left;margin-top: 50px;">
            <el-divider></el-divider>
            未注册邮箱验证后自动登录，注册即代表同意《胖乎协议》《隐私保护指引》
          </div>
        </el-tabs>
        <el-dialog
            title=""
            style=" width: 100vw; max-width: 500px; margin: 0 auto;text-align: center;"
            width="100%"
            :visible.sync="showWxLogin"

            center>
          <div class="row" style="height: 400px; width: 100%; justify-content: center;">

            <myheader style="justify-content: center;"></myheader>
          </div>
        </el-dialog>

      </div>

    </div>
  </div>
</template>

<script>
import {request} from "../util/api";
import Login from "./Login";
import Myheader from "@/components/wx/myheader";

export default {
  name: "Register",
  props: ["userbasic"],
  components: {Myheader, Login},
  data() {

    return {
      showWxLogin: false,
      loginStyle: "first",
      forgetBtnTips: "发送验证码",
      checked: true,
      forgetPasswordFrom: {
        password: "",
        checkPassword: "",
        emailCode: "",
        nickname: ""
      },
      forgetRules: {
        emailCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],

      },
      emailRules: {
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: "email", message: "请输入正确的邮箱地址", trigger: "blur"}
        ],
      },
      emailForm: {
        email: null,
      },
      canPost: true
    }
  },
  methods: {
    updateUserBasic() {
      this.$parent.updateUserBasic()
    },
    submitUpdateCode() {
      let that = this;
      this.$refs['forgetPasswordFrom'].validate((valid) => {
        if (valid) {
          request("post", "/user/registerOrLoginByCode", {
            email: this.emailForm.email,
            code: this.forgetPasswordFrom.emailCode,
            password: this.forgetPasswordFrom.password
          }).then(res => {
            if (res.code == 200) {
              let tokenStr = null;
              tokenStr = res.object.tokenHead + res.object.token;
              // window.localStorage.clear()
              window.sessionStorage.clear()
              window.localStorage.removeItem("userId")
              window.localStorage.removeItem("tokenStr")
              window.localStorage.removeItem("topic_types")

              window.localStorage.setItem("tokenStr", tokenStr);
              that.updateUserBasic();
              this.$router.push({
                path: '/index',
              })
              window.location.reload()
            }
          })
        }
      })
    },

    postEmailCode(time) {

      this.$refs['emailForm'].validate((valid) => {
        if (valid) {
          if (time === 60) {
            this.canPost = false;
            request("post", "/registerCode/register", {
              email: this.emailForm.email,
              type: 1
            }).then(res => {


              if (res.code !== 200) {
                this.forgetBtnTips = "重新发送验证码"
                this.canPost = true;
                return
              }
            })
          }
          setTimeout(() => {
            this.forgetBtnTips = time + "s";
            if (time > 0)
              this.postEmailCode(time - 1)
            else {
              this.forgetBtnTips = "重新发送验证码"
              this.canPost = true;
              return
            }
          }, 1000)
        }
      })


    },

  }
}
</script>

<style scoped>
.col {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
}
</style>
